@import "var";
input[type=button], input[type=submit], input[type=file], button { cursor: pointer; -webkit-appearance: none; }
.sx-page {
  padding-bottom: 1.5rem;
  width: 100%;
  .project-record{
    .right-btn{
      text-align: center;
      padding-top: .4rem;
      padding-bottom: .4rem;
    }
  }
  .title-code{
    position: relative;
    border-bottom: 1px solid #f1f1f1;
    h4{
      width: 90%;
      display: inline-block;
      border-bottom: none;
    }
    .icon-green-code{
      position: absolute;
      top: 50% ;
      transform: translateY(-50%);
      right: 5px;
    }
  }
  .list_search{
    .search_wrapper{
      &.j-list-wrapper{
        width: 5rem;
      }
    }
  }
  .donate-record-list{
    .donate-table-box{
      font-size: .23rem;
      .thead{
        font-size: .28rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: .2rem 0;
        border-bottom: 1px dashed @border-color;
      }
      .tbody{
        .tr{
          display: flex;
          justify-content: space-between;
          padding: .2rem 0;
          border-bottom: 1px dashed @border-color;
          .td{
            font-size: .26rem;
            .text-overflow();
            line-height: 1.3;
            color: @light-black;
          }
          .time{
            width: 22%;
          }
          .name{
            width: 40%;
            padding-right: 4%;
          }
          .c-name{
            width: 26%;
            padding-right: 4%;
          }
          .money{
            padding-right: 4%;
            width: 28.3%;
          }
        }
      }
    }
  }
  .green-bg {
    background: @green;
    height: .22rem;
  }
  .user-apply {
    margin-top: .2rem;
    //margin-bottom: .2rem;
  }
  .article-content{
    overflow: hidden;
    p {
      max-width: 100%;
    }
    img {
      border-radius: 3px;
      max-width: 6.20rem;
      margin-left: .2rem;
    }
    &.special {
      img {
        margin-left: 0;
      }
    
    }
  }
  .project_box {
    .right-btn{
      padding: .35rem 15px;
      padding-bottom: 0;
      text-align: center;
    }
    .tab_box {
      height: .75rem;
      line-height: .75rem;
      border-bottom: 1px solid #f1f1f1;
      background: none;
      margin-top: 0;
      .tab_item {
        background: none;
        color: #333;
        font-size: .28rem;
        line-height: .7rem;
      }
      .tab_item_active {
        color: @green;
        background: none;
        //border-bottom: 3px solid @green;
        box-shadow: none;
        position: relative;
        &:after{
          content: '';
          display: inline-block;
          position: absolute;
          bottom: -3px;
          left: 50%;
          width: 70%;
          height: 3px;
          background: @green;
          transform: translateX(-50%);
        }
      }
    }
    .project_item {
      //之前底部的高度是1rem，可能因为有更多这个按钮的原因
      //清除掉这个padding修改成sx-page的padding-bottom
      //padding-bottom: 1.5rem;

      .row {
        border-top: .1rem solid @bg-bar;
        &:first-child {
          border-top: none;
        }
        .record_item {
          border-bottom: none;
          padding-top: .3rem;
          padding-bottom: .3rem;
          display: flex;
          margin-left: 15px;
          margin-right: 15px;
          justify-content: flex-start;
          .img-logo {
            width: 1.47rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              position: static;
              transform: none;
              width: 1.47rem;
              border-radius: 50%;
              height: 1.47rem;
            }
            &.company-logo {
              width: 1.9rem;
              height: 1.25rem;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              img {
                max-width: 100%;
                width: 100%;
                height: auto;
                border-radius: 3px;
              }

            }
            &.fund-logo {
              width: 1.46rem;
              height: 1.46rem;
              overflow: hidden;
              img {
                width: 1.46rem;
                height: 1.46rem;
              }

            }

          }
          .record_desc {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 3.8rem;
            padding-left: .3rem;
            .create_time, .user_name {
              padding-left: 0;
            }
            .create_time{
              font-size: .24rem;
            }
            .user_name{
              margin-bottom: .3rem;
              font-size: .26rem;
            }
            &.company-desc {
              justify-content: flex-start;
              //flex: 1;
              .user_name{
                margin-bottom: .4rem;
              }
            }
            &.fund-desc {
              width: 4.5rem;
              padding-left: .21rem;
              .user_name {
                color: #333;
                font-size: .26rem;
                line-height: 1.5;
                margin-bottom: .2rem;
              }
              .fund-group {
                color: #999;
                font-size: .24rem;
                display: flex;
                margin-bottom: .2rem;
                .price {
                  color: @orange;
                  font-weight: bold;
                }
                .target, .exist {
                  flex: 0 0 2.05rem;
                }
                .target {
                  border-right: 1px solid #cccccc;
                }
                .exist {
                  padding-left: .55rem;
                }

              }
              .catchword {
                color: #999;
                display: flex;
                span:first-child {
                  flex-shrink: 0;
                }
                .text {
                  color: #333;
                }
              }
            }
          }
          .donate_count{
            font-size: .26rem;
          }

        }
      }
      .team-apply-list{
        display: flex;
        flex-wrap: wrap;
        li{
          display: flex;
          flex-direction: column;
          flex: 0 0 50%;
          align-items: center;
          border-bottom: 1px solid #e6e6e6;
          .avatar-box{
            width: 1.46rem;
            height: 1.46rem;
            overflow: hidden;
            margin-bottom: .3rem;
            margin-top: .3rem;
            img{
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .title{
            font-size: .26rem;
            color:#333;
            margin-bottom: .25rem;
            .multi-text-overflow(1.5,2);
            max-height: .8rem;
            .captain{
              color: @orange;
            }
          }
        }
      }
      .donate-table{
        width: 100%;
        tbody{
          td{
            font-size: .26rem;
            color:#666;
            padding-top: .3rem;
            padding-bottom: .3rem;
            border-bottom: 1px solid #e8e8e8;
            line-height: 1.3;
          }
        }
        thead{
          td{
            font-size: .28rem;
            color:#333;
          }
        }
      }
    }

  }
  .apply-list-row{
    &:first-child{
      border-top: none;
    }
    .record_item {
      border-bottom: none;
      display: flex;
      margin-left: 15px;
      margin-right: 15px;
      justify-content: space-between;
      .img-logo {
        width: 1.47rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          position: static;
          transform: none;
          width: 1.47rem;
          border-radius: 50%;
          height: 1.47rem;
        }
        &.company-logo {
          width: 1.9rem;
          height: 1.25rem;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            width: 100%;
            height: auto;
            border-radius: 3px;
          }

        }
        &.fund-logo{
          width: 1.46rem;
          height: 1.46rem;
          overflow: hidden;
          img{
            width: 1.46rem;
            height: 1.46rem;
          }

        }

      }
      .record_desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 3.8rem;
        padding-left: .2rem;
        .create_time, .user_name {
          padding-left: 0;
        }
        &.company-desc {
          justify-content: flex-start;
          width: 3rem;
        }
        &.fund-desc{
          width: 4.5rem;
          padding-left: .21rem;
          .user_name{
            color:#333;
            font-size: .26rem;
            line-height: 1.5;
            margin-bottom: .2rem;
          }
          .fund-group{
            color:#999;
            font-size: .24rem;
            display: flex;
            margin-bottom: .2rem;
            .price{
              color: @orange;
              font-weight: bold;
            }
            .target,.exist{
              flex: 0 0 2.05rem;
            }
            .target{
              border-right: 1px solid #cccccc;
            }
            .exist{
              padding-left: .55rem;
            }

          }
          .catchword{
            color:#999;
            display: flex;
            span:first-child{
              flex-shrink: 0;
            }
            .text{
              color:#333;
            }
          }
        }
      }

    }
    &.other_record_item{
      .record_item{
        padding-top: .4rem;
        padding-bottom: .4rem;
        overflow: hidden;
        position: relative;
      }
    }
  }

  .fund-detail-img-box{
    position: relative;
    .img-wrap(7.2rem,4.78rem);
    .fund-header{
      position: absolute;
      width: 6.6rem;
      padding: 0 .3rem;
      height: .98rem;
      background-image: -webkit-linear-gradient(-90deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0,.06) 100%);
      left: 0;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .desc-project{
      position: absolute;
      //background-image: -webkit-linear-gradient(90deg, rgba(253,116,0, 0.12) 0%, rgba(225,227,204,.06) 100%);
      background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0,0,0,.35) 50%,rgba(0, 0, 0, 0.01) 100%);
      padding: .15rem .3rem;
      width: 6.6rem;
      display: flex;
      align-items: center;
      left: 0;
      bottom: 0;
      .text{
        font-size: .32rem;
        color: #fff;
        text-align: left;
        text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.2);
      }

    }

  }
  .show-detail-data{
    color: #fff;
    font-size: .24rem;
    height: 2.9rem;
    padding: 0 15px;
    .bg(show_detail_data,jpg);
    .top-data,.bottom-data{
      height:  1.45rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .num{
        color: #fff;
        .showy{
          font-size: .58rem;
          span{
            font-size: .24rem;
          }
          margin-bottom: .15rem;
        }
        .desc{

        }
      }
    }
    .top-data{
      .m-code-share-box{
        width: 0.88rem;
        height: .88rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .bottom-data{
      .num{
        flex: 1;
      }
    }
  }
  .fund-detail-intro-box{
    font-size: .24rem;
    .top-show-data{
      font-size: .28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: .83rem;
      border-bottom: 1px solid @border-color;
      .showy-data{
        color: @orange;
      }
      .desc{
        .classify{
          color: @gray;
        }
      }
      .left-data{

      }

    }
    .bottom-show-data{
      padding: .25rem 0;
      color: @light-black;
      .data-group{
        display: flex;
        i{
          margin-right: .15rem;
        }
        i.icon-location{
          padding-top: .07rem;
        }
        &:first-child{
          margin-bottom: .15rem;
        }
        &:last-child{
          span{
            line-height: 1.5;
          }
        }
      }
    }

  }

  .detail-img-wrap {
    .img-wrap(6.6rem, 4.38rem);
    position: relative;
    img {
      width: 6.6rem;
      height: auto;
      border-radius: 5px;
    }
    .m-code-share-box{
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 0.98rem;
      background-image: -webkit-linear-gradient(-90deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.16) 100%);
      .icon-code{
        position: absolute;
        right: .3rem;
        bottom: .3rem;
      }
    }

    &.user-img-wrap{
      margin: 0 auto;
      width: 4.39rem;
      height: 4.39rem;
      img{
        width: 4.39rem;
        height: 4.39rem;
        border-radius: 50%;
      }
    }
  }
  .probably_table {
    padding-bottom: .3rem;
    display: flex;
    justify-content: space-between;
    .welfare_preview_item_1, .welfare_preview_item_2, .welfare_preview_item_3 {
      h4 {
        padding-bottom: 0;
        padding-right: 0;
        text-align: center;
      }
      .text_danger {
        padding-bottom: .2rem;
        font-size: .34rem;
        color: @orange !important;
        font-weight: bold;
        text-align: center;
      }

    }

    .welfare_preview_item {
      width: unset;
      flex: 1;
      h4 {
        color: #a7a7a7;
        font-size: .24rem;
        margin-bottom: 0;
        font-weight: normal;
        border: none;
        padding-left: 0;
        padding-right: 0;
      }
      .text_danger {
        border: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .welfare_preview_item_2{
      padding-left: .4rem;
      padding-right: .4rem;
      border-left: 1px solid #dfdfdf;
      border-right: 1px solid #dfdfdf;
    }

  }
  .sx-activity {
    .recommend_project_top_box {
      h4.recommend_project_left_top {
        font-size: .32rem;
        color: @green;
        border-left: 2px solid @green;
        padding-left: .1rem;
        margin-top: .3rem;
        margin-bottom: .3rem;
        line-height: 1.5;
      }
    }
    .sx-form-search {
      background: #68c258;
      padding-top: .2rem;
      padding-bottom: .2rem;
      padding-left: .3rem;
      margin-right: -15px;
      margin-left: -15px;
      form {
        position: relative;
        input {
          width: 3.4rem;
          padding-left: .55rem;
          border-radius: .5rem;
          height: .5rem;
          background: #fff;
          outline: none;
          border: none;
        }
        a {
          margin-left: .07rem;
        }
        .icon-search {
          position: absolute;
          left: .2rem;
          top: 0.15rem;
        }
      }
    }
  }
}

//善行活动列表
.sx-activity-list {
  //padding-bottom: .8rem;
  //overflow: hidden;
  .activity-item {
    background: #fff;
    display: inline-block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    .project-content {
      display: flex;
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;
      .left {
        position: relative;
        margin-right: .25rem;
        border: 1px solid @border-color;
        box-sizing: border-box;
        width: 2.5rem;
        height: 1.65rem;
        overflow: hidden;
        border-radius: 3px;
        flex: 0 0 2.5rem;
        img {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
        .tag-primary {
          position: absolute;
          right: 0;
          top: 0;
          color: #fff;
          border-radius: 0;
        }
      }
      .right {
        flex-grow: 1;
        .v-align {
          .title {
            color: #333;
            font-size: 0.28rem;
            height: .8rem;
            margin-bottom: 0.2rem;
            margin-top: -0.05rem;
            overflow: hidden;
          }
          .progress-count-status {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #999;
            font-size: .24rem;
            margin-top: .1rem;
            .dark-gray{
              padding-left: .1rem;
              line-height: 1.3;
            }
          }
        }
      }
    }
    .sx-m-project {
      .img-wrap {
        .img-wrap(1.9rem, 1.25rem);
        flex: 0 0 1.9rem;
        img {
          border-radius: 3px;
          max-width: 100%;
          height: auto;
        }
      }
      .right {
        .v-align {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .title {
            color: #333;
            font-size: 0.26rem;
            flex-shrink: 0;
            height: .75rem;
            overflow: hidden;
          }
          .progress-count-status {
            font-size: .24rem;
            .gray {
            }
            .dark-gray {
              color: @orange;

              .unit {
                color: #333;
              }
            }
          }
        }
      }
    }
  }
}
.activity-list{
  .activity-item{
    .project-content{
      .left{
        .tag{
          position: absolute;
          right: 0;
          top: 0;
          padding: 0.06rem .14rem;
          font-size: .2rem;
          color: #fff;
          border-radius: 0;
          z-index: 2;
        }
        .orange{
          background-color: @orange;
        }
        .green{
          background-color: @green;
        }
        .gray{
          background-color: #cccccc;
        }
      }
      .right{
        .v-align{
          font-size: .28rem;
          .title{
            line-height: 1.5;
            margin-bottom: .15rem;
          }
          .price{
            color: @red;
            margin-bottom: .2rem;
          }
          .bottom-message{
            font-size: .24rem;
            display: flex;
            justify-content: space-between;
            .raise-funds{
              color: @gray;
            }
            .support{
              color: @gray;
              > span{
                color: @orange;
              }
            }
          }
        }
      }
    }
  }
}
.project-list {
  //padding-bottom: .8rem;
  //overflow: hidden;
  .project-item {
    background: #fff;
    display: inline-block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    .project-content {
      display: flex;
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;
      .left {
        position: relative;
        margin-right: .25rem;
        .img-wrap(2.5rem, 1.65rem);
        overflow: hidden;
        border-radius: 3px;
        flex: 0 0 2.5rem;
        img {
          width: 2.5rem;
          height: 1.65rem;
        }
        .tag-primary {
          position: absolute;
          right: 0;
          top: 0;
          color: #fff;
          border-radius: 0;
        }
      }
      .right {
        width: 100%;
        .v-align {
          .title {
            color: #333;
            font-size: 0.28rem;
            height: .8rem;
            margin-bottom: 0.2rem;
            margin-top: -0.05rem;
            overflow: hidden;
          }
          .progress-box{
            display: flex;
            justify-content: space-between;
          }
          .progress-count-status {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #999;
            font-size: .24rem;
            margin-top: .1rem;
            .dark-gray{
              padding-left: .1rem;
              line-height: 1.3;
            }
            &:nth-child(2){
              justify-content: flex-end;
            }
          }
        }
      }
    }
    .sx-m-project {
      .img-wrap {
        .img-wrap(1.9rem, 1.25rem);
        flex: 0 0 1.9rem;
        img {
          border-radius: 3px;
          max-width: 100%;
          height: auto;
        }
      }
      .right {
        .v-align {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .title {
            color: #333;
            font-size: 0.26rem;
            flex-shrink: 0;
            height: .75rem;
            overflow: hidden;
          }
          .progress-count-status {
            font-size: .24rem;
            .gray {
            }
            .dark-gray {
              color: @orange;

              .unit {
                color: #333;
              }
            }
          }
        }
      }
    }
  }
}

.donate_form{
  >h4.pay-title{
    border-bottom: 1px solid #9bd543;
    color: #9bd543;
    padding-bottom: .2rem;
    font-size: .3rem;
  }
  & > .sub-title{
    font-size: .28rem;
    color: @green;
    padding-top: .3rem;
  }

  .form-title-box{
    h2{
      border-bottom: 3px solid @green;
      color: @green;
      padding-bottom: .2rem;
      display: inline-block;
    }
  }

  color:#333;
  .title{
    span:last-child{
      color:#333;
      font-size: .28rem;
    }
  }
  .detail-title{
    font-size: .24rem;
    color:#666666;
    line-height: 2;
    margin-bottom: .25rem;
    .receiver{
      color: @orange;
    }
  }
  .form-group{
    .msg{
      margin-top: .2rem;
      i{
        vertical-align: text-top;
      }
      .desc{

      }
    }
    .checkbox-inline{
      input,span{
        vertical-align: middle;
      }
    }
    .label-box{
      display: flex;
      flex-direction: column;
      label{
        &+label{
          margin-top: .3rem;
        }

      }
    }
    &.form-group-inline{
      display: table;
      .sub-title{
        min-width: 1.5rem;
        display: table-cell;
      }
      .right-text{
        display: table-cell;
      }
    }
    font-size: .28rem;
    .form-left-title{
      font-size: 0.26rem;
      display: inline-block;
      width: 1.35rem;
      text-align: right;
      min-width: 1.5rem;
      display: table-cell;
      line-height: 1.2;
    }
    .text_default{
      font-size: 0.28rem;
      color: #333;
      font-weight: bold;
      display: table-cell;
      line-height: 1.2;
    }
    .invoice_desc{
      &.add-dashed{
        border-bottom: 1px dashed #f61919;
      }
    }
    .sub-title{
      color:#333;
     .notice{
       color: @orange;
     }
    }
    .right-text{
      line-height: 1.3;
    }
    .other_num{
      input{
        width: 5.28rem !important;
        background: #fff;
        border: 1px solid #e6e6e6;
      }
      span{
        position: absolute;
        top: 50%;
        right: .2rem;
        z-index: 2;
      }
    }
    input[type=checkbox],input[type=radio]{
      vertical-align: middle;
    }
    .pl{
      padding-left: .1rem;
      vertical-align: middle;
    }
    &.select-project{
      font-size: .26rem;
      .top-title{
        margin-bottom: .2rem;
        .notice{
          color:#fd7400;
        }
      }
      .input_text_default{
        width: 100%;
        margin-left: 0;
        color:#666;
      }
    }
  }
  .form-group-mult{
    display: flex;
    line-height: 1.2;
    .sub-title{
      flex: 0 0 1.5rem;
    }
    .right-text{

    }
    .label-box{
      display: flex;
      flex-direction: column;
      label{
        &+label{
          margin-top: .3rem;
        }

      }

    }
  }
}

//圆圈头像上传
.sx-change-oss{
  .circle-avatar{
    .ossuploader-add{
      background: #e6e6e6;
      border: none;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      i{
        background: none;
      }
      p{
        font-size: .24rem;
        color:#1e81cd;
        margin-top: 0;
      }
    }
  }
}
.code-success-message{
  .all-center;
  flex-direction: column;
  height: 80vh;
  > img{
    width: 1rem;
    height: 1rem;
    margin-bottom: .4rem;
  }
  h2{
    font-size: .36rem;
    color: @green;
    margin-bottom: .4rem;
  }
  > .desc{
    font-size: .28rem;
    color: @gray;
  }
}
//捐赠成功后的页面
.success-message{
  .content{
    padding-top: 2.85rem;
    padding-bottom: 4rem;
    h1{
      font-size: .38rem;
      color: @green;

      margin-bottom: .5rem;
      text-align: center;
    }
    p.feedback{
      color:#999999;
      font-size:.26rem;
      line-height: 2;
      margin-bottom: 1.4rem;
      text-align: center;
    }

    .a-left{
      margin-left: .35rem;
      margin-right: .3rem;
    }

    .apply_code {
      text-align: center;
      color: #999;
      .num {
        color: @green;
      }
    }
  }
}

.upload-rectangle  .ossuploader-add{
  width: 3rem;
  height: 2.1rem;
  border-radius: 2px;
  background: #eeeeee;
  border: none;
  i{
    display: none;
  }
  p{
    margin-top: .8rem;
    color:#1e81cd;
    font-size: .24rem;
  }
}
.upload-rectangle .ossuploader-dash-border{
  width: 3rem;
  height: 2.05rem;
}
.upload-circle .ossuploader-add{
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #eeeeee;
  border: none;
  i{
    display: none;
  }
  p{
    margin-top: .8rem;
    color:#1e81cd;
    font-size: .24rem;
  }
}
.page-team-profile {
  .tab-title{
    margin-bottom: .3rem;
  }
  .sub-title{
    font-size: .28rem;
    color: @green;
    margin-bottom: .3rem;
  }
  .form{
    .form-group {
      .label-box{
        display: flex;
        flex-direction: column;
        label{
          &+label{
            margin-top: .3rem;
          }

        }
      }
      .msg{
        margin-top: .2rem;
        i{
          vertical-align: text-top;
        }
        .desc{

        }
      }
      .select-sex{
        input[type=radio]{
          width: .24rem;
          height: .24rem;
          vertical-align: middle;
          &:last-child{
            margin-left: .9rem;
          }
        }

      }
      &.top-flag{
        .field-name{
          width: 1.65rem;
          line-height: 1.5;
          float: left;
        }
        .right-text{
          line-height: 1.5;
          margin-left: 1.65rem;
        }
      }
    }
  }
  .unit{
    position: absolute;
    right: .2rem;
    top: .7rem;
  }
}


//noLogin

.nologin-background{
  .bg(nologin);
  display: flex;
  min-height: 100vh;
  .container-fluid{
    margin-left:.3rem;
    margin-right: .3rem;
    background: #fff;
    padding: 0;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    border-radius: 3px;
    box-shadow: 0px 10px 22px 0px rgba(49, 166, 29, 0.64);
    .top-logo{
      border-bottom: 1px dashed #dbdbdb;
      height: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .img-box{
        width: 4.45rem;
        height: .95rem;
        position: static;
      }
    }
    .main-box{
      margin-top: .6rem;
      margin-bottom: .8rem;
      .content-text{
        font-size: .32rem;
        padding-left: .7rem;
        padding-right: .7rem;
        color:#333;
        text-align: center;
        .state{
          color:#66c156;
        }
      }
      .qr-code-box{
        margin-top: .6rem;
        margin-bottom: .6rem;
        display: flex;
        justify-content: center;
        align-items: center;

          .qr-code{
            border: 1px solid #dbdbdb;
            width: 4rem;
            height: 4rem;
            padding: .15rem;
            box-sizing: border-box;
            img{
              width: 100%;
              height: auto;
            }
          }
      }
      .tool{
        text-align: center;
        font-size: .26rem;
        i{
          vertical-align: middle;
        }
        span{
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  }

.page .form,.page .ajax-form{
  padding-bottom: .3rem;
}
main .project-detail{
  .record_support_item {
    border-bottom: none;
    display: flex;
    margin-left: 15px;
    margin-right: 15px;
    justify-content: space-between;
    padding: .25rem 0;
    .img-logo {
      width: 1.47rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: static;
        transform: none;
        width: 1.47rem;
        border-radius: 50%;
        height: 1.47rem;
      }
      &.company-logo {
        width: 1.9rem;
        height: 1.25rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          width: 100%;
          height: auto;
          border-radius: 3px;
        }

      }
      &.fund-logo{
        width: 1.46rem;
        height: 1.46rem;
        overflow: hidden;
        img{
          width: 1.46rem;
          height: 1.46rem;
        }

      }

    }
    .record_desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 3.8rem;
      padding-left: .2rem;
      .create_time, .user_name {
        padding-left: 0;
      }
      &.company-desc {
        justify-content: flex-start;
        width: 3rem;
      }
      &.fund-desc{
        width: 4.5rem;
        padding-left: .21rem;
        .user_name{
          color:#333;
          font-size: .26rem;
          line-height: 1.5;
          margin-bottom: .2rem;
        }
        .fund-group{
          color:#999;
          font-size: .24rem;
          display: flex;
          margin-bottom: .2rem;
          .price{
            color: @orange;
            font-weight: bold;
          }
          .target,.exist{
            flex: 0 0 2.05rem;
          }
          .target{
            border-right: 1px solid #cccccc;
          }
          .exist{
            padding-left: .55rem;
          }

        }
        .catchword{
          color:#999;
          display: flex;
          span:first-child{
            flex-shrink: 0;
          }
          .text{
            color:#333;
          }
        }
      }
    }

  }
}

.page{
  .bottom-btn-group{
    text-align: center;
    padding-top: .4rem;
    padding-bottom: .4rem;
    a.btn{
      border-radius: 3px;
      width: 1.65rem;
    }
  }
  .team-intro{
    display: flex;
    padding-bottom: .48rem;
    border-bottom: 1px solid @border-color;
    .img-wrapper{
      .img-wrap(2.8rem,1.84rem,3px);
      flex: 0 0 2.8rem;
      margin-right: .3rem;
    }
    .content{
      h3{
        font-size: .3rem;
        margin-bottom: .25rem;
        font-weight: bold;
      }
      .content-row{
        font-size: .24rem;
        color: @gray;
        .desc{
          color: #333;
          line-height: 1.7;
        }
        &.first-child{
          height: .75rem;
          max-height: .75rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: .25rem;
        }
      }

    }
  }
  .code-share-box{
    padding: .68rem 0;
    .tips-text{
      line-height: 1.7;
      padding: 0 .25rem;
      color: #333;
      font-size: .24rem;
      margin-bottom: .6rem;
      i.icon-notice{
        margin-right: .15rem;
        vertical-align: text-top;
      }
    }
    .code-content{
      margin: 0 auto;
      padding: .1rem;
      width: 3.06rem;
      box-shadow: 0px 10px 40px 0px rgba(122, 122, 122, 0.2);
      border: 1px solid  @border-color;
      .code-img-box{
        .img-wrap(3.06rem);
        img{
          //height: 100%;
        }
      }
      .desc{
        font-size: .3rem;
        color: #333;
        margin-bottom: .4rem;
        padding:0 .18rem;
        margin-top: .2rem;
        margin-bottom: .35rem;
      }

    }
  }

  .form{
    .msg-group{
      padding: .35rem 0;
      border-bottom: 1px solid @border-color;
      .title-desc-list{
        .item{
          font-size: .28rem;
          margin-bottom: 0.24rem;
          .title{
            color: @light-black;
          }
          .desc{
            color: #333;
          }
        }
      }

      h3.title{
        color: @green;
        font-size: .28rem;
        margin-bottom: .34rem;
      }
      .member-list-box{
        display: flex;
        flex-wrap: wrap;
        .member-item{
          margin-right: .5rem;
          color:#333;
          font-size: .28rem;
          padding-bottom: .32rem;
        }
      }
    }
    &.other-tips{
      .tips-wrapper{
        margin-left: 0;
        margin-top: .25rem;
      }
    }
  }

}

.search-this-msg-box{
  padding-bottom: .3rem;
  display: flex;
  justify-content: space-between;
  select{
    margin-right: .2rem;
    height: .51rem;
    flex-shrink: 0;
    background: rgb(245 245 245);
    border-radius: 4px;
    border: 1px solid rgb(233 233 233);
    color: @light-black;
  }
  form,.keywork-box{
    height: .51rem;
    //一开始的设计为5.04rem
    //flex: 0 0 5.04rem;
    flex:1;
    position: relative;
    input{
      width: 96%;
      padding-left: .2rem;
      height: .48rem;
      border: 1px solid @border-color;
      background: #f5f5f5;
      outline: none;
      border-radius: .48rem;
    }
    button.search-submit{
      height: .51rem;
      position: absolute;
      right: -0.01rem;
      top: 0;
      background: @orange;
      outline: none;
      border: none;
      width: 1.03rem;
      border-radius:.0rem 0.48rem 0.48rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      i.icon-white-search{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

  }
  .right-btn{
    .btn{
      background: none;
      border: 1px solid @green;
      width: 1.41rem;
      color: @green;
      font-size: .26rem !important;
    }

  }
}
.modal_layer{
  .modal_box{
    overflow-y: scroll;
    .code-share{
      .code-img-box{
        img{
          border: 1px solid @border-color;
          display: block;
          margin: 0 auto;
          padding: .1rem;
          max-width: 80%;
        }
      }
    }
    .modal_foot{
      .operation{
        .tips-wrapper{
          margin-top: 0;
          margin-left: 0;
          align-items: center;
          .tips-desc{
            color: #f61919;
            font-size: .28rem;
            font-weight: bold;
          }
        }
      }
    }
    .modal_content {
      max-height: calc(8.5rem - 30px - 0.82rem - 0.6rem - .55rem);
      &.not_ax_height {
        max-height: unset;
      }
    }
  }
}
#show-code{
  .adjust-size{
    padding: 0.5rem;
    top: -0.5rem;
    right: -.5rem;
  }
}
.stats_list {
  &.has_padding {
    .stats_list_item {
      padding-top: .2rem !important;
      padding-bottom: .2rem !important;
    }
  }
}

.sz-org-detail{
  .stats_list{
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid #e2e5e1;
    &.stats_list_4{
      .stats_list_item{
        padding-top: .2rem;
        padding-bottom: .2rem;
        .text-unit{
          font-size: .24rem;
        }
      }
    }
  }
  .org-donate-count{
    .title{
      &.org-font{
        font-size: .24rem;
      }
    }
    .desc{
      font-weight: bold;
    }
  }
}

.bottom-center-tips{
  display: flex;
  justify-content: center;
  padding: .5rem 0 0 0;
  line-height: 1.5;
  align-items: center;
  .tips-desc{
    font-size: .26rem;
    padding-left: .2rem;
    color: @gray;
  }
}

//捐赠列表
.donate-table-box{
  font-size: .23rem;
  .thead{
    font-size: .28rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .2rem 0;
    border-bottom: 1px dashed @border-color;
  }
  .tbody{
    .tr{
      display: flex;
      justify-content: space-between;
      padding: .2rem 0;
      border-bottom: 1px dashed @border-color;
      .td{
        font-size: .26rem;
        .text-overflow();
        line-height: 1.3;
        color: @light-black;
      }
      .time{
        width: 22%;
      }
      .name{
        width: 40%;
        padding-right: 4%;
      }
      .c-name{
        width: 26%;
        padding-right: 4%;
      }
      .money{
        padding-right: 4%;
        width: 28.3%;
      }
    }
  }
}

//修正home（慈善项目模块）爱心记录
.donate_record.donate_record_list .record_item{
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
  &.amend-record-item{
    .record_item_content{
      padding: .3rem 0;
      justify-content: flex-start;
      img{
        width: .78rem;
        height: .78rem;
      }
      .record_desc{
        margin-left: .3rem;
        flex: 1;
        .user_name{
          font-weight: bold;
          margin-bottom: 0.05rem;
        }
        .create_time{

        }
      }
      .donate_count{
        span{
          color: #f61919;
          font-weight: bold;
        }
      }

      &.p-b-20{
        padding-bottom: .2rem;
      }
    }
    .remark{
      padding: .2rem 0;
      margin-top: -.1rem;
      border-top: 1px solid #f1f1f1;
      line-height: 1.5;
    }
  }
}

.donate_success.donate_success_auto_page{
  .content{
    // height: 97.5%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;
    .operation{
      // position: absolute;
      bottom: .2rem;
      width: 100%;
      left: 0;
      margin-bottom: .3rem;
    }
  }
}





.line-group{
  margin-bottom: .4rem;
  .field-name{
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
}

.v-list{
  &-item{
    display: block;
    > .img{
      .img-wrap(100%,4.06rem);
      border-radius: 4px;
    }
    > .inner-box{
      padding: .24rem 0;
    }
    &-title{
      .multi-text-overflow(1.4,2);
      color: @black;
      font-size: .3rem;
      margin-bottom: .04rem;
    }
    &-name{
      color: @gray;
      font-size: .24rem;
      margin-bottom: .06rem;
    }
    .bottom-funds-list{
      display: flex;
      justify-content: space-between;
      .item{
        .num{
          font-size: .32rem;
          color: @orange;
        }
        .desc{
          color: @light-black;
          font-size: .24rem;
        }
      }
    }
    &.has-p-t{
      padding-top: .3rem;
    }
  }
}
.subject-child-desc{
  color: @light-black;
  font-size: .26rem;
  line-height: 1.7;
  padding: .5rem 0;
  .blue{
    color: @blue;
  }
  .substiute{

  }
  .desc-main{
    display: none;
  }
}
.sale-goods{
  padding-top: .3rem;
  width: 100%;
  > img{
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  .top-message{
    padding: 0.26rem 0 0.2rem 0;
    .price-box{
      margin-bottom: .1rem;
      display: flex;
      justify-content: space-between;
      .price{
        color: @red;
        font-size: .36rem;
        > span{
          font-size: .2rem;
        }
      }
      .p-right{
        .all-center;
        .sale-collect,.cancel-sale-collect{
          cursor: pointer;
        }

      }
      .collect-right{
        margin-right: .4rem;
        display: flex;
        .icon-box{
          margin-right: .1rem;
        }
        .v-collect-box{
          padding: .04rem 0;
          line-height: normal;
        }
        .text{
          color: @gray;
          font-size: .28rem;
        }
      }
    }
    .title{
      display: flex;
      justify-content: space-between;
      font-size: .3rem;
      color: @black;
      line-height: 1.4;
      > .text{
        width: 100%;
        flex-shrink: 0;
      }
    }
  }
  .line-box{
    border-top: 1px solid @border-color;
    padding: .2rem 0;
    color: @gray;
    font-size: .28rem;
    display: flex;
    .filed{
      width: 1.5rem;
      flex-shrink: 0;
    }
    &.gray{
      .right{
        color: @gray;
      }
    }
    .right{
      color: @black;
      > span{
        color: @orange;
      }
    }
  }
}
.org-support{
  > .org-support-line{
    font-size: .28rem;
    display: flex;
    margin-bottom: .1rem;
    .filed{
      color: @gray;
    }
    .name{
      color: @black;
    }
    .blue{
      color: @blue;
      line-height: 1.6;
    }
  }
}
.h-list{
   .h-item{
    display: flex;
  }
}
.sale-record-list{
  .sale-record-item{
    font-size: .26rem;
    padding: .28rem 0;
    align-items: center;
    .avatar-img{
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: .22rem;
    }
    .user-message{

      .name{
        color: @black;
        margin-bottom: .2rem;
        width: 2rem;
        .text-overflow();
      }
      .date{
        color: @gray;
        font-size: .24rem;
      }
    }
    .count{
      color: @light-black;
      > span{
        color: @red;
      }
    }
    .count-g{
      flex-grow: 1;
    }
  }
  .outer-item{
    &:first-child{
      > .sale-record-item{
        padding-top: 0;
      }
    }
  }
}

.adhere-to-bottom{
  background-color: #ffffff;
	box-shadow: 0rem -0.02rem 0.2rem 0rem
    rgba(28, 119, 12, 0.2);
    width: 100%;
    padding: .16rem .3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;
    box-sizing: border-box;
    .left-box{
      flex-shrink: 0;
      font-size: 0;
      margin-right: .2rem;
    }
    .btn{
      font-size: .28rem;
      color: #fff;
      height: 0.82rem;
      flex-grow: 1;
    //  box-shadow: 0rem 0.03rem 0.03rem 0rem
    //rgba(44, 134, 203, 0.3);
      & + .btn{
        margin-left: .2rem;
      }
      &.blue{
        background-color: @blue;
      }
      &.green{
        background-color: @green;
      }
      &.orange{
        background-color: @orange;
      }
      &.grey,&.btn-grey{
        color: #333;
        background-color: #e6e6e6;
      }
      &.btn-block{
        width: 100%;
      }
    }
    .left-price{
      color: @black;
      font-size: .28rem;
      display: flex;
      align-items: center;
      justify-content: center;
      > span{
        color: @red;
        font-size: .36rem;
        vertical-align: middle;
      }
    }
    &.z-index101{
      z-index: 101;
    }
    &.p-absolute{
      position: absolute;
    }
}
.l-image-r-content{
  display: flex;
  > .left{
    .img-wrap(2.5rem,1.6rem);
    margin-right: .26rem;
    flex-shrink: 0;
  }
  > .content{
    flex-grow: 1;
    width: 100%;
    color: @black;
    font-size: .28rem;
  }
}
.goods-message{
  padding: .4rem 0;
  .left{
    border-radius: 4px;
  }
  .content{
    .title{
      margin-bottom: 0.08rem;
      .multi-text-overflow(1.5,2);
    }
    .parameter{
      font-size: .24rem;
      color: @gray;
    }
    .date{
      font-size: .24rem;
      color: @gray;
      margin-top: .1rem;
      margin-bottom: .34rem;
    }
    .price-status{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .status{
        color: @orange;
        font-size: .24rem;
      }
    }
    .price{
      font-size: .3rem;
      color: @red;
      > span{
        font-size: .18rem;
        margin-left: .12rem;
        display: inline-block;
        &.color-gray{
          color: @gray !important;
        }
        &.middle-size{
          font-size: .26rem !important;
        }
      }
    }
    .total{
      font-size: .24rem;
      margin-top: .1rem;
      color: @black;
    }
    .message{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: .24rem;
      .status{
        color: @gray;
      }
      .new-message{
        color: @orange;
      }
    }
  }
  &.pay-message{
    padding: .3rem 0 .12rem 0;
    .content{
      .desc{
        color: @gray;
        font-size: .24rem;
      }
      .price{
         > .num {
           font-size: .3rem;
           color: @gray;
         }
      }
    }
  }
  &.leave-message{
    .content{
      width: 58%;
      .l-title{
        font-size: .28rem;
        color: @black;
        width: 100%;
        .text-overflow();
      }
    }
  }
}
.sale-questions-list{
  margin-top: 3.2rem;
  overflow-y: scroll;
  .questions-item{
    padding: .3rem;
    background: #fff;
    .avatar{
      .avatar(.8rem);
      box-sizing: border-box;
      border: 1px solid @border-color;
      margin-right: .2rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      > img{
        display: block;
      }
    }
    > .content{
      font-size: .3rem;
      color: @black;
      .name{
        margin-bottom: 0.06rem;
      }
      .text{
        line-height: 1.4;
        margin-bottom: 0.14rem;
      }
      .date{
        color: @gray;
        font-size: .24rem;
      }
    }
    &.isOrg{
      background-color: #f5f5f5;
      > .content{
        color: @blue;
      }
    }
  }
}
.leave-word-form{
  > form {
    > .title{
      margin-top: .9rem;
      color: @black;
      font-size: .28rem;
      margin-bottom: .18rem;
    }
    textarea{
      width: 100%;
      height: 2.8rem;
      background-color: #f5f5f5;
      border-radius: 4px;
      border: none;
      outline: none;
      padding: .23rem .19rem;
      box-sizing: border-box;
    }
    > .form-submit{
      margin-top: 2.1rem;
      width: 100%;
      height: .82rem;
      color: #fff;
      background-color: @green;
    }
  }
}
.user-set-address{
  margin: .4rem 0 .3rem 0;
  .show-address{
    padding: .3rem;
    background-color: #ffffff;
    height: 1.72rem;
    box-sizing: border-box;
    box-shadow: 0rem 0.01rem 0.06rem 0rem
      rgba(29, 31, 33, 0.16);
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    &.h-auto{
      height: auto;
    }
    &.h-118{
      height: 1.18rem;
    }
    .tag{
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0;
    }
    .left-content{
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      max-width: 86%;
      font-size: .3rem;
      .orange{
        color: @orange;
      }
      .blue{
        color: @blue;
      }
      > .set{
        >span{
          color: @light-black;
        }
      }
      .top-message{
        display: flex;
        color: @black;
        .name{
          margin-right: .2rem;
        }
      }
      .desc{
        color: @gray;
        font-size: .26rem;
      }
      .address{
        margin-top: .1rem;
        color: @light-black;
        font-size: .26rem;
        line-height: 1.4;
      }
    }
    &.active{
      border: 1px solid #f61919;
      position: relative;
      &:after{
        content: '';
        display: inline-block;
        .icon-selected;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    &.pt-0{
      padding-top: 0;
    }
    &.plr-0{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .receipt-item{
    box-sizing: border-box;
    &+.receipt-item{
      margin-top: .3rem;
    }
  }
  &.not-box-shadow{
    .show-address{
      box-shadow: none;
    }
  }
  &.blue-theme{
    .show-address{
      .left-content{
        .name,.tel,.address{
          color: @blue;
        }
      }
    }
  }
  &.orange-theme{
    .show-address{
      .left-content{
        .name,.tel,.address{
          color: @orange;
        }
      }
    }
  }
  &.has-bottom-border{
    border-bottom: 1px solid @border-color;
  }
}
.address-list{
  .user-set-address{
    > .show-address{
      padding: 0 .6rem;
    }
    // &:after{
    //   content: '';
    //   display: inline-block;
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   width: 100%;
    //   height: 0.1rem;
    //   background-color: #ebeced;
    // }
    &:last-child{
      &:after{
        height: 0;
      }
    }
    //> .row{
    //  position: relative;
    //  padding-bottom: .1rem;
    //}
  }
}
.user-pay-message{
  margin-top: .18rem;
  .line-box{
    color: @black;
    font-size: .26rem;
    margin-bottom: .3rem;
    > span{
      color: @light-black;
    }
    > a{
      color: @blue;
    }
  }
}
.tall-p-btn-group{
  padding-top: 1.8rem;
  text-align: center;
  > .btn{
    width: 3.8rem;
    height: 0.7rem;
    box-shadow: 0rem 0.03rem 0.03rem 0rem
		rgba(155, 73, 4, 0.3);
  }
  > .middle-btn{
    width: 2.8rem;
	  height: 0.6rem;
  }
}
.address-message{
  > .line-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.22rem 0;
    border-bottom: 1px solid @border-color;
    .text{
      font-size: .28rem;
      color: @black;
      outline: none;
      line-height: 1.6;
      border: none;
      width: 100%;
      &::-webkit-input-placeholder{
        color: @black;
      }
    }
    .orange{
      color: @orange;
    }
    .address-wrap{
      display: flex;
      padding: 0.08rem 0;
      width: 100%;
    }
    .addr-select{
      border: none;
      outline: none;
      border-color: transparent;
      background-color: @border-color;
      padding: .2rem;
      width: calc(~"100% / 3");
      font-size: .28rem;
      .addr-select{
        margin-left: .1rem;
      }
      &.addr-select:first-child{
        margin-left: 0;
      }
    }
  }
}
.tips-message{
  display: flex;
  align-items: baseline;
  > .icon-box{
    flex-shrink: 0;
  }
  > .filed-name{
    font-size: .28rem;
    color: @black;
    margin-bottom: .16rem;
  }
  > .message{
    margin-left: .1rem;
    font-size: .24rem;
    color: @gray;
    line-height: 1.5;
    & + .message{
      margin-top: .2rem;
    }
  }
  &.v-group{
    flex-direction: column;
  }
}
// #mask{
//   background: rgba(43, 43, 43, 0.7);
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   z-index: 10;
//   display: none;
//   height: 100%;
//   overflow: hidden;
// }
.shopping-cart{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background: rgba(43, 43, 43, 0.7);
  z-index: 100;
  box-sizing: border-box;
  .inner-shopping-box{
    transition: all .6s;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: .8rem .3rem;
    padding-bottom: 1rem;
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    > .icon-box{
      position: absolute;
      right: .2rem;
      top: 0.2rem;
    }
  }
  .goods-message{
    .left{
      .img-wrap(2.5rem,1.6rem);
      border-radius: 4px;
    }
  }
  .has-scroll-y{
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 6.6rem;
    max-width: 100%;
  }
  .form{
    >.form-group{
      margin-bottom: 0.58rem;
      margin-top: 0.5rem;
      .edit-count{
        margin-top: .19rem;
        display: flex;
        align-items: center;
        .edit{
          display: flex;
          align-items: center;
          margin-right: .3rem;
          .num-toggle-render{
            display: flex;
            align-items: center;
            .add ,.subtract{
              color: @light-black;
              font-size: .36rem;
            }
            label > input{
              width: 0.96rem;
              height: 0.56rem;
              border: 1px solid #f3f3f3;
              margin-left: .2rem;
              margin-right: .2rem;
              line-height: .56rem;
              text-align: center;
            }
          }
          > span{
            color: @light-black;
            font-size: .36rem;
            &.prevent{
              color: @red;
            }
          }
          .box{
            width: 0.96rem;
            height: 0.56rem;
            border: 1px solid #f3f3f3;
            margin-left: .2rem;
            margin-right: .2rem;
            line-height: .56rem;
            text-align: center;
          }
        }
        .inventory{
          color: @gray;
          font-size: .24rem;
          > span{
            margin-right: .2rem;
          }
        }
      }
    }
  }
}
.right-select-box{
  overflow: hidden;
  margin-right: -.2rem;
  .right-select-item{
    float: left;
    position: relative;
    width: calc(25% - 0.24rem);
    font-size: 0.26rem;
    border-radius: 4px;
    margin-right: .2rem;
    border: 1px solid #f3f3f3;
    line-height: 0.64rem;
    height: auto;
    text-align: center;
    color: @light-black;
    margin-top: .19rem;
    box-sizing: border-box;
    &.specification{
      padding: 0 .16rem;
      width: auto;
    }
    > .selected{
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0.3rem;
      height: 0.3rem;
      display: none;
    }
    &__active{
      border-color: @red;
      > .selected{
        display: inline-block;
      }
    }
    &__disabled{
      color: @gray;
      background-color: @bg-gray;
    }
    .right-select-input{

    }
  }
  .cargo-address{
    float: left;
    height: 0.64rem;
    line-height: 0.64rem;
    margin-top: .19rem;
    display: flex;
    > .v-c-box{
      .all-center;
      line-height: normal;
      background-color: #f61919;
      height: .64rem;
      width: 1.4rem;
      border-radius: 4px;
      span{
        color: #fff;
      }
    }
  }
}

.delivery-list{
  .delivery-item{
    margin-top: .2rem;
    >.top{
      border-bottom: 1px solid @border-color;
      .contact-message{
        display: flex;
        font-size: .28rem;
        color: @black;
        margin-bottom: 0.1rem;
        line-height: 1.6;
        > .title {
          flex-shrink: 0;
        }
        .address{
          font-size: .26rem;
          color: @light-black;
        }
      }
      .tel{
        margin-bottom: 0.15rem;
      }
      .date{
        color: @gray;
        font-size: .22rem;
        line-height: 1.45;
        margin-bottom: .2rem;
        > span{
          color: @blue;
        }
      }
    }
    > .bottom{
      > input,textarea{
        z-index:-1;
        position: absolute;;
        top:-1000px;
        left:-1000px;
      }
    }
  }
}
.middle-btn-group{
  padding: .2rem 0;
  text-align: center;
  position: relative;
  .btn{
    background-color: #fff;
    border: 1px solid @green;
    color: @green;
    height: .42rem;
    line-height: .42rem;
    width: 2.06rem;
    font-size: .26rem;
    display: inline-block;
    & + .btn{
      margin-left: .6rem;
    }
  }
}
.dev-modal{
  .modal_content{
    margin-top: .6rem;
    padding: .04rem;
    .form-group{
      margin-bottom: .38rem;
      label{
        font-size: .28rem;
      }
      input[type=radio]{
        vertical-align: top;
        margin-right: .16rem;
      }
    }
    .flex-center{
      margin-bottom: .38rem;
      display: flex;
      align-items: center;
      label{
        font-size: .28rem;
      }
      .ml16{
        margin-left: .16rem;
      }
    }
    .cancel-order{
      textarea{
        width: 89%;
        margin-left: 8%;
        height: 0.68rem;
        border-radius: 4px;
        border: solid 1px #e5e5e5;
        padding: .1rem;
        box-sizing: border-box;
      }
    }
    .bottom-btn-group{
      margin-top: .53rem;
      text-align: center;
      .btn{
        width: 1.8rem;
        height: 0.5rem;
        & + .btn{
          margin-left: 1.1rem;
        }
      }
      .btn-green{
        background-color: #68c258;
      }
    }
  }
}
.user-title{
  padding: 0.2rem 0;
  display: flex;
  justify-content: space-between;
  > .title{
    color: @green;
    font-size: .32rem;
  }
  .nav-right{
    display: flex;
  }
  .user-message{
    position: relative;
    .all-center();
    &:first-child{
      margin-right: .3rem;
    }
    .text{
      margin-left: .1rem;
    }
    .v-text-box{
      .all-center();
      position: absolute;
      background-color: @orange;
      border-radius: 50%;
      right: -0.2rem;
      top: -0.14rem;
      width: 0.36rem;
      height: 0.36rem;
      line-height: normal;
    }
    .num{
      font-size: .36rem;
      transform: scale(.5);
      //font-size: 12px;
      white-space: nowrap;
      color: #fff;
    }
  }
}
.subtitle-nav{
  padding: 0.2rem 0 0.16rem 0;
  border-bottom: 1px solid @border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title{
    color: @black;
    font-size: .28rem;
  }
  .nav{
    font-size: .24rem;
    color: @light-black;
  }
}
.order-menu{
  padding: 0.4rem 0.2rem 0.4rem 0.2rem;
  display: flex;
  justify-content: space-between;
  .item{
    > .icon-box{
      position: relative;
      text-align: center;
      margin-bottom: .06rem;
      .v-text-box{
        line-height: normal;
        .ball-message;
        right: -.1rem;
        top: -0.2rem;
      }
      .num{
        font-size: .36rem;
        white-space: nowrap;
        transform: scale(.5);
        line-height: normal;
      }
    }
    .desc{
      color: @light-black;
      font-size: .24rem;
    }

  }
}
.order-list{
  .middle-btn-group{
    padding-top: .2rem;
    border-top: 1px solid @border-color;
  }
}
.line-message-box{
  > .header{
    display: flex;
    align-items: center;
    .status{
      margin-left: .1rem;
      margin-right: .64rem;
    }
    .invoice-desc{
      padding-bottom: .04rem;
    }
  }
  .header-title{
    color: @black;
    font-size: .28rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    font-weight: bold;
  }
  > .line{
    font-size: .26rem;
    display: flex;
    margin-bottom: .2rem;
    line-height: 1.6;
    .filed{
      color: @black;
      flex-shrink: 0;
      width: 1.1rem;
      margin-right: .2rem;
      text-align: right;
    }
    .text{
      flex-grow: 1;
      color: @gray;
    }
  }
  &.shipment{
    > .line{
      margin-bottom: .44rem;
    }
  }
  &.w-130{
    .line{
      .filed{
        width: 1.3rem;
      }
    }
  }
  &.w-150{
    .line{
      .filed{
        width: 1.5rem;
      }
    }
  }
  &.verification{
    .line{
      margin-bottom: .48rem;
      .filed{
        margin-right: 0;
      }
      .parameter{
        background-color: #ededed;
        border-radius: 2px;
        font-size: .26rem;
        color: @light-black;
        padding: 0.1rem 0.2rem;
      }
      &.ai-c{
        align-items: center;
        .text{
          flex-grow: 0;
          margin-right: .13rem;
        }
      }
      &:last-child{
        margin-bottom: .2rem;
      }
    }
  }
}
.code-message{
  display: flex;
  align-items: center;
  flex-direction: column;
  .code-detail{
    font-size: .36rem;
    margin-top: .54rem;
    margin-bottom: 0.44rem;
    color: @black;
    > span{
      color: @orange;
    }
  }
  .code-image{
    .img-wrap(3.66rem,3.66rem);
    border: 1px solid @border-color;
    box-sizing: border-box;
  }
  .text{
    margin: .28rem 0;
    font-size: .24rem;
    color: @gray;
  }
}
.show-goods{
  font-size: .28rem;
  color: @black;
  margin: .3rem 0;
}
.show-delivery-message{
  display: inline-block;
  margin-top: .2rem;
}
.delivery-message-list{
  font-size: .26rem;
  .deliver-message-head{
    color: @black;
    display: flex;
    justify-content: space-between;
  }
  .deliver-message-body{
    color:  @light-black;
    > .item{
      border-bottom: 1px solid @border-color;
      padding: 0.3rem 0 0.28rem 0;
      display: flex;
      justify-content: space-between;
      &:last-child{
        border-bottom: none;
      }
    }
  }
  .parameter{
    max-width: 1.82rem;
    width: 1.82rem;
    line-height: 1.7;
  }
  .date{
    max-width: 2.5rem;
    width: 2.5rem;
    text-align: left;
  }
}
// .sp-pay-success{
//   .operation{
//     justify-content: center;
//     a + a{
//       margin-left: .3rem;
//     }
//   }
// }
.operation-count{
  padding-left: 1.5rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid @border-color;
  display: flex;
  align-items: center;
  .title{
    font-size: .26rem;
    color: @light-black;
    margin-right: .13rem;
  }
  .tail-text{
    color: @gray;
    font-size: .24rem;
  }
  .operation-area{
    margin-right: .2rem;
    display: flex;
    .num-toggle-render{
      display: flex;
      .add,.subtract{
        width: 0.62rem;
        height: 0.56rem;
        background-color: #dbdbdb;
        border-radius: 2px;
        font-size: .36rem;
        color: @light-black;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      label > input{
        width: 0.96rem;
        height: 0.56rem;
        text-align: center;
        border-radius: 2px;
        border: solid 0.01rem #ebeced;
        font-size: .3rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .symbol{
      width: 0.62rem;
      height: 0.56rem;
      background-color: #dbdbdb;
      border-radius: 2px;
      font-size: .36rem;
      color: @light-black;
      display: flex;
      align-items: center;
      justify-content: center;
      &.prevent{
        color: @red;
      }
    }
    .subtract{
      margin-right: .06rem;
    }
    .add{
      margin-left: .06rem;
    }
    .num{
      width: 0.96rem;
      height: 0.56rem;
      text-align: center;
      border-radius: 2px;
      border: solid 0.01rem #ebeced;
      font-size: .3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.msg-page{
  height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .msg-content{
    text-align: center;
    > img{
      width: 2.02rem;
	    height: 2.04rem;
      display: inline-block;
    }
    h1{
      font-size: 0.36rem;
      font-weight: bold;
      margin-top: .3rem;
      color: @black;
      margin-bottom: .3rem;
    }
    .content{
      color: @gray;
      font-size: .26rem;
    }
  }
}
.rule-content {
  -webkit-overflow-scrolling: touch;
}
.fixation-box{
  position: fixed;
  left: 0;
  z-index: 9;
  top: 1.18rem;
  width: 100%;
  padding-left: 15px;
  background-color: #fff;
  padding-right: 15px;
}
.orange-link-box{
  margin-bottom: .4rem;
  span,a{
    cursor: pointer;
    &+span,&+a{
      margin-left: .2rem;
    }
  }
}

.colours-line{
  > img{
    width: 100%;
    height: .06rem;
    display: block;
  }
}
.order-new-address{
  > .line:nth-child(2){
    border-top: 1px solid @border-color;
  }
  .expressage-message{
    padding-top: .3rem;
    color: @blue;
    font-size: .3rem;
    display: flex;
    justify-content: space-between;
    .top{
      display: flex;
      .tel{
        padding-left: .3rem;
      }
      &.column{
        flex-direction: column;
      }
    }
    .count-down{
      font-size: .26rem;
      margin-top: .1rem;
      color: @gray;
    }
    &.blue{
      color: @blue;
      padding-bottom: .2rem;
    }
    &.orange{
      color: @orange;
      padding-bottom: .32rem;
    }
  }

  .address-message-detail{
    padding-bottom: .3rem;
    &.pt30{
      padding-top: .3rem;
    }
    .top{
      display: flex;
      font-size: .24rem;
      color: @black;
      padding-bottom: .1rem;
      .tel{
        padding-left: .2rem;
      }
    }
    .address{
      max-width: 5.5rem;
      font-size: .26rem;
      color: @light-black;
      line-height: 1.4;
    }
  }
}
.modal_tips_box{
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .3rem;
  .tips-text{
    color: #999999;
    font-size: 0.32rem;
    padding-left: 0.3rem;
    color: #999999;
    font-size: 0.30rem;
    padding-left: 0.3rem;
    letter-spacing: .02rem;
    .all-center;
  }
}
