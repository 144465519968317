.newfooter {
    background-color: #fff;
    padding: 0 0.3rem;
    .footer-top {
        background-color: #fff;
        padding: 0.3rem 0;
        font-size: 0.26rem;
        color: #666;
        a {
            color: #666;
        }
        .logo {
            display: flex;
            justify-content: center;
            height: 0.75rem;
            margin-bottom: 0.45rem;
            > img {
                height: 100%;
                width: auto;
            }
        }
        .organize {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.47rem;
            line-height: 1.9;
            .bottom {
                width: 0.01rem;
                height: 0.65rem;
                background-color: #eee
            }
            .letter-space {
                letter-spacing: 0.04rem;
                span {
                    letter-spacing: 0;
                }
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            li {
                margin-top: 0.3rem;
                &:first-child {
                    margin-top: 0;
                }
                &.texturl {
                    margin-left: -0.3rem;
                    a {
                        margin-left: 0.3rem;
                    }
                }
                &.website {
                    margin-left: -0.3rem;
                    a {
                        margin-left: 0.3rem;
                    }
                }
            }
        }
    }
    .footer-bottom {
        padding: 0.2rem 0;
        background-color: #7cca50;
        margin-right: -15px;
        margin-left: -15px;
    }
}

.common_popup_xh {
    display: none;
    box-sizing: border-box;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    .popup_mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
    }
}

.page-bg-new {
    display: flex;
    padding-top: 1.2rem;
    flex-direction: column;
    flex-grow: 1;
    background-color: #f2f3f5;
    .container {
        padding: 0 15px;
    }
    .dropload-down {
        background-color: #f2f3f5;
        margin: 0 -15px;
    }
}

.page-topic-wish {
    .slide_show {
        margin-left: 0;
        margin-right: 0;
    }
    .new-row {
        background-color: transparent;
    }
    .wish-topic-wrapper {
        padding: 0 15px;
        background-color: #fff;
    }
    .wish_topic_list {
        .mr-row {
            margin-right: -15px;
            margin-left: -15px;
            background-color: #f2f3f5;
        }
        .topic_item {
            display: flex;
            padding-bottom: 0.3rem;
            padding-top: 0.3rem;
            .img_box {
                flex-shrink: 0;
                width: 3.03rem;
                height: 1.96rem;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: 6px;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .content {
                width: calc(100% - 3.03rem);
                padding: 0.1rem 0.25rem;
                .title {
                    height: 0.82rem;
                    line-height: 1.5;
                   font-size: 0.28rem;
                   color: #333;
                }
                .text {
                    margin-top: 0.15rem;
                    font-size: 0.24rem;
                    color: #999;
                    > span {
                        color: #fd7400;
                    }
                }
            }
        }
    }
}
.xh_stats_3 {
    width: calc(100%);
    flex-wrap: wrap;
    .stats_list_item {
        border-right: none !important;
    }
    .line {
        display: block;
        width: 0.01rem;
        background-color: #e2e5e1;
    }
}



.add-together-donate {
    justify-content: unset;
    h3 {
        border-bottom-color: transparent;
        color: unset;
        &.active {
            color: #68c258;
            border-bottom-color: #68c258;
        }
        & + h3 {
            margin-left: 0.6rem;
        }
    }
}
.together-donation-panel {
    .together-donation-list {
        li {
            margin-top: 0.3rem;
            a {
                width: calc(100%);
                display: flex;
                align-items: center;
                padding-bottom: 0.3rem;
                border-bottom: 0.01rem solid #f2f3f5;
                .img_box {
                    flex-shrink: 0;
                    width: 0.78rem;
                    height: 0.78rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 50%;
                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .content {
                    line-height: 1.5;
                    box-sizing: border-box;
                    width: calc(100% - 0.78rem);
                    padding-left: 0.3rem;
                    .team_name {
                        font-size: 0.26rem;
                    }
                    .support {
                        margin-top: 0.08rem;
                        font-size: 0.24rem;
                        color: #666;
                    }
                    .detail {
                        margin-top: 0.08rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 0.24rem;
                        color: #666;
                        span {
                            color: #f61919;
                        }
                    }
                }
            }
            &:last-child {
                a {
                    border-bottom: none;
                }
            }
        }
    }
}
.update-project-detail {
}

// 一起捐的表单页面
.donate_together {
    .top {
        font-size: 0.26rem;
        color: #666;
        padding: 0.3rem 15px;
        display: flex;
        align-items: baseline;
        border-bottom: 0.01rem solid #e6e6e6;
        .text {
            flex-shrink: 0;
        }
        .project_name {
            font-size: 0.28rem;
            color: #333;
            line-height: 1.6;
        }
    }
}
.project_form {
    padding-top: 0.3rem;
    font-size: 0.26rem;
    color: #333;
    .form-group {
        .desc {
            margin-bottom: 0.2rem;
            span {
                color: #999;
            }
        }
        & + .form-group {
            margin-top: 0.4rem;
        }
        .input_text_default {
            padding: 0.2rem;
            border-radius: 0.06rem;
            font-size: 0.24rem;
        }
        .tips_list {
            margin-top: 0.1rem;
            color: #999;
            font-size: 0.24rem;
            p {
                margin-top: 0.06rem;
            }
        }
        .check_box {
            display: flex;
            align-items: center;
            .check_box_item {
                display: flex;
                flex-shrink: 0;
                .input_box {
                    box-sizing: border-box;
                    width: 0.2rem;
                    height: 0.2rem;
                    border: 0.01rem solid #ccc;
                    padding: 0.02rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 0.1rem;
                    p {
                        border-radius: 50%;
                        width: 0.14rem;
                        height: 0.14rem;
                    }
                }
                &.active {
                    color: #68c258;
                    .input_box {
                        border-color: #68c258;
                        p {
                            background-color: #68c258;
                        }
                    }
                }
                & + .check_box_item {
                    margin-left: 0.4rem;
                }
            }
        }
        .project_cheer {
            resize: none;
            height: 2.2rem;
            border-radius: 0.06rem;
            line-height: 1.6;
        }
    }
    .raise_form {
        .raise_input_box {
            position: relative;
            .input_text_default {
                padding-right: 0.6rem;
            }
            .unit {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 0.6rem;
            }
        }
        .check_box_for_raise {
            font-size: 0.24rem;
            margin-top: 0.2rem;
            display: flex;
            align-items: center;
            .check_raise {
                box-sizing: border-box;
                width: 0.24rem;
                height: 0.24rem;
                border: 0.01rem solid #999;
                border-radius: 0.04rem;
                margin-right: 0.08rem;
                padding: 0.03rem;
                div {
                    width: 100%;
                    height: 100%;
                }
            }
            &.active {
                .check_raise {
                    border-color: #68c258;
                    > div {
                        background-color: #68c258;
                    }
                }
            }
        }
    }
    .agreement_form {
        margin-top: 0.8rem;
        .check_box_for_agreement {
            display: flex;
            align-items: center;
            color: #68c258;
            .check_agree {
                margin-right: 0.1rem;
                border-radius: 50%;
                width: 0.24rem;
                height: 0.24rem;
                background-color: #bbbbbb;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.active {
                .check_agree {
                    background-color: #68c258;
                }
            }
        }
    }
    .btn_submit_project {
        margin-top: 0.3rem;
        height: 0.7rem;
        background-color: #68c258;
        color: #fff;
        width: 100%;
        border-radius: 0.35rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        margin-bottom: 0.7rem;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
.donate_together_popup {
    .modal_box {
        border-radius: 0.08rem;
        padding: 0.4rem 0.3rem 0.4rem 0.3rem;
        width: calc(100% - 1.2rem);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
    }
    .title {
        text-align: center;
        font-size: 0.28rem;
        color: #333;
        font-weight: bold;
        padding-bottom: 0.3rem;
        border-bottom: 0.01rem solid #ccc;
    }
    .scroll_panel {
        font-size: 0.26rem;
        line-height: 1.8;
        height: 5rem;
        overflow-y: auto;
        padding: 0.2rem;
    }
    .modal_content {
        max-height: unset;
    }
}
// 一起捐的index页面
.donate_together_page {
    .team_introduce {
        margin-top: 0.45rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .top_avatar {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            .avatar_head {
                width: 1.58rem;
                height: 1.58rem;
                border-radius: 50%;
                overflow: hidden;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .edit_donation {
                position: absolute;
                top: 0;
                right: 0;
                color: #fd7400;
                font-size: 0.24rem;
            }
        }
        .team_name {
            margin-top: 0.2rem;
            font-size: 0.26rem;
        }
        .slogan {
            line-height: 1.5;
            margin-top: 0.2rem;
            width: 100%;
            color: #999;
            font-size: 0.24rem;
        }
    }
    .project-list {
        .project_item {
            .project_item_img {
                width: 2.5rem;
                height: 1.66rem;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
.avite_friend_popup {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: .3s;
    transform: translateY(100%);
    &.show {
        transform: translateY(0);
    }
    .content {
        width: 100%;
        box-sizing: border-box;
        padding: 0.35rem 0.3rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        height: 3.1rem;
        width: 100%;
        border-radius: 0.4rem 0.4rem 0 0;
        .title {
            color: #68c258;
            font-size: 0.32rem;
            padding-bottom: 0.3rem;
            border-bottom: 0.02rem solid #68c258;
            text-align: center;
        }
        .btns {
            .btn_item {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.3rem;
                font-size: 0.28rem;
                color: #666;
                > i {
                    margin-right: 0.16rem;
                }
                & + .btn_item {
                    border-top: 0.01rem solid #eee;
                }
            }
        }
    }
    .popup_mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
    }
}
.canvas_html {
    position: fixed;
    top: -100%;
    left: -100%;
    margin: 0 auto;
    border-radius: 0.08rem;
    overflow: hidden;
    width: 6rem;
    .img_box {
        width: 100%;
        height: 3.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .img_container {
        background-color: #fff;
        width: 100%;
        padding: 0 0.2rem;
        box-sizing: border-box;
    }
    .mainContent {
        box-sizing: border-box;
        padding-bottom: 0.3rem;
        border-bottom: 0.01rem solid #eee;
        .content {
            box-sizing: border-box;
            padding: 0.4rem 0 0.3rem 0;
            display: flex;
            width: calc(100%);
            .left {
                margin-right: 0.2rem;
                .avatar {
                    border-radius: 50%;
                    flex-shrink: 0;
                    width: 1.4rem;
                    height: 1.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .right {
                width: calc(100% - 1.4rem - 0.2rem);
                .name {
                    line-height: 1.5;
                    font-size: 0.28rem;
                }
                .p_text {
                    margin-top: 0.2rem;
                    font-size: 0.24rem;
                    color: #666;
                    line-height: 1.5;
                }
            }
        }
        .money {
            font-size: 0.24rem;
            color: #666;
            > span {
                color: #f61919;
            }
        }
        position: relative;
        &::after {
            display: block;
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 2.1rem;
            height: 2.23rem;
            background-image: url(../../../../mobile/img/logo_icon.png);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .ercode_bottom {
        margin-top: 0.26rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 0.36rem;
        .ercode_img_box {
            flex-shrink: 0;
            width: 1.08rem;
            height: 1.08rem;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-y: hidden;
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .logo_box {
            height: 0.62rem;
            width: auto;
            > img {
                width: auto;
                height: 100%;
            }
        }
    }
}
.donate_canvas {
    width: 6rem;
    .modal_content {
        width: 100%;
        height: auto;
    }
}
.donate_canvas_Popup {
    display: none;
    z-index: 22;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    padding: 0.6rem;
    box-sizing: border-box;
    .modal_box {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .top {
            position: absolute;
            top: 0.26rem;
            right: 0.26rem;
        }
        .modal_content {
            width: 100%;
            height: auto;
            border-radius: 0.08rem;
            overflow: hidden;
            > img {
                width: 100%;
                height: auto;
            }
        }.modal_foot {
            color: #fff;
            font-size: 0.3rem;
            margin-top: 0.3rem;
            text-align: center;
        }
    }
}

// 心愿引导页
.page-wish-guide {
    background-image: url('@{img_dir}/guide-bg.jpg');
    background-size: cover;
    background-repeat-y: repeat;
    background-repeat-x: no-repeat;
    position: relative;
    padding-top: 1.75rem;
    &::before {
        right: 0;
        top: 0;
        position: absolute;
        display: block;
        content: '';
        background-image: url("@{img_dir}/guide-top-bg.png");
        width: 100%;
        height: 4.26rem;
        background-repeat: no-repeat;
        background-size: cover;
    }
    color: #fff;
    .container {
        padding: 0 0.3rem;
        .intructor {
            .slogan {
                margin-left: 0.46rem;
                margin-bottom: 0.98rem;
                &::before {
                    display: block;
                    content: '';
                    background-image: url("@{img_dir}/slogan.png");
                    width: 3.62rem;
                    height: 1.53rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                }
            }
        }
        .data {
            margin-top: 0.6rem;
            background-color: #fff;
            border-radius: 0.09rem;
            position: relative;
            &::before {
                position: absolute;
                top: -0.38rem;
                right: -0.24rem;
                display: block;
                content: "";
                .icon-big-heart;
            }
            &::after {
                position: absolute;
                bottom: -0.09rem;
                left: -0.2rem;
                display: block;
                content: "";
                .icon-small-heart;
            }
        }
        .guide-common-list {
            border-radius: 0.09rem;
            margin-top: 0.62rem;
            background-color: #fff;
            padding: 0.4rem 0.2rem 0.77rem 0.2rem;
            .head {
                display: flex;
                align-items: center;
                .title {
                    color: #222;
                    font-size: 0.32rem;
                    font-weight: bold;
                }
                > p {
                    font-size: 0.28rem;
                    color: #999;
                    display: flex;
                    align-items: center;
                    &::before {
                        display: block;
                        content: "";
                        width: 0.02rem;
                        height: 0.24rem;
                        background-color: #ccc;
                        margin: 0 0.2rem;
                    }
                }
            }
            .list {
                .wish-list {
                    > li {
                        .img {
                            > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .wish-info {
                            .left {
                                width: 1.6rem;
                            }
                        }
                    }
                }
                .row {
                    margin: 0 -0.1rem;
                }
                .more_btn {
                    display: block;
                    line-height: 0.7rem;
                    text-align: center;
                    height: 0.7rem;
                    color: #fff;
                    background-color: #e93a49;
                    font-size: 0.28rem;
                    margin-top: 0.4rem;
                    border-radius: 0.35rem;
                }
            }
        }
        .guide-partner {
            margin-top: 0.87rem;
            display: flex;
            justify-content: center;
            .partner-wrapper{
                position: relative;
                width: 100%;
                .title-container {
                    transform: translate(-50%, -50%);
                    top: 0;
                    left: 50%;
                    position: absolute;
                    z-index: 2;
                }
                .panel_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 3.2rem;
                    height: 0.7rem;
                    border-radius: 0.35rem;
                    background-color: #ffeede;
                    position: relative;
                    padding: 0;
                    &::before {
                        position: absolute;
                        display: block;
                        content: "";
                        top: 0.07rem;
                        right: -0.21rem;
                        .icon-item-big-heart;
                    }
                    &::after {
                        position: absolute;
                        display: block;
                        content: "";
                        bottom: 0rem;
                        left: -0.05rem;
                        .icon-item-small-heart;
                    }
                    h2 {
                        padding: 0;
                        border: none;
                        font-size: 0.32rem;
                        color: #af161b;
                    }
                }
                .home-partner-swiper{
                    padding-top: 0.6rem;
                    background-color: #fff;
                    border-radius: 0.09rem;
                  margin-bottom: 0.35rem;
                  padding-bottom: .3rem;
                  .swiper-slide{
                    .flex-center;
                    height: 1.4rem;
                    a{
                      padding: .15rem;
                      height: 1.1rem;
                      .flex-center;
                      img{
                        max-width: 100%;
                        max-height: 100%;
                      }
                    }
                  }
                  .swiper-pagination{
                    bottom: 0;
                    width: 100%;
                    text-align: center;
                    .swiper-pagination-bullet{
                      width: .24rem;
                      height: .08rem;
                      background-color: #dbdbdb;
                      border-radius: 25px;
                      opacity: 1;
                      margin: 0 .1rem;
                      &.swiper-pagination-bullet-active{
                        background-color: #af161b;
                      }
                    }
                  }
                }
              
              }
        }
    }
}

.auto-height {
    height: unset;
}

.msg-center{
    padding-top: .4rem;
    .msg-center-head{
        display: flex;
        justify-content: space-between;
        select{
            width: 1.9rem;
            height: .5rem;
            background-color: #fff;
            color: #90D147;
            border-radius: .04rem;
            border: 1px solid #90D147;
            font-size: .26rem;
            appearance: none;
            line-height: .5rem;
            background-image: url("/Public/img/icon/select-drow.png"); 
            background-repeat: no-repeat;
            padding-left: .2rem;
            background-size: .18rem;
            background-position: 1.52rem center;
        }
        .mark-btn{
            display: flex;
            align-items: center;
            width: 2.3rem;
            background: #90D147;
            color: #fff;
            justify-content: center;
            border-radius: .04rem;
            span{
                margin-left: .1rem;
            }
        }
    }
    .msg-list{
        margin-top: .3rem;
        .item{
            display: block;
            padding: .26rem 0;
            border-bottom: 1px solid #F2F3F5;
            .title{
                position: relative;
                display: flex;
                align-items: center;
                &::before{
                    content: '';
                    display: block;
                    width: .12rem;
                    height: .12rem;
                    background: #F61919;
                    border-radius: 50%;
                    flex-shrink: 0;
                    margin-right: .16rem;
                }
                span{
                    display: block;
                    font-size: .26rem;
                    color: #333;
                    width: calc(100% - .28rem);
                }
            }
            .date{
                font-size: .24rem;
                color: #999;
                margin-top: .26rem;
                margin-left: .28rem;
            }
            &:last-child{
                border: none;
            }
            &.read{
                .title{
                    &::before{
                        opacity: 0;
                    }
                    span{
                        color: #999;
                    }
                }
            }
        }
    }
    .noData{
        margin-top: .9rem;
        img{
            display: block;
            width: 3.33rem;
            margin: 0 auto;
        }
        .text{
            text-align: center;
            font-size: .26rem;
            margin-top: .36rem;
            color: #666;
        }
    }
}
.mark-popup-control{
    display: flex;
    align-items: center;
    justify-content: center;
    .btns{
        width: 1.6rem;
        height: .5rem;
        border: 1px solid #FD7400;
        line-height: .5rem;
        border-radius: .25rem;
        text-align: center;
        font-size: .28rem;
        margin: 0 .2rem;
        &.close-mark{
            background: #fff;
            color: #FD7400;
        }
        &.confirm-mark{
            background: #FD7400;
            color: #fff;
        }
    }
}

.msg-detail{
    margin-top: .34rem;
    .title{
        font-size: .3rem;
        font-weight: bold;
        line-height: 1.6;
    }
    .date{
        font-size: .24rem;
        color: #999;
        margin-top: .4rem;
        border-bottom: 1px solid #999;
        padding-bottom: .36rem;
        margin-bottom: .4rem;
    }
}
.fix-btn-box-placeholder{
    height: .9rem;
}
.fix-btn-box{
    width: 100%;
    position: fixed;
    height: .9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0px 2px 20px 0px rgba(28,119,12,0.2);
    left: 0;
    bottom: 0;
    .back{
        width: 6.58rem;
        height: .6rem;
        line-height: .6rem;
        text-align: center;
        border-radius: .3rem;
        background: #68C258;
        color: #fff;
        font-size: .28rem;
    }
}


.pc_wish_topic_video_model {
    .modal_box {
        width: 100%;
        height: 100%;
        padding: 0.6rem 0.3rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .btnClose {
            top: 0 !important;
            right: 0.1rem !important;
        }
        .modal_content {
            overflow: unset;
            max-width: 100%;
            max-height: 100%;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
        }
    }
}
.icon-new-xh {
    height: 19px;
    width: auto;
    max-width: 19px;
    max-width: 19px;
}

.activity-tag {
    position: absolute;
    top: 0;
    right: 0;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: center;
    font-size: 0.24rem;
    color: #fff;
    padding: 0 0.1rem;
    z-index: 3;
    &.green {
        background-color: #68c258;
    }
    &.orange {
        background-color: #fd7400;
    }
    &.gray {
        background-color: #CCCCCC;
    }
}