.sz-org-detail{
  .stats_list{
    .stats_list_item{
      flex-grow: 1;
    }
  }
}
.help-list-wrap{
  .tab-title{
    .no-under-line{
      border-bottom: 0;
      a{
        color: #333;
      }
    }
    a{
      padding: 0;
      color: @green;
    }
  }
}
.sy-check-donate{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  .check-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    .check-tips{
      .text-green;
      font-weight: bold;
      font-size: .3rem;
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

.sx-page .v-list .v-list-item .inner-box .p-right{
  float: right;
}

.sy-success-middle{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 .3rem;
  .check-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    .check-icon{
      width: 1rem;
      height: 1rem;
      margin-bottom: .5rem;
    }
    .check-tips{
      font-weight: bold;
      font-size: .3rem;
    }
  }
  .operation{
    width: 100%;
    margin-top: 1rem;
  }
}

.page-wish-index{
  .h-stats-list > li:nth-child(2n+1){
    width: 40%;
  }
  .h-stats-list > li:nth-child(2n){
    width: 60%;
  }
}