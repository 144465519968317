@import "var";
@import "clearDefault";

/*灰色bar*/
.bar {
  background: @bg-bar;
  height: 0.1rem;
  min-height: 0.1rem;
}

//文字颜色
.text-red {
  color: #f61919 !important;
}

.page{
  padding-bottom: 1rem;
}

/*选项卡*/

.tab_box {
  font-size: .28rem;
  height: .8rem;
  line-height: .8rem;
}

.tab_box .tab_item {
  background: #f1f1f1;
}

.tab_box .tab_item_active {
  background: #e24c37;
  color: #ffffff;
  transition: background 1s;
  box-shadow: 0 2px #d83627;
}

/*弹出层*/

.mask {
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, .7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  transition: opacity .3s;
}

.mask .left_bar {
  height: 100%;
  width: calc(100% - 5.2rem);
}

.mask .right_bar {
  width: 5.2rem;
  background: #ffffff;
  height: 100%;
  transform: translateX(4.4rem);
  transition: transform .5s;
  float: right;
  padding-bottom: .2rem;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}

.mask .right_bar .right_bar_content {
  padding: 0 .2rem .2rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .filter-list{
    flex-grow: 1;
  }
}

.right_bar_menu .right_bar_item {
  border-bottom: 1px solid #f1f1f1;
  display: block;
  margin: 0 .3rem;
  padding-left: 0.3rem;
  font-size: .3rem;
  height: 1rem;
  line-height: 1rem;
  color: #333333;
  padding-right: 0.3rem;
}

.right_bar_menu {
  padding-bottom: .2rem;
}

.mask .right_bar_menu .login {
  height: .8rem;
  line-height: .8rem;
  font-size: .26rem;
  color: #e66452;
  border: 1px solid #e66452;
  background: #ffffff;
  text-align: center;
  border-radius: 2px;
  margin-bottom: .3rem;
  display: block;
  margin-right: .3rem;
  margin-left: .3rem;
}

.single_login {
  margin-top: .4rem;
}

.right_bar_menu .info_open {
  padding-left: 15px;
  background: #e24c37;
  border-radius: 3px;
  height: 1.4rem;
  margin-left: .3rem;
  margin-right: .3rem;
  display: none;
}

.right_bar_menu .info_open > a {
  font-size: .24rem;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  display: block;
  height: .7rem;
  line-height: .7rem;
  padding-left: .3rem;
}

.right_bar_menu .info_open > a:last-child {
  border: 0;
}

/*个人/机构登陆*/

.login_logo {
  margin-top: 1.1rem;
  width: 3rem;
  height: 2.1rem;
}

/*项目推荐*/

.recommend_project {
  position: relative;
}

.recommend_project_top_box {
  width: 100%;
}

.recommend_project_top_box .project_form {
  margin-bottom: -.9rem;
}

.recommend_project .recommend_project_left_top {
  font-size: .34rem;
  width: 2rem;
  margin-right: .3rem;
  font-weight: normal;
  vertical-align: middle;
  line-height: .8rem;
}

.recommend_project .recommend_project_left_top > img {
  width: .43rem;
  vertical-align: -.1rem;
}

.recommend_project_right_top {
  width: calc(100% - 2.3rem);
  overflow: hidden;
  position: relative;
  top: -.8rem;
  float: right;
}

.recommend_project_right_top input {
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  line-height: .8rem;
  padding-left: .2rem;
  padding-right: .8rem;
  border: 0;
  background: #eeeeee;
  border-radius: 2px;
  width: 100%;
}

.recommend_project_right_top > img {
  width: .44rem;
  height: .44rem;
  position: absolute;
  right: .3rem;
  top: 50%;
  z-index: 1;
  margin-top: -.22rem;
}

.recommend_project_top_box .type {
  font-size: .14rem;
  clear: both;
  position: relative;
  margin-bottom: .3rem;
}

.recommend_project_top_box .type .top {
  overflow: hidden;
  font-size: .24rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
}

.recommend_project_top_box .type .top .type_title {
  font-weight: 500;
  float: left;
}

.recommend_project_top_box .type .top .get_more {
  font-weight: normal;
  float: right;
  margin-top: .12rem;
  margin-bottom: 0;
  cursor: default;
  font-size: .24rem;
  margin-right: .1rem;
  width: 1.4rem;
}

.recommend_project_top_box .type .top .get_more:after {
  content: "";
  display: inline-block;
  background: url(../img/load_more.png) no-repeat center;
  background-size: cover;
  width: .14rem;
  height: .08rem;
  margin-left: .1rem;
  vertical-align: middle;
}

.recommend_project_top_box .type .type_content {
  letter-spacing: -4px;
  margin-right: -.1rem;
  margin-left: -.1rem;
  font-size: .24rem;
}

.recommend_project_top_box .type .type_content.few {
  float: left;
  width: 5rem;
}

.recommend_project_top_box .type .type_content .type_item {
  border: 1px solid #f5f5f5;
  letter-spacing: 0;
  color: #494949;
  padding: .1rem .25rem;
  margin-bottom: .2rem;
  display: inline-block;
  margin-left: .1rem;
  cursor: pointer;
  vertical-align: text-bottom;
}

.recommend_project_top_box .type .type_content.few .type_item {
  margin-bottom: 0;
}

.recommend_project_top_box .type .type_content .type_item:hover {
  background-color: #e24c37;
  color: #ffffff;
  border: 1px solid #e24c37;
}

.recommend_project_top_box .type .type_content .type_item_active {
  background-color: #e24c37;
  color: #ffffff;
  border: 1px solid #e24c37;
}

.fadeIn {
  opacity: 1;
}

.mb30 {
  margin-bottom: .3rem;
}

.mb20 {
  margin-bottom: .2rem;
}

.mt0 {
  margin-top: 0;
}

/*项目进度条*/
@-webkit-keyframes progress-active {
  from {
    opacity: 0.5;
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
  }
  to {
    opacity: 0.1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes progress-active {
  from {
    opacity: 0.5;
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
  }
  to {
    opacity: 0.1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
.progress {
  background-color: #dedede;
  position: relative;
  height: .16rem;
  border-radius: 50px;
}

.progress .current-num {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e24c37;
  height: 100%;
  max-width: 100%;
  border-radius: 50px;
}

.progress .number {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  background-color: #f6b035;
  color: #fff;
  padding: .05rem .1rem;
}

.popup-item,
.popupLink {
  cursor: pointer;
}




.process {
  display: flex;
  align-items: center;
}

.process_outer {
  position: relative;
  height: .12rem;
  background-color: #ededed;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}

.process_inner {
  position: absolute;
  height: .12rem;
  top: 0;
  left: 0;
  background-color: #68c258;
  border-radius: 5px;
  &.active::before{
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFF;
    border-radius: 5px;
    animation: progress-active 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
}
}

.process_number {
  font-size: .24rem;
  color: #68c258;
  white-space: nowrap;
  width: 1rem;
  text-align: right;
}

.panel_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .3rem 0;
  border-bottom: 1px solid #e5e5e5;
  h2 {
    padding-left: 8px;
    border-left: 2px solid #68c258;
    color: #68c258;
    font-size: .32rem;
  }
  .close-search-mask {
    filter: grayscale(100%);
  }
  .link {
    color: #666;
    font-size: .24rem;
  }
  .btn-link{
    width: 1.55rem;
    height: .45rem;
    border: 1px solid @border-color;
    color: @light-black;
    font-size: .24rem;
    .flex-center;
  }
  &.not-border{
    border: none;
  }
  select{
    color: @light-black;
    font-size: .26rem;
    border-radius: 4px;
    height: .42rem;
    background-color: #e6e6e6;
    padding-left: .2rem;
    padding-right: .2rem;
    border: 0;
  }
}

.tab-title {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3, a {
    font-size: .3rem;
    color: #68c258;
    padding: .2rem 0;
    border-bottom: 3px solid #68c258;
    margin-bottom: -2px;
    display: inline-block;
  }
  .tab-child-button{
    border-radius: 4px;
    padding: 0rem .2rem;
    font-size: .24rem;
  }
  a {
    color: #333;
    border-bottom: 0;
    margin-bottom: 0;
    width: auto;
    padding-left: .15rem;
    padding-right: .15rem;
    & + a {
      margin-left: .9rem;
    }
    &.active {
      color: #68c258;
      border-bottom: 3px solid #68c258;
      margin-bottom: -2px;
    }
  }
  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .right{
    a{
      display: flex;
    }
    .btn{
      border-radius: 4px;
    }
  }
  .tip {
    font-size: 12px;
    color: #fd7400;
  }
  &.not-padding{
    padding: 0;
  }
}



.mask-weixin {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  background: rgba(0, 0, 0, 0.7) url('@{img_dir}share-guide-codonate.png') no-repeat right 0.5rem/contain;
  max-width: 720px;
  margin: 0 auto;
  &.simple{
    background: rgba(0, 0, 0, 0.7) url('@{img_dir}simple-share-mask.png') no-repeat right 0.5rem/contain;
  }
}

//文字类
.text-green {
  color: @green !important;
}

.text-orange {
  color: @orange !important;
}

.text-blue {
  color: @blue !important;
}

.text-black {
  color: @black !important;
}

.text-light-black {
  color: @light-black !important;
}

//标题描述列表
.title-desc-list{
  @title_width: 1.4rem;
  font-size: .24rem;
  .item,li{
    margin-bottom: .4rem;
    font-size: 0;
    overflow: hidden;
    line-height: 1.5;
    & + .item{
      margin-top: .15rem;
    }
    .wrapper{
      font-size: 0;
      overflow: hidden;
    }
    .title{
      width: @title_width;
      float: left;
      font-size: .26rem;
      text-align: right;
      color: @gray;
    }
    .desc{
      margin-left: @title_width;
      font-size: .26rem;
      color: @light-black;
    }
    &.inline {
      display: flex;
      justify-content: space-between;
    }
  }
}

.tips-wrapper{
  color: @gray;
  font-size: .26rem;
  display: flex;
  margin-left: 1.4rem;
  line-height: 1.2;
  margin-top: .15rem;
  i{
    flex-shrink: 0;
    margin-right: .1rem;
    line-height: 1.2;
  }
  .tips-desc{
  }
}

//form表单
.form-control{
  line-height: 1.5;
  display: flex;
  @height: .7rem;
  & + .form-control{
    margin-top: .4rem;
  }
  &.vertical{
    flex-direction: column;
  }
  .wrapper{
    display: flex;
  }
  .field-name{
    display: inline-flex;
    flex-shrink: 0;
    width: 1.4rem;
    justify-content: flex-end;
    color: @gray;
    line-height: @height;
  }
  .form-field{
    flex-grow: 1;
    display: flex;
    position: relative;
    .input-default{
      height: @height;
      line-height: @height;
      flex-grow: 1;
    }
    &.input-unit{
      position: relative;
      .unit{
        display: inline-flex;
        align-items: center;
        margin-left: 5px;
      }
    }
  }
}

//多行裁剪
.multi-text-overflow(@line-height,@num){
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @num;
  -webkit-box-orient: vertical;
  line-height: @line-height;
}

//开关
.switch {
  position: relative;
  display: inline-block;
  width: .76rem;
  height: .32rem;
  > input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: #2196F3;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    &:checked + .slider:before {
      transform: translateX(0.4rem);
    }
  }
  .round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      box-shadow: 0rem 0.02rem 0.06rem 0rem
		rgba(0, 0, 0, 0.38);
      width: 0.42rem;
	    height: 0.42rem;
      left: -0.02rem;
      top: -0.08rem;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
}
//信息小点
.ball-message{
  position: absolute;
  background-color: @orange;
  width: 0.36rem;
  height: 0.36rem;
  line-height: normal;
  .all-center;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}

.wish-tag{
  border-radius: 0;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  height: .4rem;
  padding: 0 .1rem;
  font-size: .24rem;
  background-color: @orange;
  .flex-center;
  &.ta-gray{
    background-color: @bg-gray;
  }
}

.wish-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left{
    color: @gray;
    width: 1.9rem;
  }
  .wish-raise-status{
    background-color: #ffe1e1;
    border: 1px solid #ffb8b8;
    border-radius: 3px;
    color: @red;
    .flex-center;
    padding: .1rem;
    line-height: 1.2;
    margin-left: .1rem;
  }
}

.fund_content {
  border-bottom: none;
  margin: 0;
  display: flex;
  align-items: center;
  .fund_img {
    .img-wrap(1.2rem,1.2rem);
    border-radius: 3px;
    flex: 0 0 1.2rem;
    margin-right: .2rem;
    img.fund_logo {
      max-width: 100%;
      border-radius: 5px;
      height: auto;
    }
  }
  .fund_desc {
    flex-grow: 1;
    h4 {
      line-height: 1.5;
      margin-bottom: .25rem;
      color: #333;
      font-size: .28rem;
      font-weight: normal;
    }
    >span{
      color: @gray;
      font-size: .24rem;
    }
  }
}

.img-list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -.2rem;
  margin-left: -.25rem;
  &.auto-size{
    >li{
      width: auto;
      height: auto;
    }
  }
  >li{
    cursor: pointer;
    margin-bottom: .2rem;
    margin-left: .25rem;
    .img-wrap(calc((100% - .25rem * 3) / 3), 1.35rem);
    border-radius: 4px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.wish-process-list{
  >li{
    padding: .25rem 0 .3rem;
    & + li{
      border-top: 1px solid @border;
    }
    .meta{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: .15rem;
      .left{
        display: inline-flex;
        align-items: center;
        .time{
          color: @black;
          font-size: .26rem;
        }
      }
    }
    .desc{
      font-size: .28rem;
      color: @light-black;
      line-height: 1.5;
      .text-limit2;
    }
    .img-list{
      margin-top: .25rem;
    }
  }
}

.user-info{
  .avatar{
    .avatar(.8rem);
    margin-right: .3rem;
    float: left;
  }
  .meta-content{
    min-height: .8rem;
    width: calc(100% - 1.1rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    .meta-content-top-wrapper{
      min-height: .8rem;
      margin-bottom: 5px;
    }
    .meta-content-top{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      font-size: .28rem;
      .user-name{
        display: inline-block;
        margin-bottom: .1rem;
        line-height: 1.2;
      }
      .donate-count{
        font-size: .26rem;
        margin-bottom: .1rem;
        .donate-count-title{
          flex-shrink: 0;
          color: @light-black;
        }
        .donate-count-desc{
          line-height: 1.2;
        }
      }
      .msg-type{
        font-size: .26rem;
      }
    }
    .msg-time{
      color: @gray;
      font-size: .24rem;
      line-height: 1.5;
    }
  }
}

.msg-list{
  >li{
    padding: .3rem 0;
    box-sizing: content-box;
    border-bottom: 1px solid @border;
    .user-info{
      margin-bottom: .1rem;
    }
    .content{
      .meta-content-desc{
        color: @light-black;
        font-size: .24rem;
        line-height: 1.5;
      }
    }
  }
}


.page__adhere-to-bottom{
  padding-bottom: 1.15rem;
}

.article-mask{
  position: relative;
  &__active{
    overflow: hidden;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      bottom: -1px;
      left: 0;
      right: 0;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(rgba(255, 255, 255, 0.65)), to(#ffffff));
      background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.65), #ffffff);
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.65), #ffffff);
    }

    .load-all{
      color: @green;
      font-size: .24rem;
      position: absolute;
      width: 100%;
      bottom: 5px;
      z-index: 1;
      text-align: center;
      line-height: .5rem;
      height: .5rem;
      cursor: pointer;

      span{
        display: inline-block;
        vertical-align: middle;
      }

      &:after{
        content: '';
        margin-left: .1rem;
        width: .14rem;
        display: inline-block;
        vertical-align: middle;
        height: .08rem;
        //.back_default(load_more,@position: center,@size: cover,@data_type: png);
      }
    }
  }
}

.data-panel-list{
  display: flex;
  flex-wrap: wrap;
  >li{
    width: calc(100% / 3 - 1px);
    box-sizing: border-box;
    height: 1.35rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-top: .3rem;
    padding-bottom: .3rem;
    position: relative;
    border-top: 1px solid @border-color;
    &:nth-child(1),&:nth-child(2),&:nth-child(3){
      border-top: 0;
    }
    &:after{
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -.38rem;
      background-color: @border-color;
      height: .76rem;
      width: 1px;
    }
    &:nth-child(3n){
      &:after{
        display: none;
      }
    }
    .num{
      font-size: .34rem;
      color: @orange;
      white-space: nowrap;
      text-align: center;
    }
    .desc{
      font-size: .24rem;
      color: @light-black;
    }
  }
}

.select-with-search-form{
  display: flex;
  align-items: center;
  margin: 0 -15px;
  padding: .12rem 15px;
  &__green{
    background-color: @green;
    select{

    }
    .search-word{
      background-color: #fff;
    }
  }
  select{
    width: 1.8rem;
    margin-right: .2rem;
    height: .55rem;
    border: 1px solid @border-color;
    border-radius: 4px;
    background-color: #fff;
    color: @light-black;
  }
  .search-word{
    border: 1px solid @border-color;
    padding-left: .2rem;
    height: .55rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    input{
      color: @light-black;
      border: 0;
      flex-grow: 1;
      height: 100%;
    }
    button{
      height: 100%;
      background-color: #fff;
      border: 0;
      padding-right: .2rem;
      padding-left: .2rem;
      .flex-center;
      flex-shrink: 0;
      img{
        width: .24rem;
      }
    }
  }
}

.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-cut-2 {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.notice_msg {
  font-size: 0.24rem;
  margin: 0.3rem 0;
  color: #999;
}

