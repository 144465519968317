@import 'var.less';


.iconGen(@icon,@width:10px,@height:10px){
  display: inline-block;
  background: url("@{icon-dir}/@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: @width @height;
}

//demo:
.icon-white-search{
  .iconGen(white-search,.24rem,.25rem);
}
.icon-activity-msg{
  .iconGen(activity-msg,.2rem,.22rem);
}
.icon-code{
  .iconGen(code,.44rem,.44rem);
}
.icon-green-code{
  .iconGen(green-code,.44rem,.44rem);
}
.icon-home{
  .iconGen(home,.51rem,.5rem);
}
.icon-person{
  .iconGen(person,.5rem,.5rem);
}
.icon-warning{
  .iconGen(warning,.13rem,.13rem);
}
.icon-search{
  .iconGen(search,.21rem,.21rem);
}
.icon-people{
  .iconGen(people,.22rem,.24rem);
}
.icon-time{
  .iconGen(time,.24rem,.24rem);
}
.icon-right{
  .iconGen(right,.19rem,.34rem);
}
.icon-wallet{
  .iconGen(wallet,.44rem,.36rem);
}
.icon-deliver-goods{
  .iconGen(deliver-goods,.4rem,.34rem);
}
.icon-cart{
  .iconGen(cart,.44rem,.37rem);
}
.icon-self-location{
  .iconGen(self-location,.42rem,.34rem);
}

.icon-time-s{
  .iconGen(time-s,.21rem,.2rem);
}
.icon-location{
  .iconGen(location,.14rem,.2rem);
}

.icon-close{
  .iconGen(close,.46rem,.46rem);
}

.icon-notice{
  .iconGen(notice,.24rem,.24rem);
}

.icon-wechat{
  .iconGen(wechat,.28rem,.28rem);
}
.icon-edit{
  .iconGen(edit,.28rem,.3rem);
}
.icon-tips{
  .iconGen(tips,.2rem,.2rem);
}
.icon-message{
  .iconGen(message,.36rem,.32rem);
}
.icon-green-question{
  .iconGen(green-question,14px,14px);
}

.icon-green-question-border{
  .iconGen(green-question-border,14px,14px);
}

.icon-browser{
  .iconGen(browser,742px,742px);
}
.icon-guide{
  .iconGen(guide,192px,296px);
}
.icon-collect{
  .iconGen(collect,.4rem,.4rem);
}
.icon-y-collect{
  .iconGen(y-collect,.4rem,.42rem);
}
.icon-collect-dir{
  .iconGen(collect-dir,.4rem,.34rem);
}
.icon-selected{
  .iconGen(selected,.44rem,.44rem);
}
.icon-min-right{
  .iconGen(min-right,.14rem,.25rem);
}

.icon-prev-page-gray{
  .iconGen(icon-prev-page-gray,.5rem,.47rem);
}

.icon-prev-page-green{
  .iconGen(icon-prev-page-green,1rem,1rem);
}

.icon-index-green{
  .iconGen(icon-index-green,.31rem,.31rem);
}

.icon-home-green{
  .iconGen(icon-home-green,.5rem,.47rem);
}
.icon-yes{
  .iconGen(yes,.13rem,.1rem);
}
.icon-lianjie {
  .iconGen(lianjie,.37rem,.32rem);
}
.icon-zhaopian {
  .iconGen(zhaopian,.32rem,.28rem);
}
.icon-close-new {
  .iconGen(close_new,.4rem,.4rem);
}
.icon-big-heart {
  .iconGen(big-heart,.91rem,.83rem);
}
.icon-small-heart {
  .iconGen(small-heart,.42rem,.43rem);
}
.icon-item-big-heart {
  .iconGen(item-big-heart,.51rem,.49rem);
}
.icon-item-small-heart {
  .iconGen(item-small-heart,.34rem, .35rem);
}



