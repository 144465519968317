@import "tool";

//用户中心

//活动列表
.activity-list{
  //border-bottom: 1px solid @border;
  .row;
  li{
    .info{
      padding: .3rem 15px;
      .img-info(1.9rem,1.25rem,.25rem);
      //border-bottom: 1px solid @border;
      .content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top{
          .title{
            line-height: 1.2;
            .text-overflow;
            margin-bottom: .2rem;
          }
          .status{
            color: @orange;
            font-size: .24rem;
            line-height: 1.2;
            display: flex;
            align-items: center;
            .tooltip{
              margin-left: 3px;
              display: inline-flex;
              align-items: center;
              .icon-green-question{
                display: none;
              }
              .icon-green-question-border{
                display: inline-block;
              }
              &.active{
                .icon-green-question{
                  display: inline-block;
                }
                .icon-green-question-border{
                  display: none;
                }
              }
            }

          }
        }
        .time{
          line-height: 1.2;
          color: @gray;
          font-size: .24rem;
          display: flex;
          align-items: center;
          .tag{
            height: .28rem;
            margin-left: .1rem;
            padding: 0 .1rem;
          }
        }
      }
    }
    .btn-group{
      height: .8rem;
      padding: 0 15px;
      box-sizing: border-box;
      justify-content: space-around;
      width: 100%;
      border-top: 1px solid @border;
      &.btn-group3{
        justify-content: space-between;
        .btn{
          margin-left: 0;
        }
      }
      .btn{
        height: .4rem;
        line-height: unset;
        padding: 0 .4rem;
        width: auto;
        margin-left: 0;
      }
    }
    & + li{
      //&:before{
      //  content: '';
      //  display: block;
      //  .bar;
      //}

    }
    &:last-child{
      //&:after{
      //  content: '';
      //  display: block;
      //  .bar;
      //}
    }
  }
}

//.tag{
//  height: .28rem;
//  display: inline-flex;
//  align-items: center;
//  justify-content: center;
//  //colors:
//  &.tag-gray{
//    background-color: #e6e6e6;
//    color: @light-black;
//  }
//
//  //shapes:
//  &.tag-radius{
//    border-radius: 4px;
//  }
//}

//报名码
.page-applicant-code{
  padding-top: .4rem;
  .container{
    &:after{
      content: '';
      display: block;
      .bg(signin-code,png,center,cover);
      height: 6px;
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%;
    }
    .activity-info{
      padding-bottom: .1rem;
      border-bottom: 1px dashed @border;
      .title{
        line-height: 1.2;
        font-size: .32rem;
        font-weight: bold;
        .text-overflow;
        margin-bottom: .4rem;
      }
    }
    .signin-info{
      padding-top: .5rem;
      margin-bottom: .7rem;
      .signin-code{
        font-size: .3rem;
        margin-bottom: .45rem;
        display: flex;
        align-items: center;
      }
      .title-desc-list{
        li{
          .title{
            float: none;
            width: auto;
          }
          .desc{
            margin-left: 0;
          }
        }
      }
      .btn{
        height: .75rem;
      }
    }
    .sign-status{
      font-size: .26rem;
      margin-bottom: .3rem;
      line-height: 1.2;
    }
    .signin-tips{
      font-size: .24rem;
      padding: .3rem .2rem .2rem .2rem;
      color: @black;
      line-height: 1.5;
      border-radius: 3px;
      border: 1px dashed @border;
      .title{
        margin-bottom: .1rem;
        color: @orange;
      }
    }
  }

}

//页面最低100% 高
html{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  body{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
main{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}
.container-fluid{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  padding-top: 1.2rem;
}

.page{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  .page-desc{
    line-height: 1.5;
    padding: .3rem 0 .15rem;
    font-size: .3rem;
    color: @black;
    .row-border-bottom;
  }
  &.page-shadow{
    .container{
      padding-top: .6rem;
      padding-bottom: .7rem;
      position: relative;
      margin-bottom: .8rem;
      box-shadow: 0 4px 16px 0 rgba(122, 122, 122, 0.8);
      border-radius: 4px;
    }
  }
}

.row-border-bottom{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .row;
    border-bottom: 1px solid @border;
  }
}

//页面最低100% 高 设置结束

//消息盒子
.modal_box{
  &.msg_box{
    .modal_content{
      margin: .6rem 0 .7rem;
      text-align: center;
      line-height: 1.5;
      .title{
        font-size: .32rem;
        font-weight: bold;
        color: @black;
        margin-bottom: .35rem;
      }
      .content{
        font-size: .26rem;
        color: @gray;
      }
    }
  }
}

.page-end{
  padding-bottom: 1.05rem;
  &.page-end-gray{
    background-color: @bg-bar;
  }
}

//队伍资料
.page-team-profile-confirm{
  .page-end;
  .tab-title{
    margin-bottom: .4rem;
  }
  .btn-group{
    margin-top: .9rem;
  }
  .team-cover{
    .img-wrap(4.8rem,3.2rem,4px);
    img{
      vertical-align: top;
    }
  }
  .form-control{
    margin-bottom: .4rem;
    .field-name{
      font-size: .26rem;
    }
  }
}

//成员列表
.member-list{
  li{
    padding: .3rem 0;
    margin-bottom: .1rem;
    display: flex;
    position: relative;
    height: 1.45rem;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: -.1rem;
      .bar;
      .row;
      right: 0;
    }
    .img{
      .avatar(1.45rem);
      margin-right: .2rem;
      flex-shrink: 0;
    }
    .content{
      flex-grow: 1;
      padding: .1rem 0;
      font-size: .24rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 0;
      .top{

      }
      .title{
        font-size: .26rem;
        color: @black;
        margin-bottom: .2rem;
        font-weight: 600;
        span{
          display: inline-block;
          .text-overflow;
          line-height: 1.2;
          max-width: 100%;
        }
      }
      .status{
        color: @orange;
      }
      .num{
        color: @gray;
      }
      .time{
        color: @gray;
        white-space: nowrap;
      }
    }
    .opera{
      display: flex;
      margin-left: .2rem;
      flex-shrink: 0;
      text-align: right;
      .btn{
        line-height: .4rem;
        height: .4rem;
        padding-left: .2rem;
        padding-right: .2rem;
        width: auto;
        margin-bottom: .2rem;
        & + .btn{
          margin-left: .2rem;
        }
      }
    }
  }
  &.signin-list{
    li{
      .content{
        padding: .2rem 0;
      }
      .opera{
        padding: .2rem 0;
        width: 0.8rem;
        justify-content: flex-end;
        line-height: 1.2;
      }
    }
  }
  &.team-signin-list{
    li{
      height: 1.25rem;
      .img{
        .img-wrap(1.9rem,1.25rem,4px);
      }
      .content{
        padding: 0;
        justify-content: space-between;
        .title{
          margin-bottom: 0;
        }
      }
      .opera{
        padding: 0;
      }
    }
  }
}

.info-item{
  font-size: .24rem;
  color: @black;
  display: flex;
  align-items: center;
  line-height: 1.2;
  & + &{
    margin-top: .25rem;
  }
}

//报名用户信息
.page-user-info{
  .user-info{
    display: flex;
    align-items: center;
    padding: .5rem 0;
    border-bottom: 1px solid @border;
    &.team-info{
      .img{
        .img-wrap(2.8rem,1.85rem,4px);
        margin-right: .35rem;
      }
    }
    .img{
      .avatar(2rem);
      margin-right: .5rem;
      flex-shrink: 0;
    }
    .content{
      flex-grow: 1;
      .user-name,.info-item{
        color: @black;
      }
      .user-name{
        line-height: 1.5;
        font-weight: bold;
        font-size: .3rem;
        margin-bottom: .25rem;
      }
      .info-item{
        line-height: 1.2;
      }
    }
  }
  .user-info-list{
    >li{
      padding: .4rem 0 .5rem;
      font-size: .28rem;
      >.title{
        color: @green;
        margin-bottom: .3rem;
      }
      & + li{
        border-top: 1px solid @border;
      }
      .title-desc-list{
        >li{
          >.title{
            color: @light-black;
          }
        }
      }
    }
  }
}

//签到码
.page-signin-code{
  text-align: center;
  .container{
    border-radius: 4px;
    margin-top: .3rem;
    flex-grow: 1;
  }
  .title{
    font-size: .32rem;
    padding: .7rem 0 .4rem;
    border-bottom: 1px dashed @border;
  }
  .content{
    padding-top: .75rem;
    padding-bottom: 1.4rem;
    .img{
      border: 1px solid @border;
      max-width: 4.3rem;
      margin: auto;
      margin-bottom: .8rem;
      padding: .1rem;
      box-sizing: border-box;
    }
    .btn-group{

    }
  }
}

.page-info-title{
  font-size: .28rem;
  margin-bottom: .3rem;
  color: @green;
}

//微信中进行alipay
.page-wx-alipay{
  background-color: @bg-gray;
  padding: .1rem 15px;
  .guide{
    margin-bottom: .5rem;
    img{
      height: 2rem;
    }
  }
  .text{
    box-shadow: 0 8px 46px rgba(0, 0, 0, 0.05);
    font-size: .26rem;
    color: @black;
    margin-bottom: .5rem;
    background-color: #fff;
    border-radius: 4px;
    padding: .2rem;
    line-height: 1.5;
  }
  .browser{
    img{
      height: 3rem;
    }
  }
}

.logo-wrapper{
  overflow: hidden;
  border-radius: 5px;
  img{
    overflow: hidden;
    border-radius: 5px;
  }
}

select{
  .text-overflow;
  option{
  }
}

.page{
  &.page-bottom{
    padding-bottom: 1.5rem;
  }
}

.no-data{
  color: @light-black;
  font-size: .24rem;
  text-align: center;
  padding-top: .3rem;
  padding-bottom: .3rem;
}

.wish-list{
  >li{
    a{
      border-radius: 3px;
      overflow: hidden;
      padding: .3rem 0;
      .img-info(2.5rem, 1.62rem, .3rem);
      .img{
        border-radius: 3px;
      }
    }
    .img{
      position: relative;
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .content-top{
        .title{
          margin-bottom: .2rem;
          font-size: .28rem;
          .text-overflow;
        }
        .desc{
          .text-overflow;
        }
      }
      .dream-success-seal{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.45rem;
        height: 1.1rem;
      }
    }
  }
}

.page-wish-index{
  .wish-org-search-form{
    padding-top: .2rem;
    padding-bottom: .2rem;
  }
}

.page-wish-index, .page-wish-org-detail{
  .wish-wrapper{
    .panel_title{
      margin-bottom: .25rem;
    }
    .wish-cert-desc{
      margin-bottom: .25rem;
      font-size: .24rem;
      color: @gray;
      line-height: 1.2;
    }
    .wish-list{
      li{
        &:first-child{
          a{
            padding-top: 0;
          }
        }
      }
    }
  }
}

.page-wish-org-detail{
  .wish-wrapper{
    .panel_title{
      margin-bottom: 0;
      border-bottom: 0;
    }
    .select-with-search-form{
      margin-bottom: .2rem;
    }
  }
  .wish-list{
    >li{
      &:last-child{
        .row.bar{
          display: none;
        }
      }
    }
  }
}

.partner-wrapper{
  .home-partner-swiper{
    margin-bottom: 0.35rem;
    padding-bottom: .3rem;
    .swiper-slide{
      .flex-center;
      height: 1.4rem;
      a{
        padding: .15rem;
        height: 1.1rem;
        .flex-center;
        img{
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .swiper-pagination{
      bottom: 0;
      width: 100%;
      text-align: center;
      .swiper-pagination-bullet{
        width: .24rem;
        height: .08rem;
        background-color: #dbdbdb;
        border-radius: 25px;
        opacity: 1;
        margin: 0 .1rem;
        &.swiper-pagination-bullet-active{
          background-color: @green;
        }
      }
    }
  }

}

.page-wish-detail{
  .meta{
    .cover{
      margin-top: .3rem;
      margin-bottom: .25rem;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      .wish-tag{
        position: absolute;
        right: .2rem;
        bottom: .2rem;
        top: unset;
        border-radius: 3px;
      }
      .dream-success-seal{
        position: absolute;
        right: .1rem;
        top: .2rem;
        width: 1.45rem;
        height: 1.1rem;
      }
    }
    .title{
      font-size: .28rem;
      color: @black;
      line-height: 1.5;
      font-weight: bold;
      margin-bottom: .25rem;
    }
    .wish-info{
      margin-top: .25rem;
      margin-bottom: .3rem;
      .left{
        order: 1;
        margin-left: .4rem;
      }
    }
  }

  .wish-info-wrapper{
    padding-bottom: .1rem;
    .fund_content{
      margin-bottom: .2rem;
      border-bottom: 1px solid @border;
      padding: 0;
      padding-top: .3rem;
      padding-bottom: .3rem;
    }
    .title-desc-list{
      >li{
        margin-top: 0;
        margin-bottom: .1rem;
        .title,.desc{
          color: @black;
        }
        a{
          color: @blue;
          border-bottom: 1px dashed @blue;
          padding-bottom: 2px;
        }
        .org-name{
          color: @blue;
          border-bottom: 1px dashed @blue;
        }
      }
    }
  }

  .wish-detail{
    .article-content{
      padding-top: .1rem;
    }
  }

}


.donate-list{
  >li{
    padding-top: .25rem;
    padding-bottom: .25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .meta{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: .2rem;
      .title{
        font-size: .28rem;
        color: @black;
      }
      .cost{
        color: @red;
        font-size: .26rem;
        flex-shrink: 0;
        margin-left: 5px;
      }
      .status{
        color: @blue;
        font-size: .26rem;
      }
    }
    .content{
      font-size: .24rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content-info{
        flex-shrink: 0;
        .text-overflow;
        .content-info-item{
          display: flex;
          align-items: center;
          line-height: 1.2;
          & + .content-info-item{
            margin-top: .1rem;
          }
          .info-item-title{
            color: @gray;
          }
          .info-item-desc{
            color: @light-black;
          }
        }
      }
      .btns{
        display: flex;
        justify-content: flex-end;
        margin-bottom: -.1rem;
        flex-wrap: wrap-reverse;
        a,button{
          height: .4rem;
          padding-left: .15rem;
          padding-right: .15rem;
          width: auto;
          & + a,& + button{
            margin-left: .2rem;
          }
        }
      }
    }
  }
}

.page-article-detail{
  .title{
    margin-top: .4rem;
    font-size: .32rem;
    color: @black;
    line-height: 1.5;
    padding-bottom: .25rem;
    border-bottom: 1px solid @border;
    margin-bottom: .25rem;
  }
  .article-meta{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .3rem;
    .time{
      font-size: .24rem;
      color: @gray;
    }
  }
}

.page-wish-donate-index{
  .title{
    font-size: .32rem;
    line-height: 1.5;
    margin-bottom: .1rem;
    margin-top: .3rem;
    color: @black;
    span{
      color: @light-black;
    }
  }
  .sub-title{
    font-size: .28rem;
    color: @black;
    line-height: 1.5;
    margin-bottom: .2rem;
  }
  .desc{
    font-size: .24rem;
    color: @gray;
    line-height: 1.2;
    margin-bottom: .3rem;
  }
}

.ossuploader-add, .ossuploader-dash-border{
  justify-content: center;
}

@media (max-width: 500px){
  .ossuploader-add,.ossuploader-dash-border{
    i{
      width: .4rem !important;
      height: .4rem !important;
    }
    p{
      font-size: .24rem !important;
      color: @light-black !important;
    }
  }
}

.wish-project-desc{
  font-size: .26rem;
  color: @black;
  line-height: 1.6;
  padding: .2rem 0;
}

.h-stats-list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: .2rem 0;
  >li{
    box-sizing: border-box;
    width: 50%;
    padding-left: .2rem;
    display: inline-flex;
    align-items: baseline;
    height: .5rem;
    line-height: .5rem;
    &:nth-child(even){
      border-left: 1px solid @border;
    }
    .title{
      font-size: .24rem;
      color: @light-black;
      margin-right: 5px;
    }
    .num{
      font-size: .32rem;
      color: @orange;
    }
    .unit{
      color: @light-black;
      font-size: .24rem;
    }
  }
}


.modal_layer{
  &.video-popup-box{
    padding: 0;
    .modal_box{
      padding: 0;
      display: flex;
      align-content: center;
      justify-content: center;
      position: relative;
      background-color: transparent;
      .icon-close{
        position: absolute;
        right: 0;
        top: -.8rem;
      }
      .modal_content{
        display: flex;
        align-content: center;
        justify-content: center;
        margin: 0;
        width: 100%;
        iframe{
          width: 100%;
        }
      }
    }
  }
}

.page-wish-closing-report{
  .title{
    font-size: .32rem;
    color: @green;
    padding: .25rem;
    line-height: 1.5;
    text-align: center;
    margin-bottom: .2rem;
    border-bottom: 1px solid @border-color;
  }
  .bottom{
    border-top: 1px solid @border-color;
    padding-top: .5rem;
    text-align: center;
    margin-top: .2rem;
    .btn{
      width: 2rem;
      height: .6rem;
      font-size: .26rem;
    }
  }
}

.middle_all {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .need_grow {
    flex-grow: 1;
  }
  .page-middle {
    .middle_container{
      padding: 0.4rem 0.3rem;
    }
    .bg-green {
      width: 100%;
      background-image: url("@{img_dir}bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right bottom;      
      position: relative;
      .main-titel {
        color: #fff;
        .top {
          display: flex;
          margin-bottom: 0.42rem;
          .left {
            flex-shrink: 0;
            width: 2.2rem;
            height: 1.5rem;
            overflow: hidden;
            border-radius: 0.06rem;
            border: solid 0.04rem #ffffff;
            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          > h3 {
            margin-left: 0.28rem;
            font-size: 0.32rem;
            font-weight: bold;
            line-height: 1.5;
          }
        }
        .bottom {
          font-size: 0.26rem;
          .list {
            .li {
              display: flex;
              align-items: baseline;
              > .title {
                flex-shrink: 0;
              }
              > .right {
                display: flex;
                align-items: center;
              }
              .logo {
                margin-right: 0.08rem;
                padding: 0.05rem;
                background-color: #fff;
                border-radius: 0.04rem;
                > img {
                  max-width: 0.28rem;
                  max-height: 0.28rem;
                  width: auto;
                }
              }
              & + .li {
                margin-top: 0.2rem;
              }
            }
          }
        }
      }
    }
    .main-content {
      .donate {
        .danate_msg {
          font-size: 0.28rem;
          text-align: center;
          margin-bottom: 0.33rem;
        }
        .donate_money {
          color: #96bd2a;
          display: flex;
          justify-content: center;
          align-items: baseline;
          .icon {
            font-size: 0.4rem;
            font-weight: bold;
            margin-right: 0.13rem;
          }
          .number {
            font-size: 0.68rem;
          }
        }
      }
      .agreement_read {
        margin-top: 1rem;
        align-items: baseline;
        font-size: 0.26rem;
        display: flex;
        align-items: baseline;
        .input_item {
          position: relative;
          cursor: pointer;
          margin-right: 0.11rem;
          input[type="checkbox"] {
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            width: 0.24rem;
            height: 0.24rem;
            z-index: 2;
          }
          input[type='checkbox'] + .text {
            z-index: 1;
            position: relative;
            width: 0.24rem;
            height: 0.24rem;
            box-sizing: border-box;
            border: 0.02rem solid #333;
            margin-right: 0.04rem;
            border-radius: 0.02rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              opacity: 0;
              max-width: 100%;
              max-height: 100%;
            }
          }
          input[type='checkbox']:checked + .text {
            border-color: #96bd2a;
            img {
              opacity: 1;
            }
          }
        }
        .text_two {
          line-height: 1.5;
          .text_admire {
            color: #96bd2a;
            text-decoration: underline;
          }
        }
      }
      .submit_btn {
        margin-top: 0.23rem;
        width: 100%;
        height: 0.71rem;
        line-height: 0.71rem;
        background-color: #68c258;
        border-radius: 0.02rem;
        text-align: center;
        color: #fff;
        font-size: 0.28rem;
      }
    }
  }
  .footer-middle {
    flex-shrink: 0;
    padding-bottom: 0.37rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-logo {
      > img {
        height: 0.62rem;
        width: auto;
      }
    }
    > p {
      margin-top: 0.25rem;
      font-size: 0.26rem;
      color: #666;
    }
  }
  .transparent_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    &.hidden {
      display: none;
    }
    .msg_box_black {
      position: fixed;
      z-index: 101;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3.32rem;
      background-color: #000000;
      border-radius: 0.1rem;
      opacity: 0.8;
      padding: 0.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      font-size: 0.28rem;
      line-height: 1.6;
      .text {
        text-align: center;
      }
      .loader {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
        display: flex;
        align-items: center;
        > div {
          background-color: #fff;
          border-radius: 50%;
          width: 0.13rem;
	        height: 0.13rem;
          animation: loader-middle 0.8s ease infinite;
          & + div {
            margin-left: 0.17rem;
          }
          &:nth-child(1) {
            animation-delay: 0;
          }
          &:nth-child(2) {
            animation-delay: 0.6s;
          }
          &:nth-child(3) {
            animation-delay: 1.2s;
          }
        }
      }
    }
  }
}


@keyframes loader-middle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
