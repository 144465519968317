@import "var";
//标点符号不在行首
.symbol-not-front{
  word-break: normal;
  text-align: justify;
  word-wrap:break-word;
}
a.btn,button.btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.2rem;
  height: .5rem;
  line-height: .5rem;
  background-color: @green;
  color: #fff;
  font-size: .26rem;
  border: none;
  border-radius: .5rem;
  box-sizing: border-box;
  &.btn-green{
    background:@green;
    color:#fff;
    &.btn-border{
      border: 1px solid @green;
      background-color: #fff;
      color: #69c258;
      &:hover{
        background: @green;
        color: #fff;
      }
    }
    &.btn-shadow{
      box-shadow: 0 3px 3px 0 rgba(53, 142, 36, 0.3);
    }
  }
  &.btn-yellow,&.btn-orange{
    background: @orange;
    color:#fff;
    &.btn-border{
      border: 1px solid @orange;
      background-color: #fff;
      color: @orange;
      &:hover{
        background: @orange;
        color: #fff;
      }
    }
  }
  &.btn-disabled{
    background-color: @gray;
    cursor: no-drop;
    pointer-events: none;

  }
    //大小
  &.btn-search{
    width: 1.2rem;
    height: .5rem;
    line-height: .5rem;
  }
  &.btn-form{
    width: 2.8rem;
    height: .6rem;
    line-height: .6rem;
  }
  &.btn-big{
    width: 100%;
    height: .82rem;
    line-height: .82rem;
  }
  &.btn-list-bottom{
    width: 33%;
    height: .6rem;
    line-height: .6rem;
  }
  &.btn-block{
    display: flex;
    width: 100%;
  }
  &.btn-h-70{
    height: .7rem;
  }
  &.btn-radius{
    border-radius: 25px;
  }
  &.btn-square{
    border-radius: 0;
  }
  &.btn-square-radius{
    border-radius: 4px;
  }
  &:before,
  &:after {
    content: '';
    flex: 1 0 auto;
  }
  &[disabled]{
    opacity: .3;
    cursor: default;
    pointer-events: none;
  }
}

.btn {
  height: .5rem;
  line-height: .5rem;
  text-align: center;
  border-radius: .5rem;
  font-size: .28rem;
  border: 1px solid transparent;
  box-sizing: border-box;
  white-space: nowrap;
}
.btn-org{
  display: inline-block;
  height: .5rem;
  line-height: .5rem;
  text-align: center;
  border-radius: .5rem;
  font-size: .28rem;
  border: 1px solid transparent;
  box-sizing: border-box;
  white-space: nowrap;
  width: auto;
  padding: 0 0.2rem;
}

.btn-small {
  height: .4rem;
  line-height: .4rem;
  font-size: .24rem;
  border-radius: .4rem;
}

.btn-primary {
  color: #fff;
  background-color: #68c258;
}

.btn-second {
  color: #fff;
  background-color: #fd7400 !important;
}

.btn-grey {
  color: #333;
  background-color: #e6e6e6;
}


.btn-frame-primary {
  background-color: transparent;
  color: #68c258;
  border-color: #68c258;
}

.btn-frame-second {
  background-color: transparent;
  color: #fd7400;
  border-color: #fd7400;
}

.btn-big {
  width: 100%;
  height: .6rem;
  line-height: .6rem;
}

.text-limit(@lineCount){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @lineCount;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.text-limit2{
  .text-limit(2);
}
.text-limit3{
  .text-limit(3);
}
.text-limit4{
  .text-limit(4);
}
.text-limit5{
  .text-limit(5);
}

.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

//图片超出裁剪
.img-wrap(@width,@height: auto, @radius: 0) {
  width: @width;
  height: @height;
  overflow: hidden;
  text-align: center;
  line-height: @height;
  border-radius: @radius;
  img {
    border-radius: @radius;
  }
}

//背景图
.bg(@imgUrl,@imgType: jpg,@bgPos: center center,@bgSize: cover) {
  background: url('@{img_dir}@{imgUrl}.@{imgType}') no-repeat @bgPos;
  background-size: @bgSize;
}



//详细页
.detail-row{
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .28rem;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  .left-desc{
    color:#999999;
    flex-shrink: 0;
    line-height: 1.3;
  }
  .right-content{
    color:#333;
    line-height: 1.3;
    &.price{
      color: #fd7400;
    }
  }
  &.title{
    color:  @green;
    font-size: .3rem;
    font-weight: bold;
  }

}

//文字一行  溢出隐藏
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-overflow(){
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
//水平垂直居中。兼容ios
.position-center(){
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
//图片超出裁剪
.img-wrap(@width,@height: auto, @radius: 0) {
  width: @width;
  height: @height;
  overflow: hidden;
  text-align: center;
  line-height: @height;
  border-radius: @radius;
  img {
    border-radius: @radius;
  }
}

//设置圆形图片
.avatar(@size) {
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  width: @size;
  height: @size;
  line-height: @size;
  img {
    border-radius: 50%;
  }
}


.input-default{
  background-color: #e6e6e6;
  height: .5rem;
  line-height: .5rem;
  border: 0;
  border-radius: 4px;
  color: @light-black;;
  box-sizing: border-box;
  padding-left: .2rem;
  padding-right: .2rem;
}

//图片信息info
.img-info(@width: 1.2rem,@height: .8rem,@margin-left: .2rem){
  display: flex;
  .img{
    .img-wrap(@width,@height);
    img{
      border-radius: 4px;
    }
    flex-shrink: 0;
  }
  .content{
    flex-grow: 1;
    margin-left: @margin-left;
    min-height: @height;
    height: @height;
    position: relative;
    max-width: calc(100% - @margin-left - @width);
    .title{
      color: @black;
      font-size: .26rem;
      line-height: 1.5;
    }
    .desc{
      color: @gray;
      line-height: 1.5;
      font-size: .24rem;
    }
  }
}

//按钮组
.btn-group{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .btn{
    max-width: 2.05rem;
    flex-grow: 1;
    height: .5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    & + .btn{
      margin-left: .6rem;
    }
    &.btn-block{
      height: .8rem;
      line-height: .8rem;
      width: 100%;
      max-width: 100%;
    }
  }
}


//描述栏
.desc-item(@width){
  display: flex;
  position: relative;
  font-size: .24rem;
  color: @black;
  line-height: 1.5;
  margin-bottom: .3rem;
  .title,.icon-wrapper{
    flex-shrink: 0;
    width: @width;
  }
  .icon-wrapper{
    i{

    }
  }
  .desc{
  }
}

.icon-desc-item{
  .desc-item(.4rem);
}

//标题->描述
.title-desc-list {
  li {
    .title {
      color: @black;
    }
    .desc {
      color: @gray;
    }
    & + li {
      margin-top: .3rem;
    }
    span {
    }
  }
  &.inline{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      margin-top: 0;
      margin-bottom: .3rem;
      width: 50%;
      flex-shrink: 0;
      flex-grow: 1;
      display: inline-flex;
      line-height: 1.5;
      .title {
        flex-shrink: 0;
      }
    }
  }
  &.list-stat{
    font-size: 14px;
    overflow: hidden;
    li{
      .desc{
        color: @red;
      }
      &:first-child{
        float: left;
      }
      &:last-child{
        float: right;
      }
      & + li{
        margin-top: 0;
      }
    }
  }
}

.bg-white{
  background-color: #fff !important;
}

.bg-gray{
  background-color: @bg-gray !important;
}

&.no-radius{
  border-radius: 0 !important;
}

.page{
  &.page-gray{
    .bg-gray;
    .row;
    padding-left: 15px;
    padding-right: 15px;
    >.container{
      padding-left: .3rem;
      padding-right: .3rem;
    }
  }
}

//下划线
.border-bottom{
  border-bottom: 1px solid @border !important;
}
.border-bottom-thick{
  border-bottom: .1rem solid @border !important;
}
.border-top{
  border-top: 1px solid @border !important;
}
.not-border{
  border: none !important;
}
.not-b-border{
  border-bottom: none !important;
}
.not-t-border{
  border-top: none !important;
}
.not-l-border{
  border-left: none !important;
}
.not-r-border{
  border-right: none !important;
}
.not-padding{
  padding: 0 !important;
}
.not-margin{
  margin: 0 !important;
}
.not-l-padding{
  padding-left: 0 !important;
}
.not-r-padding{
  padding-right: 0 !important;
}
.not-t-padding{
  padding-top: 0 !important;
}
.not-b-padding{
  padding-bottom: 0 !important;
}
.pb0{
  padding-bottom: 0 !important;
}
.pt0{
  padding-top: 0 !important;
}
.pb10{
  padding-bottom: .1rem !important;
}
.pb20{
  padding-bottom: .2rem !important;
}
.pl20{
  padding-left: .2rem !important;
}
.pt30{
  padding-top: .3rem !important;
}
.pb40{
  padding-bottom: .4rem !important;
}
.mr5{
  margin-right: .05rem !important;
}
.mt30{
  margin-top: .3rem !important;
}
.mt50{
  margin-top: .5rem !important;
}
.mt0{
  margin-top: 0 !important;
}
.mb0{
  margin-bottom: 0 !important;
}
.mb20{
  margin-bottom: .2rem !important;
}
.mb30{
  margin-bottom: .3rem !important;
}
.mb40{
  margin-bottom: .4rem !important;
}
.mb50{
  margin-bottom: .5rem !important;
}
.mb80{
  margin-bottom: .8rem !important;
}
.ml20{
  margin-left: .2rem !important;
}
//链接颜色
.to-link{
  color:#fd7400 !important;
}
//字体颜色
.text-yellow{
  color:#fd7400 !important;
}
.text-orange{
  color: @orange !important;
}
//显眼的颜色
.showy-cost-red{
  color:#e24c37 !important;
  font-size: .28rem;
  font-weight: bold;
}

//背景图
.bg(@imgUrl,@imgType: jpg,@bgPos: center center,@bgSize: cover) {
  background: url('@{img_dir}@{imgUrl}.@{imgType}') no-repeat @bgPos;
  background-size: @bgSize;
}
.has-bottom-decoration{
  padding-bottom: .04rem;
  display: inline-block;
  border-bottom: 1px dotted @orange;
}

.all-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.mb10{
  margin-bottom: .1rem !important;
}
.text-cut-2 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}