@import "var";
@import "clearDefault";
@import "tool";
@import "common";
@import "icon";
@import "wj";
@import "jb";
@import "zj";
@import "xh";
@import "xiaohong";
@import "yl";

//隐藏内容
.hidden-content{
  position: relative;
  max-height: 500px;
  overflow: hidden;
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 70px;
    background: linear-gradient(rgba(255,255,255,0),rgba(255,255,255,.7), rgba(255,255,255,1));
    bottom: 0;
    left: 0;
  }
  .view-more{
    position: absolute;
    z-index: 1;
    width: 100%;
    text-align: center;
    bottom: 20px;
    left: 0;
    font-size: .24rem;
    color: #69c258;
  }
}
//通用轮播图
.slide_show{
  box-shadow: 0px 5px 10px 0px rgba(225,235,222,1);
  .swiper-slide {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.height {
      height: 3.8rem;
    }
  }
  .swiper_desc {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: .2rem .3rem .5rem;
    color: #ffffff;
    font-size: .28rem;
    background: rgba(195, 40, 40, 0.48);
    line-height: 1.2;
  }
  .play-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.14rem;
    height: 1.14rem;
    transform: translate(-50%, -50%);
  }
  .swiper-pagination-bullet{
    background: rgba(255,255,255,.7)!important;
    width: .2rem;
    height: .08rem;
    opacity: 1!important;
    border-radius: .08rem;
    bottom: .2rem!important;
    transition: all .3s;
  }
  .swiper-pagination-bullet-active {
    background: #68c258 !important;
    width: .3rem;
    transition: all .3s;
  }
}

//通用横向滑动列表
.swiper_box {
  padding-top: .3rem;
  padding-bottom: .3rem;
  .swiper-wrapper .swiper-slide {
    width: 2.8rem;
  }
  .swiper_item {
    width: 100%;
    display: block;
    border: 1px solid #f5f5f5;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
    img {
      display: block;
      width: 100%;
      border-radius: 3px 3px 0 0;
    }
    .content{
      padding: .15rem 5px;
    }
    .title {
      font-size: .26rem;
      color: #333333;
      line-height: 1.2;
      margin-bottom: .1rem;
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

    }
    .desc {
      font-size: .24rem;
      color: #999999;
    }
  }
}

//慈善项目列表
.project-list{
  .project_item {
    padding-top: .2rem;
    padding-bottom: .2rem;
    display: table;
    width: 100%;
    .project_title {
      font-size: .28rem;
      font-weight: normal;
      padding-top: .2rem;
      padding-bottom: .2rem;
      line-height: .4rem;
      display: table-caption;
      margin-bottom: -.2rem;
    }
    .project_item_img {
      width: 2rem;
      vertical-align: top;
      border-radius: 5px;
      overflow: hidden;
      .project_item_image {
        height: 1.35rem;
        img{
          border-radius: 5px;
        }
      }
    }
    .project_item_content {
      padding-left: .3rem;
      box-sizing: border-box;
      width: 100%;
      display: inline-block;
      color: #7a7a7a;
      vertical-align: 0;
      .project_desc {
        font-size: .24rem;
        margin-bottom: .1rem;
      }
      .project_status {
        font-size: .24rem;
        margin-top: .1rem;
        span{
          color: @orange;
        }
      }
    }
  }
}

//个人求助列表
.help-list{
  .help_item{
    .info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .2rem 0;
      border-bottom: 1px solid #f5f5f5;
      img{
        border-radius: 50%;
        width: .6rem;
        height: .6rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 3px;
      }
      .user-name{
        font-size: .28rem;
        color: #333;
      }
    }
    .content{
      padding: .2rem 0;
      //border-bottom: 1px solid #f5f5f5;
      h4{
        color: #333;
        font-size: .28rem;
        margin-bottom: .2rem;
      }
      p{
        color: #666666;
        font-size: .24rem;
        margin-bottom: .2rem;
      }
      .process{
        padding-top: .2rem;
      }
    }
    .stat{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .24rem;
      padding: .3rem 0;
      .title-desc-border{
        .title{
          border-right: 1px solid @border;
        }
      }
      .title{
        padding-right: 5px;
        border-right: 1px solid #dbdbdb;
        margin-right: 5px;
      }
      .support-count{
        color: #999999;
      }
    }
  }
}

//图片列表，一行三个
.img-list{
  display: flex;
  flex-wrap: wrap;
  .img-wrap{
    width: 32%;
    margin-right: 2%;
    margin-bottom: 2%;
    height: 1.36rem;
    &:nth-child(3n){
      margin-right: 0;
    }
    img{
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
}

//列表搜索，包括侧滑筛选
.list_search{
  background-color: #68c258;
  padding: 0 15px;
  height: .8rem;
  line-height: .8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search_wrapper{
    width: 4rem;
    height: .5rem;
    border-radius: .5rem;
    box-sizing: border-box;
    padding: 0 .2rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    img{
      width: .24rem;
      height: .24rem;
      padding-right: .1rem;
      margin-right: .1rem;
      border-right: 1px solid #f5f5f5;
      flex-shrink: 0;
    }
    input{
      border: none;
      background-color: #fff;
      color: #999999;
      font-size: .24rem;
      width: 100%;
    }
  }
  &.not-background{
    background-color: #fff;
    height: auto;
    padding-top: .3rem;
    .search_wrapper{
      background-color: #e6e6e6;
      img{
        border-right-color: @gray;
      }
      > input{
        color: @gray;
        background-color: #e6e6e6;
      }
    }
  }
}

//筛选
.filter-list{
  .item{
    display: flex;
    flex-wrap: wrap;
    a{
      min-width: 1.36rem;
      height: .44rem;
      line-height: .44rem;
      font-size: .24rem;
      margin: 0 .2rem .2rem 0;
      padding: 0 .1rem;
      box-sizing: border-box;
      border-radius: .44rem;
      color: #666;
      background-color: #e6e6e6;
      text-align: center;
      display: inline-block;
      .text-overflow;
      &.active{
        color: #fff;
        background-color: #69c258;
      }
    }
    &.type{
      a{
        //width: auto;
      }
    }
  }
  .title{
    font-size: .26rem;
    color: #666;
    margin: .5rem 0 .2rem;
  }
}

.operation{
  display: flex;
  justify-content: space-around;
  .btn{
    flex-grow: 1;
    margin-left: .3rem;
    height: .82rem;
    line-height: .82rem;
    &:first-child{
      margin-left: 0;
    }
  }
}

//公益数据，可适应2-3个数据
.stats_list {
  padding-bottom: .3rem;
  padding-top: .3rem;
  display: flex;
  justify-content: space-between;
  .stats_list_item {
    text-align: center;
    border-right: 1px solid #e2e5e1;
    &:last-child{
      border-right: none;
    }
    h4 {
      color: #666666;
      font-size: .24rem;
      font-weight: normal;
      margin-top: .1rem;
      white-space: nowrap;
    }
    p {
      font-size: .34rem;
      font-weight: bold;
      color: @orange;
      white-space: nowrap;
    }
  }
  &.stats_list_2{
    .stats_list_item {
      flex: 1;
    }
  }
  &.stats_list_3{
    .stats_list_item {
      padding: 0 .15rem;
      flex-shrink: 1;
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
    }
  }
  &.stats_list_4{
    .stats_list_item {
      padding: 0 .1rem;
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
    }
  }
  &.has-g-background{
    background-color: @green;
    border-radius: 4px;
    padding-left: .2rem;
    padding-right: .2rem;
    .stats_list_item {
      border: none;
      p{
        color: #fff;
      }
      h4{
        color: rgba(255,255,255,.6);
      }
    }
  }
}

//首页功能图标导航，一行最多四个，然后换行
.home_nav {
  display: flex;
  flex-wrap: wrap;
  .nav-item {
    padding: .3rem 0;
    width: 25%;
    text-align: center;
    a {
      color: #666;
    }
    h4 {
      font-size: .24rem;
      margin-top: .2rem;
    }
    img {
      width: .8rem;
      display: block;
      margin: 0 auto;
    }
  }
}

//详情页
.project-detail{
  .cover{
    margin-top: .3rem;
    img{
      display: block;
      width: 100%;
      height: auto;
      border-radius: 3px;
    }
  }
  .title{
    color: #333333;
    font-size: .32rem;
    font-weight: normal;
    margin: .3rem 0;
    line-height: 1.5;
  }
  .process{
    margin-bottom: .3rem;
  }
  .excu_org{
    height: 1.66rem;
    border-bottom: 1px solid #f1f1f1;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    .dis_table_cell{
      vertical-align: middle;
      position: relative;
    }
    .org_img{
      flex: 0 0 1.2rem;
      .img-wrap(1.2rem,1.2rem);
      overflow: hidden;
      border-radius: 5px;
    }
    .org_logo{
      width: 1.2rem;
      overflow: hidden;
      border-radius: 5px;
    }
    .org_desc{
      padding-left: .4rem;
      flex-grow: 1;
      h4{
        color: #333;
        font-size: .28rem;
        margin-bottom: .25rem;
      }
      span{
        color: #999999;
        font-size: .24rem;
      }
    }
    .get_more{
      width: .26rem;
      height: .5rem;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -.13rem;
    }
  }
  .fund_org{
    padding-top: .2rem;
    padding-bottom: .2rem;
    .title{
      margin-bottom: .2rem;
      color: #333;
      font-size: .28rem;
      .org_name{
        padding-bottom: .05rem;
        border-bottom: 1px dashed #B2B2B2;
        display: inline-block;
        margin-left: .1rem;
        color: #333;
      }
      .popupLink{
        float: right;
        border-radius: 100px;
        border: 1px solid #B4B4B4;
        padding: 2px;
        width: .25rem;
        height: .25rem;
        text-align: center;
        line-height: .28rem;
        color: #999;
      }
    }
    .desc{
      font-size: .24rem;
      color: #999;
      line-height: 1.2;
      margin-bottom: .2rem;

    }
  }
  .project_desc{
    padding-top: .3rem;
    margin-bottom: .3rem;
  }
  .project_process{
    .project_process_item{
      border-bottom: 1px solid #f1f1f1;
      padding: .3rem 0;
      .process_title{
        font-size: .26rem;
        margin-bottom: .2rem;
      }
      .process_time{
        color: #999;
        font-size: .24rem;
        margin-bottom: .1rem;
      }
      .process_desc{
        font-size: .24rem;
        margin-bottom: .2rem;
        color: #666;
      }
      .view-more{
        text-align: right;
        position: relative;
        a{
          font-size: .24rem;
          color: #68c258;
          position: relative;
          z-index: 2;
          display: inline-block;
          background-color: #fff;
          padding-left: 10px;
        }
        &:before{
          content: '';
          border-top: 1px dashed #f5f5f5;
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
        }
      }
    }
  }
}

.donate_record{
  .record_item{
    padding: .3rem 0;
    border-bottom: 1px solid #f1f1f1;
    .record_item_content{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    img{
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: .3rem;
    }
    .record_desc{
      flex-grow: 1;
      .create_time{
        font-size: .24rem;
        color: #999999;
      }
    }
    .user_name{
      font-size: .26rem;
      color: #333;
      margin-bottom: .1rem;
    }
    .donate_count{
      flex-shrink: 0;
      white-space: nowrap;
      font-size: .26rem;
      color: @light-black;
      span{
        color: @orange;
      }
    }
    .remark{
      font-size: .24rem;
      margin-top: .15rem;
      color: #666666;
    }
  }
}

//全屏列表
.row-list{
  border-bottom: 1px solid #f1f1f1;
  .row;
  >li{
    padding: .3rem;
    & + li{
      border-top: .1rem solid @bg-bar;
    }
  }
}

.bar-list{
  .row-list;
  border-bottom-width: .1rem;
}

//一起捐列表
.codonate-list{
  >li {
    border-bottom: none;
    a{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img{
        width: 1.1rem;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: .3rem;
      }
      .content{
        flex: 1;
        font-size: .24rem;
        color: @gray;
        >.title{
          line-height: 1.5;
          margin-bottom: .2rem;
          font-size: .26rem;
          color: #333333;
        }
        .project-title{
          font-size: .24rem;
          margin-bottom: .18rem;
        }
        .donate-summary-list{
          display: flex;
          >li{
            width: 50%;
            flex-shrink: 0;
            .title{

            }
          }
        }
      }
    }
  }
}

//捐赠列表
.donation-list{
  .item-header{
    display: flex;
    justify-content: space-between;
    font-size: .26rem;
    margin-bottom: .2rem;
    .title{
      flex-grow: 1;
      line-height: 1.5;
      margin-right: .3rem;
      color: #333333;
    }
    .count{
      line-height: 1.5;
      flex-shrink: 0;
    }
  }
  .content{
    font-size: .24rem;
    color: #a6a6a6;
  }
}

.user_donate{
  background: #fff;
  padding: .2rem 15px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 3;
  box-shadow: 0 0 20px 1px #eee;
  display: flex;
  justify-content: space-between;
  .donate_btn{
    border-radius: .6rem;
    line-height: .6rem;
    height: .6rem;
    font-size: .28rem;
    flex-grow: 1;
  }
}

//遮罩提示
.claim_mask{
  background: rgba(73,73,73,.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity .5s;
  .claim_box{
    background: #ffffff;
    margin-left: 15px;
    margin-right: 15px;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 50%;
    border-radius: 3px;
    padding: .3rem;
    transition: transform .5s;
    transform: translateY(-1.2rem);
    .mask_title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      h4{
        font-size: .3rem;
        padding-bottom: .15rem;
        border-bottom: 3px solid #68c258;
        margin-bottom: -2px;
      }
    }
    .mask_content{
      padding-top: .3rem;
    }
    .claim_succes_img{
      width: 1.25rem;height: 1.25rem;margin-top: .5rem;margin-bottom: .55rem;
      float: right;
      margin-left: -1.25rem;
    }
    .mask_close_img{
      position: absolute;right: .3rem;top: .3rem;
      width: .3rem;
      height: .3rem;
    }
    .claim_status_desc{
      font-size: .2rem;font-weight: 400;margin-bottom: .5rem;
      line-height: 1.2;
      text-align: left;
    }
    .claim_project_desc{
      font-size: .24rem;margin-bottom: .2rem;
    }
    .claim_org_desc{
      font-size: .24rem;margin-bottom: .6rem;
    }
    .btn_donate{
      background-color: @orange;
      color: #ffffff;
      border: 0;
      line-height: .9rem;
      font-weight: normal;
      font-size: .3rem;
    }
  }
}

//文章详情
.news_detail{
  margin-top: .3rem;
  margin-bottom: .3rem;
  .news_title{
    font-size: .28rem;
    margin-bottom: .1rem;
    padding-top: .3rem;
    text-align: center;
  }
  .news_time{
    font-size: .24rem;
    color: #999999;
    margin-bottom: .4rem;
    text-align: center;
  }
  .back-project{
    .back-project-link{
      font-size: 0.28rem;
      display: flex;
      align-items: center;
      color: #7e7e7e;
      width: 2rem;
      .icon-right{
        transform: rotateY(180deg);
        width: 0.2rem;
        height: 0.2rem;
      }
    }
  }
}

//求助首页
.sz-home{
  .help-btn{
    padding: .25rem 0;
    a{
      height: .7rem;
      line-height: .7rem;
      width: 100%;
    }
  }
  .panel_title{
    .tip{
      color: @orange;
      font-size: 12px;
    }
  }
}

//求助详情
.sz-project-detail{
  .fund_org{
    padding-top: 0;
  }
  .proof{
    font-size: .24rem;
    padding: .3rem 0;
    border-bottom: 1px solid #f5f5f5;
    position: relative;
    p{
      line-height: 1.8;
      //&:after{
      //    content: '';
      //    width: .24rem;
      //    height: .24rem;
      //    vertical-align: middle;
      //    display: inline-block;
      //    margin-left: 8px;
      //    background: url('../../../../../img/verified.png') no-repeat center;
      //    background-size: contain;
      //}
    }
    .name{
      color: #999999;
    }
    .val{
      color: #333;
    }
    .add-cert{
      position: absolute;
      top: .3rem;
      right: 0;
    }
  }
  .img-list{
    padding: .3rem 0;
  }
  .commitment{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .2rem 0;
    border-bottom: 1px solid #f5f5f5;
    h3{
      font-size: .26rem;
      color: #68c258;
    }
    a{
      color: #666666;
      font-size: .24rem;
    }
  }
  .add-cert{
    color: #69c258;
    font-size: .24rem;
    border-radius: .3rem;
    line-height: .3rem;
    height: .3rem;
    padding: 0 10px;
    border: 1px solid #69c258;
    box-sizing: border-box;
  }
  .confirm-list-wrap .empty-content{
    margin: .2rem 0;
  }
  .confirm-list{
    margin: .3rem 0 .2rem;
    display: flex;
    .confirm-item{
      border-radius: 50%;
      width: .58rem;
      height: .58rem;
      line-height: .58rem;
      text-align: center;
      margin-right: .12rem;
      overflow: hidden;
      font-size: 0;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .comment-list{
    background-color: #f5f5f5;
    position: relative;
    margin-bottom: .3rem;
    .comment-item{
      padding: .2rem;
      .anchor{
        display: block;
        margin-bottom: .1rem;
        color: #333333;
        font-size: .26rem;
      }
      .desc{
        color: #666666;
        line-height: 1.5;
        font-size: .24rem;
      }

      &.current-mask{
        position: absolute;
        padding: 0;
        transition: left .3s;
        left: .1rem;
        top: -11px;
        transition-timing-function: cubic-bezier(0.36, 0.88, 0.43, 1.14);
      }
      &.triangle {
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid #f5f5f5;
      }
    }
  }
  .help-process-wrap{
    .help-tab{
      border-bottom: 1px solid #f5f5f5;
      .tab_item{
        display: inline-block;
        margin-right: .5rem;
        padding: .3rem 0 .2rem;
        color: #666666;
        font-size: .28rem;
        &.active{
          border-bottom: 3px solid #68c258;
          margin-bottom: -2px;
          color: #68c258;
        }
      }
    }
    .help-list{
      .help-item{
        .item{
          padding-top: .3rem;
          padding-bottom: .3rem;
          border-bottom: 1px solid #f5f5f5;
          .info{
            display: flex;
            align-items: center;
            padding-bottom: .2rem;
            img{
              width: .58rem;
              height: .58rem;
              border-radius: 50%;
              margin-right: .2rem;
              flex-shrink: 0;
            }
            .info-detail{
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .name{
              font-size: .28rem;
              color: #333333;
            }
            .date{
              font-size: .24rem;
              color: #999999;
            }
          }
          .content{
            p{
              color: #999;
              font-size: .26rem;
            }
            .img-list{
              padding-bottom: 0;
              padding-top: .2rem;
            }
          }
        }
      }
    }
  }
}

.sz-cert-list{
  .add-cert{
    color: #69c258;
    font-size: .24rem;
    border-radius: .3rem;
    line-height: .3rem;
    height: .3rem;
    padding: 0 10px;
    border: 1px solid #69c258;
  }
  .cert-list{
    .item{
      display: flex;
      padding: .3rem 0;
      img{
        width: .78rem;
        height: .78rem;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: .25rem;
      }
      .content{
        .name{
          color: #333;
          font-size: .26rem;
        }
        .desc{
          color: #666;
          font-size: .24rem;
        }
      }
    }
  }
}

.form{
  .form-group{
    margin-bottom: .4rem;
    font-size: .26rem;
    position: relative;
    .line-default{
      > label{
        > .desc{
          margin-left: .1rem;
        }
        input[type=radio]{
          vertical-align: top;
        }
        & + label{
          margin-left: .4rem;
        }
      }
    }
    .field-name{
      font-size: 0.26rem;
      font-weight: 500;
      margin-bottom: 0.2rem;
      display: inline-block;
      color: @black;
    }
    &.last-child{
      margin-bottom: 0;
    }
    .desc{
      margin-bottom: .2rem;
      line-height: 1.2;
      color: #333;
    }
    .input-unit{
      position: relative;
      .input-default{
        padding-right: 1rem;
      }
      .unit{
        position: absolute;
        top: 0;
        right: 10px;
        color: #333;
        height: .75rem;
        line-height: .75rem;
      }
    }
    .input-default{
      padding-left: .2rem;
      padding-right: .2rem;
      border: none;
      background-color: #f5f5f5;
      box-sizing: border-box;
      width: 100%;
      height: .75rem;
      line-height: .75rem;
      &:disabled{
        opacity: .8;
      }
    }
    textarea.input-default{
      padding: .2rem;
      height: 2.3rem;
      line-height: 1.5;
    }
    input[type=checkbox]{
      display: inline-block;
      vertical-align: middle;
      width: .28rem;
      height: .28rem;
      & + .desc{
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }

    .arrd-select-wrap{
      margin-bottom: .1rem;
      select{
        .input-default;
        width: calc(~"(100% - .1rem) / 3");
        & + select{
          margin-left: .05rem;
        }
        &.select-block{
          width: 100%;
        }
      }
    }
    .upload-img-wrap{
      width: 2.05rem;
      height: 1.55rem;
      background-color: #EEEEEE;
      text-align: center;
      cursor: pointer;
      img{
        margin-bottom: .2rem;
      }
      .desc{
        margin-bottom: 0;
        color: #333;
      }
    }
    &.exam_code_box{
      width: 100%;
      input{
        width: calc(~"100% - 2.2rem");
      }
      .exam_code{
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        width: 2rem;
        height: .75rem;
        img{
          height: 100%;
          width: auto;
          left: 0;
        }
      }
    }
    &.check_code{
      width: 100%;
      input{
        width: calc(~"100% - 2.2rem");
      }
      button{
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        width: 2rem;
        height: .75rem;
        line-height: .75rem;
        border-radius: 5px;
      }
    }

  }
  .show-line{
    padding-bottom: .36rem;
    font-size: .28rem;
    > .field-name{
      color: @black;
    }
  }
  .sub-title{
    font-size: .24rem;
    color: @gray !important;
    margin-left: 3px;
  }
  .tips-text{
    padding-left: .35rem;
    background: url('../../../../img/notice.png') no-repeat top left 3px;
    background-size: .24rem .24rem;
    color: #999;
    font-size: .24rem;
    margin-top: .15rem;
    line-height: 1.2;
  }
  .refer-tips{
    margin-left: .2rem;
    color: #666;
    font-size: .24rem;
    line-height: 1.2;
    border-bottom: 1px dashed #666;
  }
  .required-text{
    color: @orange;
    font-size: .26rem;
    margin-right: .1rem;
  }
  .text_admire {
    border-bottom: 1px dotted @orange;
    padding-bottom: .1rem;
    font-size: .26rem;
    color: @orange;
  }
  .operation{
    margin-bottom: .4rem;
  }
}
.message-page{
  position: fixed;
  top: 50%;
  left: .85rem;
  right: .85rem;
  transform: translateY(-50%);
  h1{
    color: #68c258;
    font-size: .38rem;
    margin-bottom: .6rem;
    text-align: center;
  }
  .operation{
    margin-top: 1rem;
  }
  .article-content{
    text-align: center;
    p{
      color: #999999;
    }
  }
}
.claim-codonate{
  .codonate-target{
    font-size: .28rem;
    padding: .3rem 0;
    margin-bottom: .3rem;
    border-bottom: 1px solid #f5f5f5;
    line-height: 1.5;
    span{
      color: #666;
      vertical-align: unset;
    }
    .codonate-target-title{
      color: #333;
      font-weight: bold;
      font-size: .3rem;
    }
  }
}
.codonate{
  padding-bottom: 1rem;
  .info{
    padding: .4rem 0 0;
    position: relative;
    .head_img{
      width: 1rem;
      height: 1rem;
      display: block;
      margin: 0 auto;
      border-radius: 50%;
    }
    h4.donate_title{
      font-size: .26rem;
      color: #333;
      margin-top: .3rem;
      text-align: center;
    }
    .donate_desc{
      font-size: .28rem;
      color: #333;
      margin-bottom: .2rem;
      text-align: center;
    }
    .reason{
      font-size: .24rem;
      color: #666;
    }
    .edit{
      position: absolute;
      right: 0;
      top: 15px;
      font-size: .26rem;
      color: #fd7400;
    }
  }
}
.user-center{
  .user_info{
    background-color: #a8e292;
    padding: .5rem 0 .4rem;
    .headimg{
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      display: block;
      margin: 0 auto;
    }
    .user_name{
      margin-top: .3rem;
      text-align: center;
      color: #fff;
      font-size: .28rem;
      a{
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }
  .user_nav{
    .nav_item{
      height: .84rem;
      border-bottom: 1px solid #f5f5f5;
      line-height: .84rem;
      font-size: .26rem;
      a{
        color: #333333;
        vertical-align: top;
        display: flex;
        align-items: center;
      }
      .nav_item_img{
        width: .25rem;
        margin-right: .3rem;
      }
    }
  }
}

.donate_form{
  padding-top: 15px;
  padding-bottom: 15px;

  .hidden-content{
    position: relative;
    height: 1.2rem;
    overflow: hidden;

    &:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 1.2rem;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 1)), color-stop(rgba(255, 255, 255, 1)), to(#ffffff));
      background: -webkit-linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), #ffffff);
      background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), #ffffff);
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    .view-more{
      position: absolute;
      z-index: 1;
      width: 1.6rem;
      height: 0.6rem;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -100%);
      font-size: 0.24rem;
      color: #fff;
      padding: 0;
    }
  }

  .title{
    font-size: .26rem;
    margin-bottom: .3rem;
    line-height: 1.5;
    color: #333;
    span{
      color: #666;
    }
  }
  .selected{
    position: absolute;
    right: 0;
    bottom: 0;
    width: .3rem;
    height: .3rem;
  }

  label{
    font-size: .26rem;
    font-weight: 500 !important;
    margin-bottom: .2rem;
    display: inline-block;
  }

  input[type=checkbox]{
    width: .26rem;
    height: .26rem;
    vertical-align: -2px;
  }

  label,span{
    padding: 0;
  }
  /*捐赠金额*/
  .donate_count_box{
    padding: .3rem 0;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    .donate_count_title{
      font-size: .26rem;
      font-weight: 500;
      margin-bottom: .2rem;
    }
    .donate_input_box{
      overflow: hidden;
      width: 100%;
      .donate_count_item{
        position: relative;
        width: calc(~'25% - 10px');
        font-size: .26rem;
        line-height: .8rem;
        border-radius: 4px;
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid #f3f3f3;
        height: .8rem;
        &:first-child{
          margin-left: 0;
        }
        &:last-child{
          margin-right: 0;
        }
      }
      .donate_count_item_active{
        border-color: #74c753;
      }
    }
    .other_num{
      padding-top: 15px;
      position: relative;
      overflow: hidden;
      >label,>span{
        font-size: .26rem;
      }
      >input{
        width: calc(~'100% - 1.85rem');
      }
    }
  }


  /*发票*/
  .invoice{
    display: inline-block;
  }

  .need_invoice{
    margin-left: .8rem;
  }

  .invoice_desc{
    margin-left: .8rem;
  }

  .invoice_title{
    font-size: .26rem;
    margin-top: .5rem;
    font-weight: normal;
  }

  .invoice .radio_like{
    border-radius: 50%;
    width: .26rem;
    height: .26rem;
    vertical-align: -3px;
  }

  .invoice .radio_default{
    background: #eeeeee;
  }

  .invoice .invoice_desc{
    padding-bottom: .1rem;
    vertical-align: -1px;
  }

  .addr-select{
    overflow: hidden;
    margin-bottom: 15px;
  }
  .addr-select select{
    width: calc(~'(100% - .2rem) / 3');
    border: 0;
    padding: 15px;
    background: #eeeeee;
    color: #999999;
    float: left;
  }

  .addr-select select + select{
    margin-left: .1rem;
  }

  .province_select{
    margin-left: 0;
  }

  /*支付方式*/
  .payment_title{
    font-size: .26rem;
    margin-bottom: .2rem;
    margin-top: .6rem;
    font-weight: 400;
  }

  .pay_method{
    position: relative;
    border-radius: 2px;
    margin-bottom: 15px;
    height: .9rem;
    overflow: hidden;
    vertical-align: middle;
  }

  .pay_method_default{
    border: 3px solid #d7d7d7;
    border-radius: 2px;
  }

  .pay_method_active{
    border: 3px solid #74c753;
    border-radius: 2px;
  }

  .pay_method .payment_img{
    height: .5rem;
    margin-top: .2rem;
  }

  /*阅读协议*/
  .agreement_read{
    margin-bottom: .5rem;
  }

  /*捐款按钮*/
  .donate_btn{
    margin-top: 0;
    font-weight: normal;
    letter-spacing: 0.5px;
    margin-bottom: .4rem;
    font-size: .3rem;
  }


  .form-group-inline{
    overflow: hidden;
    position: relative;
    margin-top: .5rem;
  }

  .form-group-inline label{
    float: left;
    width: 15%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .form-group-inline .input_text_default{
    width: 85%;
    float: left;
    margin-left: 15%;
  }
}

//入驻机构列表
.org-list{
  .org_item {
    padding-top: .2rem;
    padding-bottom: .2rem;
    display: table;
    width: 100%;
    .org_title {
      color: #424242;
      font-size: .28rem;
      font-weight: normal;
      padding-bottom: .2rem;
    }
    .org_item_img {
      width: 2rem;
      vertical-align: middle;
      .org_item_image {
        background-size: 100% auto;
        height: 2rem;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
        margin-right: 0.2rem;
      }
    }
    .org_item_content {
      margin-left: .2rem;
      box-sizing: border-box;
      width: 100%;
      display: inline-block;
      color: #7a7a7a;
      vertical-align: 0;
      height: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .org_desc {
        display: flex;
        flex-direction: column;
        .org_status {
          font-size: .24rem;
          .donate_num{
            color: @orange;
          }
          & + .org_status{
            margin-top: .1rem;
          }
        }
      }
    }
  }
}

.sz-add-help{
  .tab-title{
    margin-bottom: .3rem;
  }
}

//.org-select{
//    padding-bottom: .3rem;
//	.claim_mask{
//		.title{
//            font-size: .3rem;
//            color: #68c258;
//			&.title-border{
//	    	    padding-bottom: .3rem;
//			    border-bottom: 1px solid #f5f5f5;
//			    margin-top: 0;
//            }
//            .mask_close_img{
//                width: .4rem;
//                height: .4rem;
//            }
//        }
//        .search-group{
//            border: 1px solid #68c258;
//            border-radius: .5rem;
//            height: .5rem;
//            input{
//                border: none;
//                outline: none;
//            }
//        }
//		.article-content{
//			max-height: 6rem;
//			overflow-y: auto;
//		}
//	}
//	.org-select-mask{
//	    background-color: rgba(73, 73, 73, 0.7);
//	    position: fixed;
//	    left: 0;
//	    top: 0;
//	    right: 0;
//	    bottom: 0;
//	    z-index: 0;
//	    opacity: 0;
//	    transition: opacity.3s;
//
//	    &.active{
//	    	opacity: 1;
//	    }
//	}
//
//	/*搜索框*/
//	#claimMask{
//		display: table;
//		.claim_box,.claim_box_valign{
//			.title{
//			    margin-bottom: .3rem;
//			}
//			.search_box{
//				.search_content{
//					border: 0;
//					width: 4rem;
//					line-height: .5rem;
//                    float: left;
//                    margin-left: .3rem;
//				}
//				.search_icon{
//					border: 0;
//                    width: .24rem;
//                    height: .25rem;;
//				    margin-right: .3rem;
//                    float: right;
//                    margin-top: .15rem;
//				}
//			}
//			.mechanism_box{
//                margin-top: .3rem;
//				max-height: 3.5rem;
//    			overflow-y: auto;
//				.mechanism_item{
//					height: .8rem;
//					border-bottom: 1px dashed #f5f5f5;
//					padding-top: .2rem;
//					padding-bottom: .2rem;
//					.mechanism_img{
//						float: left;
//						width: .8rem;
//						height: .8rem;
//						background-position: center;
//						background-size: cover;
//						background-repeat: no-repeat;
//					}
//					.mechanism_desc{
//						padding: 0;
//						float: left;
//						margin-left: .2rem;
//						color: #333;
//
//						.mechanism_name{
//							line-height: 1.2;
//						    margin-bottom: 0;
//						    max-width: 3.7rem;
//						    font-size: .24rem;
//						    height: .6rem;
//						    overflow: hidden;
//						}
//						.mechanism_status{
//							// font-size: 0;
//							.mechanism_status-desc {
//								vertical-align: middle;
//							    display: inline-block;
//							    & + .mechanism_status-desc {
//							      margin-left: .1rem;
//							      &.org-contact {
//							        color: #1E81CD;
//							        cursor: pointer;
//							        position: relative;
//							        .org-detail-list {
//							          display: none;
//							          border-radius: 4px;
//							          background-color: rgb(255, 255, 255);
//							          box-shadow: 0px 0px 5px 0px rgba(40, 40, 40, 0.41);
//							          padding: .3rem .2rem;
//							          position: absolute;
//							          top: 25px;
//							          z-index: 1;
//							          background-color: #fff;
//							          transform: translateX(-50%);
//
//							          &.active{
//							            top: -1.7rem;
//							            &:before{
//							              top: auto;
//							              bottom: -5px;
//							            }
//							            &:after{
//							              top: auto;
//							              bottom: 0;
//							            }
//							          }
//
//							          &:before,&:after{
//							            content: "";
//							            position: absolute;
//							            height: 10px;
//							            background-color: #fff;
//							          }
//
//							          &:before {
//							            width: 10px;
//							            top: -5px;
//							            left: 50%;
//							            transform: translateX(-50%) rotate(45deg);
//							            box-shadow: 0px 0px 5px 0px rgba(40, 40, 40, 0.41);
//							          }
//
//							          &:after {
//							            content: "";
//							            width: 100%;
//							            top: 0;
//							            left: 0;
//							            z-index: 1;
//							          }
//
//							          .org-detail-item {
//						          	    min-width: 4rem;
//										max-width: 5rem;
//							            & + .org-detail-item {
//							              margin-top: 20px;
//							            }
//
//							            .detail-title {
//							              color: #666;
//							            }
//
//							            .detail-desc {
//							              color: #333;
//							            }
//
//							          }
//
//							        }
//
//							      }
//
//							      img, span {
//							        vertical-align: middle;
//							      }
//
//							      img {
//							        margin-right: .1rem;
//							      }
//
//							    }
//
//							  }
//						}
//					}
//					.button_invitate{
//						outline: none;
//						line-height: .6rem;
//						height: .6rem;
//						margin-top: .1rem;
//					    width: 1.2rem;
//					    font-size: .24rem;
//					    color: #69c258;
//					    float: right;
//					    border: 1px solid #69c258;
//					    background-color: #ffffff;
//					    transition: background-color .3s;
//					    margin-right: 1px;
//
//					    &.active{
//					    	background-color: #69c258;
//					    	color: #fff;
//
//					    	&:before{
//							    content: url(../img/checked.png);
//							    display: inline-block;
//							    margin-right: .2rem;
//							    width: 15px;
//							    height: 10px;
//							    margin-right: 10px;
//					    	}
//					    }
//					}
//				}
//			}
//
//			.pagination{
//				margin-top: .3rem;
//			}
//
//			.invitation_box{
//				position: relative;
//				margin-top: .2rem;
//				padding-top: .2rem;
//				padding-bottom: .4rem;
//				overflow: hidden;
//				.invitation_desc{
//				    float: left;
//				    font-size: .24rem;
//					.invitation_count{
//						margin-bottom: .3rem;
//						.invitation_number,.invitation_max{
//							padding-left: .1rem;
//							padding-right: .1rem;
//							color: #e24c37;
//						}
//					}
//				}
//				.button_confirm{
//					&.mask_close_img{
//					    margin-top: .2rem;
//					    position: relative;
//					    width: 2rem;
//					    padding: 0;
//					    line-height: .2rem;
//					    right: 0;
//					    height: .6rem;
//					    line-height: .5rem;
//					    float: right;
//					}
//				}
//			}
//		}
//	}
//
//	#agree{
//		.claim_box{
//			min-height: 80%;
//			.modal_footer{
//			    position: absolute;
//			    bottom: .6rem;
//			    width: calc(~"100% - .6rem");
//				.accept_box{
//					overflow: hidden;
//					.button{
//					    float: left;
//					    border: 0;
//					    width: calc(~"50% - .15rem");
//
//					    & + .button{
//					    	margin-left: .3rem;
//					    }
//					}
//				}
//			}
//		}
//	}
//}

//.claim_mask {
//    background: rgba(73, 73, 73, 0.7);
//    position: fixed;
//    left: 0;
//    top: 0;
//    z-index: -1;
//    height: 100%;
//    width: 100%;
//    opacity: 0;
//    -webkit-transition: opacity .5s;
//    transition: opacity .5s;
//    .v-align {
//        vertical-align: middle;
//        display: table-cell;
//    }
//    .claim_box {
//        background: #ffffff;
//        margin-left: 15px;
//        margin-right: 15px;
//        position: absolute;
//        left: 15px;
//        right: 15px;
//        top: 50%;
//        border-radius: 2px;
//        padding: .3rem .3rem .6rem;
//        transition: transform .5s;
//        transform: translateY(-54%);
//        .title {
//            font-size: .3rem;
//            margin: .25rem auto .55rem;
//            line-height: 1.2;
//            font-weight: 400;
//            padding-left: .3rem;
//            padding-right: .3rem;
//        }
//        .claim_succes_img {
//            width: 1.25rem;
//            height: 1.25rem;
//            margin-top: .5rem;
//            margin-bottom: .55rem;
//            float: right;
//            margin-left: -1.25rem;
//        }
//        .mask_content .desc {
//            text-indent: 2em;
//            line-height: 1.5;
//            font-size: .26rem;
//            color: #333;
//            margin-bottom: .2rem;
//        }
//        .mask_close_img {
//            position: absolute;
//            right: .3rem;
//            top: .3rem;
//            width: .3rem;
//            height: .3rem;
//        }
//        .claim_status_desc {
//            font-size: .2rem;
//            font-weight: 400;
//            margin-bottom: .5rem;
//            line-height: 1.2;
//            text-align: left;
//        }
//        .claim_project_desc {
//            font-size: .24rem;
//            margin-bottom: .2rem;
//        }
//        .claim_org_desc {
//            font-size: .24rem;
//            margin-bottom: .6rem;
//        }
//        .btn_donate {
//            background-color: #FF6610;
//            color: #ffffff;
//            border: 0;
//            line-height: .9rem;
//            font-weight: normal;
//            font-size: .3rem;
//        }
//    }
//    .claim_box_valign {
//        background: #ffffff;
//        margin-left: 15px;
//        margin-right: 15px;
//        border-radius: 2px;
//        transition: transform .5s;
//        padding: .3rem .3rem .6rem;
//        transform: translateY(-5px);
//      }
//  }
.mechanism-desc{
  padding-top: .5rem;
  padding-bottom: .4rem;
  height: unset;
  display: flex;
  align-items: flex-start;
  .logo-wrapper{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-right: .2rem;
    img{
      width: 1.4rem;
    }
  }
  .mechanism-content{
    h4{
      font-weight: normal;
      font-size: .28rem;
    }
    .btn-closing-report{
      margin-top: .2rem;
      .flex-center;
      width: 1.55rem;
      height: .45rem;
      border: 1px solid @border-color;
      font-size: .24rem;
      color: @light-black;
    }
    p{
      margin-top: .2rem;
      font-size: .24rem;
      line-height: 1.6;
      color: #666666;
    }
    .org-summary-retract{
      display: inline-block;
      margin-top: .1rem;
    }
    .org-summary-retract,.load-more-org-summary{
      font-size: .24rem;
      color: #68c258;
    }
    .org-summary-review{
      position: relative;
      .load-more-org-summary{
        position: absolute;
        right: 0;
        bottom: .05rem;
      }
    }
  }
}
.sz-org-detail{
  .org-donate-count{
    line-height: 1.15rem;
    height: 1.15rem;
    font-size: 0;
    .title{
      font-size: .2rem;
      color: #666666;
      margin-right: .2rem;
      color: #666666;
    }
    .desc{
      font-size: .4rem;
      color: @orange;
    }
  }
  .org-name-card{
    .org-name-card-wrap{
      padding-top: .4rem;
      padding-bottom: .4rem;
      .item{
        font-size: 0;
        overflow: hidden;
        & + .item{
          margin-top: .15rem;
        }
        .title{
          width: 1.4rem;
          float: left;
          font-size: .26rem;
          color: #999999;
        }
        .desc{
          line-height: 1.2;
          margin-left: 1.4rem;
          font-size: .26rem;
          color: #333333;
        }
      }
    }
  }
  .btn{
    width: auto;
    padding: 0 .2rem;
  }
}

.project-tab,.project-tab-static{
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
  font-size: 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  //边框长度占满屏幕
  &.row-title{
    .row;
    padding-left: 15px;
    padding-right: 15px;
  }
  .title-deco{
    border-bottom: none;
    font-size: .28rem;
    display: inline-block;
    padding: .2rem 0;
    & + .title-deco{
      margin-left: 1rem;
    }

    &.active{
      color: #68c258;
      border-bottom: 3px solid #68c258;
      margin-bottom: -1px;
    }
  }
}
.donate_success{
  padding: .3rem;
  background-color: #f1f2f4;
  position: absolute;
  left: 0;
  right: 0;
  top: 1.2rem;
  bottom: 0;
  .content{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 1px #ecedef;
    padding: .3rem;
    margin-bottom: .5rem;
  }
  .donate-msg{
    border: 1px dashed #68c258;
    padding: .3rem;
    font-size: .26rem;
    line-height: 1.6;
    border-radius: 5px;
    .user_name{
      font-size: .28rem;
      color: #333333;
      margin-bottom: .4rem;
    }
    #descIndent{
      text-indent: .4rem;
    }
    .donate_desc{
      color: #666666;
      margin-bottom: .2rem;
    }
    .donate_mechanism{
      text-align: right;
      color: @orange;
      margin-top: .8rem;
    }
    .donate_time{
      color: #999999;
      text-align: right;
    }
  }
  .donate-share-tip{
    background: url('../../../../img/donate-share-bg.jpg') no-repeat;
    width: 100%;
    background-size: cover;
    height: 3rem;
    margin: .5rem auto;
    color: #fff;
    font-size: .26rem;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    .tip-content{
      position: absolute;
      left: .12rem;
      right: .12rem;
      top: .12rem;
      bottom: .12rem;
      border: 1px solid #fff;
      border-radius: 5px;
      .tip{
        position: absolute;
        left: .3rem;
        top: .6rem;
        p{
          margin-bottom: .1rem;
        }
      }
      .card-btn{
        background-color: #fff;
        color: #68c258;
        font-size: .24rem;
        height: .4rem;
        line-height: .4rem;
        border-radius: .4rem;
        padding: 0 .25rem;
        position: absolute;
        left: .3rem;
        bottom: .6rem;
      }
    }
  }
  .operation{
    font-size: .24rem;
    a{
      color: #999999;
    }
  }
  .user-comment{
    display: flex;
    align-items: center;
    margin-bottom: .3rem;
    .avatar{
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
      margin-right: .2rem;
      flex-shrink: 0;
    }
    p{
      font-size: .26rem;
      color: #666666;
      line-height: 1.8;
    }
  }
  .project-info{
    position: relative;
    .wish{
      position: absolute;
      top: .4rem;
      right: .3rem;
      color: #fff;
      font-size: .28rem;
      z-index: 2;
    }
    img.cover{
      width: 100%;
      height: auto;
      display: block;
      border-radius: 5px;
      margin-bottom: .3rem;
    }
    .project-content{
      display: flex;
      align-items: center;
      img.qrcode{
        width: 1.2rem;
        height: 1.2rem;
        margin-right: .2rem;
        flex-shrink: 0;
      }
      .title{
        h2{
          color: #333;
          font-size: .28rem;
          margin-bottom: .2rem;
        }
        p{
          font-size: .24rem;
          color: #999999;
        }
      }
    }
  }
}

.user-help-list{
  .item{
    padding: .3rem 0;
    font-size: .24rem;
  }
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .2rem;
    h4{
      font-size: .26rem;
      color: #333;
      line-height: 1.2;
    }

  }
  .stat, .meta{
    display: flex;
    justify-content: space-between;
    color: #999;
    margin-top: .1rem;
    span{
      color: @orange;
    }
  }
  .operation{
    padding: .2rem 0;
    border-top: 1px solid #f5f5f5;
    margin-top: .2rem;
    .btn{
      font-size: .24rem;
      height: 0.5rem;
      line-height: 0.5rem;
    }

  }
}

//ng-dialog 默认设置
.ngdialog.ngdialog-theme-default{
  .ngdialog-content{
    background-color: #fff !important;
    max-width: 80% !important;
    padding: 0 !important;
    .popup-content{
      max-height: 6rem;
    }
  }
  .ngdialog-close{
    right: .2rem !important;
  }
}


.text-gray{
  color: @gray;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
//选择机构
.claim_box{
  padding: .2rem .4rem;
  .claim-header{
    .title{
      height: .45rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .3rem;
      color: @green;
      border-bottom: 1px solid @border;
      padding-bottom: .1rem;
      margin-right: -.4rem;
      margin-left: -.4rem;
      padding-left: .4rem;
      padding-right: .4rem;
    }
    .mask_close_img{

    }
    .search-keyword{
      margin: .2rem 0;
      position: relative;
      input{
        padding: 0 .2rem;
        font-size: .24rem;
        width: 100%;
        height: .55rem;
        line-height: .55rem;
        border-radius: 25px;
        border: 1px solid @green;
      }
      .search_icon{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1rem;
        z-index: 1;
        background-color: transparent;
        border: none;
        .icon-search{
          background: url(../../../../img/search.png) no-repeat center;
          background-size: cover;
          width: .24rem;
          height: .25rem;
          display: inline-block;
        }
      }
    }
  }
  .mechanism_box{
    max-height: 4rem;
    overflow-y: auto;
    margin-bottom: .3rem;
    padding-bottom: .05rem;
    .mechanism_item{
      padding: .2rem 0;
      display: flex;
      justify-content: space-between;
      position: relative;
      border: 1px solid transparent;
      border-bottom-color: @border;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      &.active{
        border-color: @green;
      }
      & + .mechanism_item{
        margin-top: .1rem;
      }
      >a{
        width: 1.85rem;
        flex-shrink: 0;
        margin-right: .2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img{

        }
      }
      .mechanism_desc{
        font-size: .24rem;
        flex-grow: 1;
        overflow: hidden;
        .mechanism_name{
          .text-overflow;
          font-size: .26rem;
          color: #333333;
          margin-bottom: .25rem;
        }
        .mechanism_number{
          color: @light-black;
        }
        & + .mechanism_desc{
          margin-top: .2rem;
        }
      }
      .btn-invite{
        display: none;
      }
    }
  }
  .button_confirm{
    background-color: #69c258;
    box-shadow: 0px 3px 3px 0px rgba(53, 142, 36, 0.3);
    color: #fff;
    margin-top: .2rem;
    font-size: .24rem;
  }
  .invitation_box{
    font-size: .24rem;
    .invitation_name{
      span{
        & + span{
          margin-left: .1rem;
        }
      }
    }
  }
}

//选中的机构列表
.org-select-mask{

}
.select-org{
  margin-bottom: 1.1rem;
}
.org_list{
  font-size: .24rem;
  color: @light-black;
  margin-left: -3.3%;
  &:after{
    content: '';
    clear: both;
    display: block;
  }
  .org_item{
    float: left;
    width: 30%;
    position: relative;
    margin-left: 3.3%;
    .org_img{
      height: 1.2rem;
    }
    .remove_mechanism{
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%,-50%);
    }
  }
}


.ngdialog{
  overflow: hidden !important;
}

.ng-pagination{
  display: flex;
  justify-content: center;
  font-size: .24rem;
  li{
    a{
      color: @light-black;
    }
    & + li{
      margin-left: .1rem;
    }
    &.active{
      a{
        color: @green;
      }
    }
  }
}

.special{
  &-item{
    position: relative;
    display: inline-block;
    img{
      max-width: 100%;
    }
    &-desc{
      font-size: .24rem;
      color: @black;
      background-color: rgba(255,255,255, .6);
      padding: .18rem .19rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      .text-overflow();
      max-width: 100%;
    }
  }
  &-child-list{
    //display: flex;
    //justify-content: space-between;
    padding: .3rem 0;
    > li{

    }
    .special-child-item{
      display: inline-block;
      overflow: hidden;
      font-size: .24rem;
      width: 2.06rem;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0rem 0.01rem 0.06rem 0rem rgba(29, 31, 33, 0.16);
      .inner-desc-box{
        padding: .2rem;
        width: 100%;
        box-sizing: border-box;
      }
      &-name{
        color: @black;
        margin-bottom: .1rem;
        .text-overflow();
      }
      &-price{
        color: @red;
      }
    }
  }
}
