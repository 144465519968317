/* 清除内外边距 */

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
  /* structural elements 结构元素 */

dl,
dt,
dd,
ul,
ol,
li,
  /* list elements 列表元素 */

pre,
  /* text formatting elements 文本格式元素 */

fieldset,
lengend,
button,
input,
textarea,
  /* form elements 表单元素 */

th,
td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
  outline: none;
}

/* 设置默认字体 */

body,
button,
input,
select,
textarea {
  /* for ie */
  font: 12px/1 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

h1 {
  font-size: 18px;
  font-weight: normal;
  /* 18px / 12px = 1.5 */
}

h2 {
  font-size: 16px;
  font-weight: normal;
}

h3 {
  font-size: 14px;
  font-weight: normal;
}

h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

/* 将斜体扶正 */

code,
kbd,
pre,
samp,
tt {
  font-family: "Courier New", Courier, monospace;
}

/* 统一等宽字体 */

small {
  font-size: 12px;
}

/* 小于 12px 的中文很难阅读，让 small 正常化 */

/* 重置列表元素 */

ul,
ol {
  list-style: none;
}

/* 重置文本格式元素 */

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

abbr[title],
acronym[title] {
  /* 注：1.ie6 不支持 abbr; 2.这里用了属性选择符，ie6 下无效果 */
  border-bottom: 1px dotted;
  cursor: help;
}

q:before,
q:after {
  content: '';
}

/* 重置表单元素 */

legend {
  color: #000;
}

/* for ie6 */

fieldset,
img {
  border: none;
}

/* img 搭车：让链接里的 img 无边框 */

/* 注：optgroup 无法扶正 */

button,
input,
select,
textarea {
  font-size: 100%;
  /* 使得表单元素在 ie 下能继承字体大小 */
}

/* 重置表格元素 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置 hr */

hr {
  border: none;
  height: 1px;
}

body {
  font-family: arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  background-color: #fff;
}

.img_responsive {
  width: 100%;
}

a {
  color: #333;
}

p {
  line-height: 1.6;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

p,
div {
  word-wrap: break-word;
  word-break: break-all;
}

iframe {
  max-width: 100%;
  height: 4rem;
}

.text_danger {
  color: #e24c37 !important;
}

.text_default {
  color: #999999;
}

.explain {
  margin-top: 10px;
  padding-left: 20px;
  vertical-align: middle;
  background: url(../../../../mobile/img/notice.png) no-repeat left center;
  color: #999999;
  background-size: contain;
}

.line-grey {
  display: block;
  height: 1px;
  background-color: #e9e9e9;
  margin: .3rem 0;
}

a,
a:hover {
  text-decoration: none;
}

.empty-content {
  margin: .3rem 0;
  font-size: .24rem;
  color: #999;
  text-align: center;
}

.btn {
  display: inline-block;
  width: 1.8rem;
  height: .5rem;
  line-height: .5rem;
  text-align: center;
  border-radius: .5rem;
  font-size: .28rem;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.btn-small{
  height: .4rem;
  line-height: .4rem;
  font-size: .24rem;
  border-radius: .4rem;
}

.btn-primary {
  color: #fff;
  background-color: #68c258;
}

.btn-second {
  color: #fff;
  background-color: #fd7400;
}
.btn-grey {
  color: #333;
  background-color: #e6e6e6;
}
.btn-frame-primary{
  background-color: transparent;
  color: #68c258;
  border-color: #68c258;
}
.btn-frame-second{
  background-color: transparent;
  color: #fd7400;
  border-color: #fd7400;
}
.cover img {
  display: block;
  width: 100%;
  height: auto;
}

.button_group {
  overflow: hidden;
}

.button {
  width: 100%;
  border-radius: 2px;
  font-weight: bold;
  display: block;
  font-size: .2rem;
  height: .8rem;
  line-height: .9rem;
}

.button_danger {
  box-shadow: 0 2px #d83627 !important;
  background: #e24c37 !important;
  color: #ffffff !important;
  border: 0;
  font-size: .28rem;
  font-weight: 400;
}

.button_warning {
  box-shadow: 0 2px #f39826;
  background: #f6b035;
  color: #ffffff;
  font-size: .28rem;
  font-weight: 400;
}

.button_primary {
  box-shadow: 0 2px #3DA0D3;
  background: #47ACDB;
  color: #ffffff;
  font-size: .28rem;
  font-weight: 400;
}

.button_default {
  background: #f1f1f1;
  color: #333;
  font-weight: 400;
}

.button_white {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #e24c37;
  font-size: .28rem;
  font-weight: 400;
}

.button-red {
  background-color: #e24c37;
  color: #fff;
  box-shadow: 0 1px 1px #f69935;
  border-radius: 3px;
  display: inline-block;
  border: none;
  text-align: center;
  padding: 5px 14px;
}

.load_more {
  height: .8rem;
  font-size: .24rem;
  line-height: .8rem;
  display: block;
  color: #333333;
}

.clearfix {
  clear: both;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.over_hidden {
  overflow: hidden;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto
}

.offcanvas {
  position: fixed;
}

.tag {
  display: inline-block;
  border-radius: .4rem;
  height: .4rem;
  line-height: .4rem;
  padding: 0 .2rem;
  font-size: 12px;
  box-sizing: border-box;
}

.tag-primary {
  background-color: #fd7400;
  color: #fff;
}

/*背景为灰色*/

.input_text_default {
  border: 0;
  background: #eeeeee;
  padding: 15px;
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
}

/*背景为白色*/

.input_text_primary {
  border: 0;
  background: #ffffff;
  padding-left: .7rem;
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
}

.input_text_white {
  border: 0;
  background: #eeeeee;
  padding-left: .7rem;
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
  height: .8rem;
  line-height: .8rem;
}

.dis_table {
  display: table;
}

.dis_table_cell {
  display: table-cell;
}

.hidden {
  display: none !important;
}

.uploadify-queue .uploadify-progress {
  display: inline-block;
  background-color: #dedede;
  position: relative;
  width: 80%;
  height: 6px;
  text-align: center;
}

.uploadify-queue .uploadify-progress .uploadify-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e24c37;
  height: 6px;
}

.img-box {
  width: 120px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: .2rem;
}

.img-box img {
  max-width: 100%;
  height: auto;
}

.img-box .remove-picture {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  background: url(../../../../mobile/img/del-pic.png) no-repeat center;
  cursor: pointer;
}

/* 普通文章样式 */

.article-content p {
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 10px;
}

.article-content img {
  max-width: 100% !important;
  height: auto !important;
}

.article-content h1 {
  font-size: 20px;
}

.article-content h2 {
  font-size: 18px;
}

.article-content h3 {
  font-size: 16px;
}

.article-content h4,
.article-content h5,
.article-content h6 {
  font-size: 14px;
}

.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  padding: 15px 0;
  font-weight: bold;
}

.article-content ul {
  list-style: square;
  margin-left: 1em;
  padding: 10px 0;
}

.article-content ol {
  list-style: decimal;
  margin-left: 1em;
  padding: 10px 0;
}

.article-content blockquote {
  padding-left: 10px;
  border-left: 3px solid #dbdbdb;
  font-size: 14px;
}

.article-content blockquote p {
  font-size: 14px;
}

.article-content textarea {
  max-width: 100%;
}

.article-content video,audio {
	max-width: 100%;
	height: auto;
	max-height: 100%;
}

/*head*/

header {
  padding-bottom: .2rem;
  padding-top: .2rem;
  position: relative;
  display: flex;
  align-items: center;
}

header .logo-wrap {
  height: .8rem;
  line-height: .8rem;
  font-size: 0;
}

header .logo-wrap a {
  display: inline-block;
  vertical-align: middle;
}

header .sub_module_anchor {
  margin-left: .2rem;
  line-height: .65rem;
  width: 1.7rem;
  height: .65rem;
  border: .02rem dashed #e24c37;
  border-radius: 50px;
  text-align: center;
  box-sizing: border-box;
}

header .sub_module_anchor span {
  font-size: .26rem;
  color: #e24c37;
  margin-left: .1rem;
  line-height: .26rem;
  height: .26rem;
}

header .sub_module_anchor .img-sj-logo {
  width: .25rem;
  height: .2rem;
}

header .sub_module_anchor .img-sz-logo {
  width: .19rem;
  height: .21rem;
}


header .menu_img {
  width: auto;
  height: .54rem;
}

header .msg-box{
  margin-left: auto;
  margin-right: .45rem;
  position: relative;
  .bell{
    height: .54rem;
  }
  .num{
    min-width: .3rem;
    height: .3rem;
    border-radius: 50%;
    position: absolute;
    background: #F61919;
    color: #fff;
    font-size: .22rem;
    line-height: 1.5;
    text-align: center;
    top: 0px;
    right: -.10rem;
    border: .04rem solid #fff;
  }
}
/*footer*/

footer {
  background: #7cca50;
  overflow: hidden;
  padding: .2rem 15px;
  color: #fff;
}

footer a {
  color: #fff;
}

footer p {
  font-size: 12px;
  line-height: 1.5;
}

/*灰色bar*/

.bar {
  background: #e3e4e5;
  height: 0.1rem;
}

//文字颜色
.text-red{
  color: #f61919 !important;
}

/*选项卡*/

.tab_box {
  font-size: .28rem;
  height: .8rem;
  line-height: .8rem;
}

.tab_box .tab_item {
  background: #f1f1f1;
}

.tab_box .tab_item_active {
  background: #e24c37;
  color: #ffffff;
  transition: background 1s;
  box-shadow: 0 2px #d83627;
}

/*弹出层*/

.mask {
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, .7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  transition: opacity .3s;
}

.mask .left_bar {
  height: 100%;
  width: calc(100% - 5.2rem);
}

.mask .right_bar {
  width: 5.2rem;
  background: #ffffff;
  height: 100%;
  transform: translateX(4.4rem);
  transition: transform .5s;
  float: right;
  padding-bottom: .2rem;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}

.mask .right_bar .right_bar_content {
  padding: 0 .2rem .2rem;
}

.right_bar_menu .right_bar_item {
  border-bottom: 1px solid #f1f1f1;
  display: block;
  margin: 0 .3rem;
  padding-left: 0.3rem;
  font-size: .3rem;
  height: 1rem;
  line-height: 1rem;
  color: #333333;
  padding-right: 0.3rem;
}

.right_bar_menu {
  padding-bottom: .2rem;
}

.mask .right_bar_menu .login {
  height: .8rem;
  line-height: .8rem;
  font-size: .26rem;
  color: #e66452;
  border: 1px solid #e66452;
  background: #ffffff;
  text-align: center;
  border-radius: 2px;
  margin-bottom: .3rem;
  display: block;
  margin-right: .3rem;
  margin-left: .3rem;
}

.single_login {
  margin-top: .4rem;
}

.right_bar_menu .info_open {
  padding-left: 15px;
  background: #e24c37;
  border-radius: 3px;
  height: 1.4rem;
  margin-left: .3rem;
  margin-right: .3rem;
  display: none;
}

.right_bar_menu .info_open>a {
  font-size: .24rem;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  display: block;
  height: .7rem;
  line-height: .7rem;
  padding-left: .3rem;
}

.right_bar_menu .info_open>a:last-child {
  border: 0;
}

/*个人/机构登陆*/

.login_logo {
  margin-top: 1.1rem;
  width: 3rem;
  height: 2.1rem;
}

/*项目推荐*/

.recommend_project {
  position: relative;
}

.recommend_project_top_box {
  width: 100%;
}

.recommend_project_top_box .project_form {
  margin-bottom: -.9rem;
}

.recommend_project .recommend_project_left_top {
  font-size: .34rem;
  width: 2rem;
  margin-right: .3rem;
  font-weight: normal;
  vertical-align: middle;
  line-height: .8rem;
}

.recommend_project .recommend_project_left_top>img {
  width: .43rem;
  vertical-align: -.1rem;
}

.recommend_project_right_top {
  width: calc(100% - 2.3rem);
  overflow: hidden;
  position: relative;
  top: -.8rem;
  float: right;
}

.recommend_project_right_top input {
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  line-height: .8rem;
  padding-left: .2rem;
  padding-right: .8rem;
  border: 0;
  background: #eeeeee;
  border-radius: 2px;
  width: 100%;
}

.recommend_project_right_top>img {
  width: .44rem;
  height: .44rem;
  position: absolute;
  right: .3rem;
  top: 50%;
  z-index: 1;
  margin-top: -.22rem;
}

.recommend_project_top_box .type {
  font-size: .14rem;
  clear: both;
  position: relative;
  margin-bottom: .3rem;
}

.recommend_project_top_box .type .top {
  overflow: hidden;
  font-size: .24rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
}

.recommend_project_top_box .type .top .type_title {
  font-weight: 500;
  float: left;
}

.recommend_project_top_box .type .top .get_more {
  font-weight: normal;
  float: right;
  margin-top: .12rem;
  margin-bottom: 0;
  cursor: default;
  font-size: .24rem;
  margin-right: .1rem;
  width: 1.4rem;
}

.recommend_project_top_box .type .top .get_more:after {
  content: "";
  display: inline-block;
  background: url(../../../../mobile/img/load_more.png) no-repeat center;
  background-size: cover;
  width: .14rem;
  height: .08rem;
  margin-left: .1rem;
  vertical-align: middle;
}

.recommend_project_top_box .type .type_content {
  letter-spacing: -4px;
  margin-right: -.1rem;
  margin-left: -.1rem;
  font-size: .24rem;
}

.recommend_project_top_box .type .type_content.few {
  float: left;
  width: 5rem;
}

.recommend_project_top_box .type .type_content .type_item {
  border: 1px solid #f5f5f5;
  letter-spacing: 0;
  color: #494949;
  padding: .1rem .25rem;
  margin-bottom: .2rem;
  display: inline-block;
  margin-left: .1rem;
  cursor: pointer;
  vertical-align: text-bottom;
}

.recommend_project_top_box .type .type_content.few .type_item {
  margin-bottom: 0;
}

.recommend_project_top_box .type .type_content .type_item:hover {
  background-color: #e24c37;
  color: #ffffff;
  border: 1px solid #e24c37;
}

.recommend_project_top_box .type .type_content .type_item_active {
  background-color: #e24c37;
  color: #ffffff;
  border: 1px solid #e24c37;
}

.fadeIn {
  opacity: 1;
}

.mb30 {
  margin-bottom: .3rem;
}

.mb20 {
  margin-bottom: .2rem;
}

.mt0 {
  margin-top: 0;
}

/*项目进度条*/

.progress {
  background-color: #dedede;
  position: relative;
  height: .16rem;
  border-radius: 50px;
}

.progress .current-num {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e24c37;
  height: 100%;
  max-width: 100%;
  border-radius: 50px;
}

.progress .number {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  background-color: #f6b035;
  color: #fff;
  padding: .05rem .1rem;
}

.popup-item,
.popupLink {
  cursor: pointer;
}
.msgBoxBackGround {
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1000;
}
.msgBox {
  width: 90% !important;
  left: 50% !important;
  margin-left: -45%;
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  .msgBoxTitle {
    margin-bottom: .3rem;
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    span{
      display: inline-block;
      font-size: .3rem;
      border-bottom: 3px solid #68c258;
      margin-bottom: -2px;
      height: .55rem;
      line-height: .55rem;
    }
  }
  .msgBoxImage {
    display: none;
  }
  .msgBoxContent {
    color: #333;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: .3rem;
  }

  .msgBoxButtons {
    display: flex;
    justify-content: space-around;
    .btn {
      margin-left: .3rem;
      height: .5rem;
      line-height: .5rem;
      width: 1.8rem;
      color: #fff;
      background-color: #fd7400;
      &:first-child{
        margin-left: 0;
      }
    }
  }
}

.process {
  display: flex;
  align-items: center;
}

.process_outer {
  position: relative;
  height: .12rem;
  background-color: #ededed;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}

.process_inner {
  position: absolute;
  height: .12rem;
  top: 0;
  left: 0;
  background-color: #68c258;
  border-radius: 5px;
}

.process_number {
  font-size: .24rem;
  color: #68c258;
  white-space: nowrap;
  width: 1rem;
  text-align: right;
}

.panel_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .25rem 0;
  border-bottom: 1px solid #e5e5e5;
  h2 {
    padding-left: 8px;
    border-left: 2px solid #68c258;
    color: #68c258;
    font-size: .32rem;
  }
  .close-search-mask{
    filter: grayscale(100%);
  }
  .link {
    color: #666;
    font-size: .24rem;
  }
}

.tab-title {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: .28rem;
    color: #68c258;
    padding: .2rem 0;
    border-bottom: 3px solid #68c258;
    margin-bottom: -2px;
    display: inline-block;
  }
  .tip {
    font-size: 12px;
    color: #fd7400;
  }
}

.tips {
  color: #999999;
  font-size: .24rem;
  padding-left: .3rem;
  background: url(../../../../mobile/img/notice.png) no-repeat left 3px;
  background-size: 12px 12px;
  line-height: 1.6;
  margin: .2rem 0;
}

.mask-weixin {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7) url(../../../../mobile/img/share-guide-codonate.png) no-repeat right 0.5rem/contain;
  max-width: 720px;
  margin: 0 auto;
}

.title-desc-list{
  font-size: .24rem;
  li{
    .title{
      color: #999999;
    }
    .desc{
      color: #666666;
    }
  }
  &.inline{
    display: flex;
    justify-content: space-between;
  }
}

//文字一行  溢出隐藏
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 清除内外边距 */

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
  /* structural elements 结构元素 */

dl,
dt,
dd,
ul,
ol,
li,
  /* list elements 列表元素 */

pre,
  /* text formatting elements 文本格式元素 */

fieldset,
lengend,
button,
input,
textarea,
  /* form elements 表单元素 */

th,
td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
  outline: none;
}

/* 设置默认字体 */

body,
button,
input,
select,
textarea {
  /* for ie */
  font: 12px/1 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

h1 {
  font-size: 18px;
  font-weight: normal;
  /* 18px / 12px = 1.5 */
}

h2 {
  font-size: 16px;
  font-weight: normal;
}

h3 {
  font-size: 14px;
  font-weight: normal;
}

h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

/* 将斜体扶正 */

code,
kbd,
pre,
samp,
tt {
  font-family: "Courier New", Courier, monospace;
}

/* 统一等宽字体 */

small {
  font-size: 12px;
}

/* 小于 12px 的中文很难阅读，让 small 正常化 */

/* 重置列表元素 */

ul,
ol {
  list-style: none;
}

/* 重置文本格式元素 */

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

abbr[title],
acronym[title] {
  /* 注：1.ie6 不支持 abbr; 2.这里用了属性选择符，ie6 下无效果 */
  border-bottom: 1px dotted;
  cursor: help;
}

q:before,
q:after {
  content: '';
}

/* 重置表单元素 */

legend {
  color: #000;
}

/* for ie6 */

fieldset,
img {
  border: none;
}

/* img 搭车：让链接里的 img 无边框 */

/* 注：optgroup 无法扶正 */

button,
input,
select,
textarea {
  font-size: 100%;
  /* 使得表单元素在 ie 下能继承字体大小 */
}

/* 重置表格元素 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 重置 hr */

hr {
  border: none;
  height: 1px;
}

body {
  font-family: arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  background-color: #fff;
}

.img_responsive {
  width: 100%;
}

a {
  color: #333;
}

p {
  line-height: 1.6;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

p,
div {
  //word-wrap: break-word;
  //word-break: break-all;
}

iframe {
  max-width: 100%;
  height: 4rem;
}

.text_danger {
  color: #e24c37 !important;
}

.text_default {
  color: #999999;
}

.explain {
  margin-top: 10px;
  padding-left: 20px;
  vertical-align: middle;
  background: url(../../../../img/notice.png) no-repeat left center;
  color: #999999;
  background-size: contain;
}

.line-grey {
  display: block;
  height: 1px;
  background-color: #e9e9e9;
  margin: .3rem 0;
}

a,
a:hover {
  text-decoration: none;
}

.empty-content {
  margin: .3rem 0;
  font-size: .24rem;
  color: #999;
  text-align: center;
}

.cover img {
  display: block;
  width: 100%;
  height: auto;
}

.button_group {
  overflow: hidden;
}

.button {
  width: 100%;
  border-radius: 2px;
  font-weight: bold;
  display: block;
  font-size: .2rem;
  height: .8rem;
  line-height: .9rem;
}

.button_danger {
  box-shadow: 0 2px #d83627 !important;
  background: #e24c37 !important;
  color: #ffffff !important;
  border: 0;
  font-size: .28rem;
  font-weight: 400;
}

.button_warning {
  box-shadow: 0 2px #f39826;
  background: #f6b035;
  color: #ffffff;
  font-size: .28rem;
  font-weight: 400;
}

.button_primary {
  box-shadow: 0 2px #3DA0D3;
  background: #47ACDB;
  color: #ffffff;
  font-size: .28rem;
  font-weight: 400;
}

.button_default {
  background: #f1f1f1;
  color: #333;
  font-weight: 400;
}

.button_white {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #e24c37;
  font-size: .28rem;
  font-weight: 400;
}

.button-red {
  background-color: #e24c37;
  color: #fff;
  box-shadow: 0 1px 1px #f69935;
  border-radius: 3px;
  display: inline-block;
  border: none;
  text-align: center;
  padding: 5px 14px;
}

.load_more {
  height: 1.2rem;
  font-size: .24rem;
  line-height: 1.2rem;
  display: block;
  color: #333333;
}
input[type=text]{
  -webkit-appearance:none;
}
.clearfix {
  clear: both;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.over_hidden {
  overflow: hidden;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto
}

.tag {
  border-radius: .4rem;
  height: .4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 .2rem;
  font-size: 12px;
  box-sizing: border-box;
  &.tag-primary {
    background-color: #fd7400;
    color: #fff;
  }
  &.tag-gray {
    background-color: #e6e6e6;
    color: #666;
  }
}

/*背景为灰色*/

.input_text_default {
  border: 0;
  background: #eeeeee;
  padding: 15px;
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
}

/*背景为白色*/

.input_text_primary {
  border: 0;
  background: #ffffff;
  padding-left: .7rem;
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
}

.input_text_white {
  border: 0;
  background: #eeeeee;
  padding-left: .7rem;
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
  height: .8rem;
  line-height: .8rem;
}

.dis_table {
  display: table;
}

.dis_table_cell {
  display: table-cell;
}

.hidden {
  display: none !important;
}

.uploadify-queue .uploadify-progress {
  display: inline-block;
  background-color: #dedede;
  position: relative;
  width: 80%;
  height: 6px;
  text-align: center;
}

.uploadify-queue .uploadify-progress .uploadify-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #e24c37;
  height: 6px;
}

.img-box {
  width: 120px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: .2rem;
}

.img-box img {
  max-width: 100%;
  height: auto;
}

.img-box .remove-picture {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  background: url(../../../../mobile/img/del-pic.png) no-repeat center;
  cursor: pointer;
}

/* 普通文章样式 */
.article-content iframe{
  width: 100%;
}

.article-content p {
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  margin-bottom: 10px;
}

.article-content p  span{
  display: inline;
}

.article-content img {
  max-width: 100% !important;
  height: auto !important;
}

.article-content h1 {
  font-size: 20px;
}

.article-content h2 {
  font-size: 18px;
}

.article-content h3 {
  font-size: 16px;
}

.article-content h4,
.article-content h5,
.article-content h6 {
  font-size: 14px;
}

.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  padding: 15px 0;
  font-weight: bold;
}

.article-content ul {
  list-style: square;
  margin-left: 1em;
  padding: 10px 0;
}

.article-content ol {
  list-style: decimal;
  margin-left: 1em;
  padding: 10px 0;
}

.article-content blockquote {
  padding-left: 10px;
  border-left: 3px solid #dbdbdb;
  font-size: 14px;
}

.article-content blockquote p {
  font-size: 14px;
}

/*head*/

header {
  padding: .2rem 15px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 5;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.06);
}
header .logo-wrap {
  height: .8rem;
  line-height: .8rem;
  font-size: 0;
}

header .logo-wrap a {
  display: inline-block;
  vertical-align: middle;
}

header .logo-wrap .index_anchor{
  height: 100%;
}

header .logo-wrap .index_anchor .logo_img{
  height: 100%;
}

header .sub_module_anchor {
  margin-left: .2rem;
  line-height: .65rem;
  width: 1.7rem;
  height: .65rem;
  border: .02rem dashed #e24c37;
  border-radius: 50px;
  text-align: center;
  box-sizing: border-box;
}

header .sub_module_anchor span {
  font-size: .26rem;
  color: #e24c37;
  margin-left: .1rem;
  line-height: .26rem;
  height: .26rem;
}

header .sub_module_anchor .img-sj-logo {
  width: .25rem;
  height: .2rem;
}

header .sub_module_anchor .img-sz-logo {
  width: .19rem;
  height: .21rem;
}

/*footer*/

footer {
  background: #7cca50;
  overflow: hidden;
  padding: .2rem 15px;
  color: #fff;
}

footer a {
  color: #fff;
}

footer p {
  font-size: 12px;
  line-height: 1.5;
}

input, button, a, select, textarea, option {
  outline: none;
  &:focus {
    outline: none;
  }
}
