.mb-zixun-page {
    .panel_title {
        padding: 0.63rem 0 0.3rem;
        .topic_title {
            position: relative;
            font-size: 0.32rem;
	        color: @green;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.60rem;
                width: 100%;
                height: 0.05rem;
                background: #68C258;
                z-index: 1;
            }
        }
        a {
            font-size: 0.24rem;
            color: #666666;
        }
    }
    .module_content {
        .zixun_common_box {
            width: 100%;
            padding-top: 0.32rem;
            .left-box {
                display: block;
                width: 100%;
                height: 3.99rem;
                border-radius: 20px;
                margin-right: 0rem;
                overflow: hidden;
            }
            .img-box {
                position: relative;
                top: 0rem;
                display: block;
                width: 100%;
                height: 100%;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .mask_box {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 0.68rem;
                    padding: 0rem 0.43rem;
                    background-color: @green;
                    opacity: 0.9;
                    font-size: 0.28rem;
                    line-height: 0.68rem; //33px
                    color: #ffffff;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .right_list {
                width: 100%;

                .item {
                    display: block;
                    height: 1.50rem;
                    border-bottom: 0.01rem solid #999;
                    padding-top: 0.44rem;
                    box-sizing: border-box;

                    &:last-child {
                        border: none;
                    }

                    .title {
                        height:0.28rem;
                        font-size: 0.28rem;
                        line-height: 0.3rem;
                        color: #222222;

                        margin-bottom: 0.25rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .date {
                        font-size: 0.24rem;
                        line-height: 0.24rem;
                        color: #666666;
                    }
                }

            }
        }
    }
}


.zixun_common {
    .zixun_topic_list {
        .mr-row {
            margin-right: -15px;
            margin-left: -15px;
            background-color: #f2f3f5;
        }

        .topic_item {
            display: flex;
            padding-bottom: 0.3rem;
            padding-top: 0.3rem;

            .img_box {
                flex-shrink: 0;
                width: 2.51rem;
                height: 1.62rem;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: 6px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .content {
                width: calc(100% - 2.51rem);
                padding: 0.13rem 0.25rem;

                .title {
                    max-height: 0.68rem;
                    line-height: 0.34rem;
                    font-size: 0.28rem;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .text,.date {
                    margin-top: 0.28rem;
                    font-size: 0.24rem;
                    color: #999;

                    >span {
                        color: #fd7400;
                    }
                }
                
            }
        }
    }
}

